import { Axios } from "../utils/axios";
import { BASE_URL } from '../config';

const axios = new Axios();

export const addProduct = async (data) => {
    const formData = new FormData();
    data.images.forEach(element => {
        formData.append("images", element);
    });
    delete data.images;
    const queryParam = new URLSearchParams(data).toString();
    const res = await axios.post(`${BASE_URL}api/products?${queryParam}`, formData);
    return res.data
}
export const getProducts = async () => {
    const res = await axios.get(`${BASE_URL}api/products`);
    return res.data
}
export const getProductsPage = async () => {
    const res = await axios.get(`${BASE_URL}api/products/page`);
    return res.data
}
export const searchProduct = async (searchParams, searchOptions) => {
    const searchParamsString = new URLSearchParams(searchParams).toString()
    const res = await axios.post(`${BASE_URL}public/products/search?${searchParamsString}`, searchOptions);
    return res.data
}
export const getPublicProducts = async () => {
    const res = await axios.get(`${BASE_URL}public/products`);
    return res.data
}
export const getPublicProductDetail = async (id) => {
    const res = await axios.get(`${BASE_URL}public/products/${id}`);
    return res.data
}
export const deleteProduct = async (id) => {
    const res = await axios.delete(`${BASE_URL}api/products/${id}`);
    return res
}
export const updateProduct = async (id,data) => {
    const formData = new FormData();
    data.images.forEach(element => {
        formData.append("images", element);
    });
    delete data.images;
    const queryParam = new URLSearchParams(data).toString();
    const res = await axios.put(`${BASE_URL}api/products/${id}?${queryParam}`, formData);
    return res.data
}