
export const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
        }
    });
}

export const base64toFile = (base64) => {
    return fetch(base64)
      .then(res => res.blob())
      .then(blob => new File([blob], "filename.jpeg"))
  };