import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ProductCard } from 'src/components/ProductCard';
import { searchProduct } from 'src/services/product';
export const Products = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [products, setProducts] = useState([]);
  const [pageState, setPageState] = useState({
    first: true,
    last: true,
    page: 1,
    totalPages: 1,
    totalElements: 0,
    numberOfElements: 0,
  });

  const getData = async (searchParam, filterData) => {
    const data = await searchProduct(searchParam, filterData);
    setProducts(data.content);
    setPageState({
      first: data.first,
      last: data.last,
      page: data.pageable?.pageNumber + 1,
      totalPages: data.totalPages,
      numberOfElements: data.numberOfElements,
      totalElements: data.totalElements
    })
  }

  const changePageTo = (pageNo) => {
    setSearchParams({ ...Object.fromEntries(searchParams.entries()), page: pageNo })
  }

  useEffect(() => {
    const filterData = {};
    const searchParam = {
      page: searchParams.get("page") || pageState.page,
      size: searchParams.get("size") || 12,
    };
    const q = searchParams.get("q", "");
    if (q) {
      filterData.productName = q;
    }
    const category = searchParams.get("category", "");
    if (category) {
      filterData.category = category;
    }
    getData(searchParam, filterData);
  }, [searchParams])

  return (<>
    <div className="top-column-content" id="TopColumnContent">
      <div className="page-width">
        <div className="title-breadcrumbs">
          <div className="title-breadcrumbs-container">
            <nav className="breadcrumb" role="navigation" aria-label="breadcrumbs">
              <a href="/" className="breadcrumb-home" title="Home">Home</a>
              <a href="/products">
                Catalog
              </a>
            </nav>
            <h2 className="breadcrumb_title">Products</h2>

          </div>
        </div>
      </div>
    </div>

    <div id="shopify-section-template--16114105680111__product-grid" className="shopify-section spaced-section collection-grid-section"> <link href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/template-collection.css?v=45113759048121360531654750508" rel="stylesheet" type="text/css" media="all" />
      <link href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-loading-overlay.css?v=135534932159979988391654690478" rel="stylesheet" type="text/css" media="all" />
      <link href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/tm-sidebar.css?v=129277856269335818031654690518" rel="stylesheet" type="text/css" media="all" />
      <link rel="stylesheet" href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-card.css?v=125460242322242993271655903721" as="style" onload="this.onload=null;this.rel='stylesheet'" />
      <link rel="stylesheet" href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-price.css?v=26672859977914416921654690481" as="style" onload="this.onload=null;this.rel='stylesheet'" />
      <link rel="stylesheet" href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-badge.css?v=67041376918774314611654690473" as="style" onload="this.onload=null;this.rel='stylesheet'" />
      <link rel="stylesheet" href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-rte.css?v=29398525773061942381654748991" as="style" onload="this.onload=null;this.rel='stylesheet'" />
      <noscript><link href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-card.css?v=125460242322242993271655903721" rel="stylesheet" type="text/css" media="all" /></noscript>
      <noscript><link href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-price.css?v=26672859977914416921654690481" rel="stylesheet" type="text/css" media="all" /></noscript>
      <noscript><link href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-badge.css?v=67041376918774314611654690473" rel="stylesheet" type="text/css" media="all" /></noscript>
      <noscript><link href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-rte.css?v=29398525773061942381654748991" rel="stylesheet" type="text/css" media="all" /></noscript><div className="page-width collection-filters sidebar-filter" id="main-collection-filters" data-id="template--16114105680111__product-grid"><link href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-facets.css?v=153675251337681318891654690476" rel="stylesheet" type="text/css" media="all" />
        <script src="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/facets.js?v=27997007195175939411655524559" defer="defer"></script>
        <div className="facets-container desktop">
          <facet-filters-form className="facets small-hide">
            <form id="FacetFiltersForm" className="facets__form"><div className="FacetsWrapperDesktop"><h3 className="box-heading">Filter by</h3><div className="box-content">
              <div className="disclosure-has-popup facets__disclosure js-filter" data-index="1">
                <summary className="facets__summary">
                  <div>
                    <span className="filter-title">Availability</span>
                    <span className="count-bubble"></span>                    <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" >
                      <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                      <g><path d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z"></path></g>
                    </svg>
                  </div>
                </summary>
                <div className="facets__display">
                  <div className="facets__header">
                    <span className="facets__selected no-js-hidden">0 selected</span>
                    <a href="/collections/all" className="facets__reset link underlined-link js-facet-remove">Reset</a>
                  </div>
                  <ul className="facets__list list-unstyled" ><li className="list-menu__item facets__item" id="Availability">
                    <label for="Filter-Availability-1" className="facet-checkbox">
                      <input type="checkbox" name="filter.v.availability" value="1" id="Filter-Availability-1" />
                      <svg width="16" height="16" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                        <rect width="16" height="16" stroke="currentColor" fill="none" stroke-width="1"></rect>
                      </svg>
                      <svg className="icon icon-checkmark" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 9" fill="none" width="10px" height="10px">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.35.643a.5.5 0 01.006.707l-6.77 6.886a.5.5 0 01-.719-.006L.638 4.845a.5.5 0 11.724-.69l2.872 3.011 6.41-6.517a.5.5 0 01.707-.006h-.001z" fill="currentColor"></path>
                      </svg>
                      In stock (18)
                    </label>
                  </li><li className="list-menu__item facets__item" id="Availability">
                      <label for="Filter-Availability-2" className="facet-checkbox">
                        <input type="checkbox" name="filter.v.availability" value="0" id="Filter-Availability-2" />
                        <svg width="16" height="16" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                          <rect width="16" height="16" stroke="currentColor" fill="none" stroke-width="1"></rect>
                        </svg>
                        <svg className="icon icon-checkmark" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 9" fill="none" width="10px" height="10px">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.35.643a.5.5 0 01.006.707l-6.77 6.886a.5.5 0 01-.719-.006L.638 4.845a.5.5 0 11.724-.69l2.872 3.011 6.41-6.517a.5.5 0 01.707-.006h-.001z" fill="currentColor"></path>
                        </svg>
                        Out of stock (1)
                      </label>
                    </li></ul>
                </div>
              </div>              <div className="disclosure-has-popup facets__disclosure js-filter" data-index="2">
                <summary className="facets__summary">
                  <div>
                    <span className="filter-title">Price</span>
                    <span className="count-bubble"></span>                    <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" >
                      <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                      <g><path d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z"></path></g>
                    </svg>
                  </div>
                </summary>
                <div className="facets__display"><price-range className="facets__price">
                  <div className="field">
                    <span className="field__currency">$</span>
                    <input className="field__input" name="filter.v.price.gte" id="Filter-Price-2" type="number" placeholder="0" min="0" max="370.00" />
                    <label className="field__label" for="Search-In-Modal">From</label>
                  </div>
                  <div className="field">
                    <span className="field__currency">$</span>
                    <input className="field__input" name="filter.v.price.lte" id="Filter-Price-2" type="number" placeholder="370.00" min="0" max="370.00" />
                    <label className="field__label" for="Search-In-Modal">To</label>
                  </div>
                </price-range></div>
              </div>

              <div className="disclosure-has-popup facets__disclosure js-filter" data-index="3">
                <summary className="facets__summary">
                  <div>
                    <span className="filter-title">Brand</span>
                    <span className="count-bubble"></span>                    <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" >
                      <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                      <g><path d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z"></path></g>
                    </svg>
                  </div>
                </summary>
                <div className="facets__display">
                  <div className="facets__header">
                    <span className="facets__selected no-js-hidden">0 selected</span>
                    <a href="/collections/all" className="facets__reset link underlined-link js-facet-remove">Reset</a>
                  </div>
                  <ul className="facets__list list-unstyled" ><li className="list-menu__item facets__item" id="Brand">
                    <label for="Filter-Brand-1" className="facet-checkbox">
                      <input type="checkbox" name="filter.p.vendor" value="Apple" id="Filter-Brand-1" />
                      <svg width="16" height="16" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                        <rect width="16" height="16" stroke="currentColor" fill="none" stroke-width="1"></rect>
                      </svg>
                      <svg className="icon icon-checkmark" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 9" fill="none" width="10px" height="10px">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.35.643a.5.5 0 01.006.707l-6.77 6.886a.5.5 0 01-.719-.006L.638 4.845a.5.5 0 11.724-.69l2.872 3.011 6.41-6.517a.5.5 0 01.707-.006h-.001z" fill="currentColor"></path>
                      </svg>
                      Apple (5)
                    </label>
                  </li><li className="list-menu__item facets__item" id="Brand">
                      <label for="Filter-Brand-2" className="facet-checkbox">
                        <input type="checkbox" name="filter.p.vendor" value="Canon" id="Filter-Brand-2" />
                        <svg width="16" height="16" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                          <rect width="16" height="16" stroke="currentColor" fill="none" stroke-width="1"></rect>
                        </svg>
                        <svg className="icon icon-checkmark" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 9" fill="none" width="10px" height="10px">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.35.643a.5.5 0 01.006.707l-6.77 6.886a.5.5 0 01-.719-.006L.638 4.845a.5.5 0 11.724-.69l2.872 3.011 6.41-6.517a.5.5 0 01.707-.006h-.001z" fill="currentColor"></path>
                        </svg>
                        Canon (2)
                      </label>
                    </li><li className="list-menu__item facets__item" id="Brand">
                      <label for="Filter-Brand-3" className="facet-checkbox">
                        <input type="checkbox" name="filter.p.vendor" value="Dell" id="Filter-Brand-3" />
                        <svg width="16" height="16" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                          <rect width="16" height="16" stroke="currentColor" fill="none" stroke-width="1"></rect>
                        </svg>
                        <svg className="icon icon-checkmark" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 9" fill="none" width="10px" height="10px">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.35.643a.5.5 0 01.006.707l-6.77 6.886a.5.5 0 01-.719-.006L.638 4.845a.5.5 0 11.724-.69l2.872 3.011 6.41-6.517a.5.5 0 01.707-.006h-.001z" fill="currentColor"></path>
                        </svg>
                        Dell (5)
                      </label>
                    </li><li className="list-menu__item facets__item" id="Brand">
                      <label for="Filter-Brand-4" className="facet-checkbox">
                        <input type="checkbox" name="filter.p.vendor" value="LG" id="Filter-Brand-4" />
                        <svg width="16" height="16" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                          <rect width="16" height="16" stroke="currentColor" fill="none" stroke-width="1"></rect>
                        </svg>
                        <svg className="icon icon-checkmark" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 9" fill="none" width="10px" height="10px">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.35.643a.5.5 0 01.006.707l-6.77 6.886a.5.5 0 01-.719-.006L.638 4.845a.5.5 0 11.724-.69l2.872 3.011 6.41-6.517a.5.5 0 01.707-.006h-.001z" fill="currentColor"></path>
                        </svg>
                        LG (3)
                      </label>
                    </li><li className="list-menu__item facets__item" id="Brand">
                      <label for="Filter-Brand-5" className="facet-checkbox">
                        <input type="checkbox" name="filter.p.vendor" value="Samsung" id="Filter-Brand-5" />
                        <svg width="16" height="16" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                          <rect width="16" height="16" stroke="currentColor" fill="none" stroke-width="1"></rect>
                        </svg>
                        <svg className="icon icon-checkmark" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 9" fill="none" width="10px" height="10px">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.35.643a.5.5 0 01.006.707l-6.77 6.886a.5.5 0 01-.719-.006L.638 4.845a.5.5 0 11.724-.69l2.872 3.011 6.41-6.517a.5.5 0 01.707-.006h-.001z" fill="currentColor"></path>
                        </svg>
                        Samsung (3)
                      </label>
                    </li></ul>
                </div>
              </div>

              <div className="disclosure-has-popup facets__disclosure js-filter" data-index="4">
                <summary className="facets__summary">
                  <div>
                    <span className="filter-title">Color</span>
                    <span className="count-bubble"></span>                    <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" >
                      <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                      <g><path d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z"></path></g>
                    </svg>
                  </div>
                </summary>
                <div className="facets__display">
                  <div className="facets__header">
                    <span className="facets__selected no-js-hidden">0 selected</span>
                    <a href="/collections/all" className="facets__reset link underlined-link js-facet-remove">Reset</a>
                  </div>
                  <ul className="facets__list list-unstyled" ><li className="list-menu__item facets__item" id="Color">
                    <div className="filter-variant-color">

                      <label for="Filter-Color-1" className="facet-checkbox">
                        <input type="checkbox" name="filter.v.option.color" value="Black" id="Filter-Color-1" />

                        <span className="color-lable" style={{ backgroundColor: "Black" }}>
                        </span>
                      </label>
                    </div>
                  </li><li className="list-menu__item facets__item" id="Color">
                      <div className="filter-variant-color">

                        <label for="Filter-Color-2" className="facet-checkbox">
                          <input type="checkbox" name="filter.v.option.color" value="Blue" id="Filter-Color-2" />

                          <span className="color-lable" style={{ backgroundColor: "Blue" }}>
                          </span>
                        </label>
                      </div>
                    </li><li className="list-menu__item facets__item" id="Color">
                      <div className="filter-variant-color">

                        <label for="Filter-Color-3" className="facet-checkbox">
                          <input type="checkbox" name="filter.v.option.color" value="Brown" id="Filter-Color-3" />

                          <span className="color-lable" style={{ backgroundColor: "Brown" }}>
                          </span>
                        </label>
                      </div>
                    </li><li className="list-menu__item facets__item" id="Color">
                      <div className="filter-variant-color">

                        <label for="Filter-Color-4" className="facet-checkbox">
                          <input type="checkbox" name="filter.v.option.color" value="Gray" id="Filter-Color-4" />

                          <span className="color-lable" style={{ backgroundColor: "Gray" }}>
                          </span>
                        </label>
                      </div>
                    </li><li className="list-menu__item facets__item" id="Color">
                      <div className="filter-variant-color">

                        <label for="Filter-Color-5" className="facet-checkbox">
                          <input type="checkbox" name="filter.v.option.color" value="Green" id="Filter-Color-5" />

                          <span className="color-lable" style={{ backgroundColor: "Green" }}>
                          </span>
                        </label>
                      </div>
                    </li><li className="list-menu__item facets__item" id="Color">
                      <div className="filter-variant-color">

                        <label for="Filter-Color-6" className="facet-checkbox">
                          <input type="checkbox" name="filter.v.option.color" value="Pink" id="Filter-Color-6" />

                          <span className="color-lable" style={{ backgroundColor: "Pink" }}>
                          </span>
                        </label>
                      </div>
                    </li><li className="list-menu__item facets__item" id="Color">
                      <div className="filter-variant-color">

                        <label for="Filter-Color-7" className="facet-checkbox">
                          <input type="checkbox" name="filter.v.option.color" value="Red" id="Filter-Color-7" />

                          <span className="color-lable" style={{ backgroundColor: "Red" }}>
                          </span>
                        </label>
                      </div>
                    </li><li className="list-menu__item facets__item" id="Color">
                      <div className="filter-variant-color">

                        <label for="Filter-Color-8" className="facet-checkbox">
                          <input type="checkbox" name="filter.v.option.color" value="White" id="Filter-Color-8" />

                          <span className="color-lable" style={{ backgroundColor: "White" }}>
                          </span>
                        </label>
                      </div>
                    </li><li className="list-menu__item facets__item" id="Color">
                      <div className="filter-variant-color">

                        <label for="Filter-Color-9" className="facet-checkbox">
                          <input type="checkbox" name="filter.v.option.color" value="Yellow" id="Filter-Color-9" />

                          <span className="color-lable" style={{ backgroundColor: "Yellow" }}>
                          </span>
                        </label>
                      </div>
                    </li></ul>
                </div>
              </div>

              <div className="disclosure-has-popup facets__disclosure js-filter" data-index="5">
                <summary className="facets__summary">
                  <div>
                    <span className="filter-title">Size</span>
                    <span className="count-bubble"></span>                    <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" >
                      <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                      <g><path d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z"></path></g>
                    </svg>
                  </div>
                </summary>
                <div className="facets__display">
                  <div className="facets__header">
                    <span className="facets__selected no-js-hidden">0 selected</span>
                    <a href="/collections/all" className="facets__reset link underlined-link js-facet-remove">Reset</a>
                  </div>
                  <ul className="facets__list list-unstyled" ><li className="list-menu__item facets__item" id="Size">
                    <label for="Filter-Size-1" className="facet-checkbox">
                      <input type="checkbox" name="filter.v.option.size" value="S" id="Filter-Size-1" />
                      <svg width="16" height="16" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                        <rect width="16" height="16" stroke="currentColor" fill="none" stroke-width="1"></rect>
                      </svg>
                      <svg className="icon icon-checkmark" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 9" fill="none" width="10px" height="10px">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.35.643a.5.5 0 01.006.707l-6.77 6.886a.5.5 0 01-.719-.006L.638 4.845a.5.5 0 11.724-.69l2.872 3.011 6.41-6.517a.5.5 0 01.707-.006h-.001z" fill="currentColor"></path>
                      </svg>
                      S (9)
                    </label>
                  </li><li className="list-menu__item facets__item" id="Size">
                      <label for="Filter-Size-2" className="facet-checkbox">
                        <input type="checkbox" name="filter.v.option.size" value="M" id="Filter-Size-2" />
                        <svg width="16" height="16" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                          <rect width="16" height="16" stroke="currentColor" fill="none" stroke-width="1"></rect>
                        </svg>
                        <svg className="icon icon-checkmark" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 9" fill="none" width="10px" height="10px">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.35.643a.5.5 0 01.006.707l-6.77 6.886a.5.5 0 01-.719-.006L.638 4.845a.5.5 0 11.724-.69l2.872 3.011 6.41-6.517a.5.5 0 01.707-.006h-.001z" fill="currentColor"></path>
                        </svg>
                        M (9)
                      </label>
                    </li><li className="list-menu__item facets__item" id="Size">
                      <label for="Filter-Size-3" className="facet-checkbox">
                        <input type="checkbox" name="filter.v.option.size" value="L" id="Filter-Size-3" />
                        <svg width="16" height="16" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                          <rect width="16" height="16" stroke="currentColor" fill="none" stroke-width="1"></rect>
                        </svg>
                        <svg className="icon icon-checkmark" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 9" fill="none" width="10px" height="10px">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.35.643a.5.5 0 01.006.707l-6.77 6.886a.5.5 0 01-.719-.006L.638 4.845a.5.5 0 11.724-.69l2.872 3.011 6.41-6.517a.5.5 0 01.707-.006h-.001z" fill="currentColor"></path>
                        </svg>
                        L (8)
                      </label>
                    </li></ul>
                </div>
              </div>
              <noscript>
                <button type="submit" className="facets__button button">Filter</button>
              </noscript>
            </div>
            </div>
            </form>
          </facet-filters-form>
        </div>
        <div className="collection-btn">
          <div className="btn-group">
            <a href="#" id="col_4" className="btn btn-default btn-sm active"><svg className="icon icon-col-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.5 12.5"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="shop_page" data-name="shop page"><g id="_4_col" data-name="4_col"><path id="Rectangle" d="M.75 0a.76.76 0 01.75.75v11a.76.76 0 01-.75.75.76.76 0 01-.75-.75v-11A.76.76 0 01.75 0z"></path><path id="Rectangle-2" d="M4.75 0a.76.76 0 01.75.75v11a.76.76 0 01-.75.75.76.76 0 01-.75-.75v-11A.76.76 0 014.75 0z" data-name="Rectangle"></path><path id="Rectangle-3" d="M8.75 0a.76.76 0 01.75.75v11a.76.76 0 01-.75.75.76.76 0 01-.75-.75v-11A.76.76 0 018.75 0z" data-name="Rectangle"></path><path id="Rectangle-4" d="M12.75 0a.76.76 0 01.75.75v11a.76.76 0 01-.75.75.76.76 0 01-.75-.75v-11a.76.76 0 01.75-.75z" data-name="Rectangle"></path></g></g></g></g></svg></a>
            <a href="#" id="col_3" className="btn btn-default btn-sm"><svg className="icon icon-col-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.5 12.5"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="shop_page" data-name="shop page"><g id="Group-16"><path id="Rectangle" d="M.75 0a.76.76 0 01.75.75v11a.76.76 0 01-.75.75.76.76 0 01-.75-.75v-11A.76.76 0 01.75 0z"></path><path id="Rectangle-2" d="M4.75 0a.76.76 0 01.75.75v11a.76.76 0 01-.75.75.76.76 0 01-.75-.75v-11A.76.76 0 014.75 0z" data-name="Rectangle"></path><path id="Rectangle-3" d="M8.75 0a.76.76 0 01.75.75v11a.76.76 0 01-.75.75.76.76 0 01-.75-.75v-11A.76.76 0 018.75 0z" data-name="Rectangle"></path></g></g></g></g></svg></a>
            <a href="#" id="col_2" className="btn btn-default btn-sm"><svg className="icon icon-col-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.5 12.5"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="shop_page" data-name="shop page"><g id="Group-10"><path id="Rectangle" d="M.75 0a.76.76 0 01.75.75v11a.76.76 0 01-.75.75.76.76 0 01-.75-.75v-11A.76.76 0 01.75 0z"></path><path id="Rectangle-2" d="M4.75 0a.76.76 0 01.75.75v11a.76.76 0 01-.75.75.76.76 0 01-.75-.75v-11A.76.76 0 014.75 0z" data-name="Rectangle"></path></g></g></g></g></svg></a>
            <a href="#" id="col_1" className="btn btn-default btn-sm"><svg className="icon icon-list" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.5 9.5"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="shop_page" data-name="shop page"><g id="Group-16"><path id="Rectangle" d="M12.5.75a.76.76 0 01-.75.75h-11A.76.76 0 010 .75.76.76 0 01.75 0h11a.76.76 0 01.75.75z"></path><path id="Rectangle-2" d="M12.5 4.75a.76.76 0 01-.75.75h-11A.76.76 0 010 4.75.76.76 0 01.75 4h11a.76.76 0 01.75.75z" data-name="Rectangle"></path><path id="Rectangle-3" d="M12.5 8.75a.76.76 0 01-.75.75h-11A.76.76 0 010 8.75.76.76 0 01.75 8h11a.76.76 0 01.75.75z" data-name="Rectangle"></path></g></g></g></g></svg></a>
          </div>
          <div className="product-count light" role="status">
            <div className="product-count__text">
              <span id="ProductCount">Showing {pageState.numberOfElements} of {pageState.totalElements} products
              </span>
            </div>
            <div className="loading-overlay__spinner">
              <svg aria-hidden="true" focusable="false" role="presentation" className="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle className="path" fill="none" stroke-width="6" cx="33" cy="33" r="30"></circle>
              </svg>
            </div>
          </div>
        </div>
        <menu-drawer className="mobile-facets__wrapper" data-breakpoint="mobile">
          <details className="mobile-facets__disclosure disclosure-has-popup">
            <summary className="mobile-facets__open-wrapper focus-offset" role="button" aria-expanded="false" aria-controls="">
              <span className="mobile-facets__open button">
                <span className="mobile-facets__open-label button-label">Filter and sort
                </span>
              </span>
              <span tabindex="0" className="mobile-facets__close mobile-facets__close--no-js"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="presentation" className="icon icon-close" viewBox="0 0 18 17">
                <path d="M.865 15.978a.5.5 0 00.707.707l7.433-7.431 7.579 7.282a.501.501 0 00.846-.37.5.5 0 00-.153-.351L9.712 8.546l7.417-7.416a.5.5 0 10-.707-.708L8.991 7.853 1.413.573a.5.5 0 10-.693.72l7.563 7.268-7.418 7.417z">
                </path></svg>
              </span>
            </summary>
            <facet-filters-form>
              <form id="FacetFiltersFormMobile" className="mobile-facets">
                <div className="mobile-facets__inner">
                  <div className="mobile-facets__header">
                    <div className="mobile-facets__header-inner">
                      <h2 className="mobile-facets__heading">Filter and sort
                      </h2>
                      <p className="mobile-facets__count">18 products
                      </p>
                    </div>
                  </div>
                  <div className="mobile-facets__main">
                    <details id="Details-Mobile-1-template--16114105680111__product-grid" className="mobile-facets__details js-filter" data-index="mobile-1">
                      <summary className="mobile-facets__summary focus-inset" role="button" aria-expanded="false" aria-controls="FacetMobile-1-template--16114105680111__product-grid">
                        <div>
                          <span>Availability</span>
                          <span className="mobile-facets__arrow no-js-hidden"><svg viewBox="0 0 14 10" fill="none" aria-hidden="true" focusable="false" role="presentation" className="icon icon-arrow" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z" fill="currentColor">
                            </path></svg>
                          </span>
                          <noscript>

                            <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" >
                              <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                              <g><path d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z" /></g>
                            </svg></noscript>
                        </div>
                      </summary>
                      <div id="FacetMobile-1-template--16114105680111__product-grid" className="mobile-facets__submenu">
                        <button className="mobile-facets__close-button link link--text focus-inset" aria-expanded="true" type="button">
                          <svg viewBox="0 0 14 10" fill="none" aria-hidden="true" focusable="false" role="presentation" className="icon icon-arrow" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z" fill="currentColor">
                            </path></svg>
                          Availability
                        </button>
                        <ul className="mobile-facets__list list-unstyled" ><li className="mobile-facets__item list-menu__item" id="Availability">
                          <label for="Filter-Availability-mobile-1" className="mobile-facets__label">
                            <input className="mobile-facets__checkbox" type="checkbox" name="filter.v.availability" value="1" id="Filter-Availability-mobile-1" />
                            <span className="mobile-facets__highlight"></span>
                            <svg width="1.6rem" height="1.6rem" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                              <rect width="16" height="16" stroke="currentColor" fill="none" stroke-width="1"></rect>
                            </svg>
                            <svg className="icon icon-checkmark" width="1.1rem" height="0.7rem" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.5 3.5L2.83333 4.75L4.16667 6L9.5 1" stroke="currentColor" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                            In stock (18)
                          </label>
                        </li><li className="mobile-facets__item list-menu__item" id="Availability">
                            <label for="Filter-Availability-mobile-2" className="mobile-facets__label">
                              <input className="mobile-facets__checkbox" type="checkbox" name="filter.v.availability" value="0" id="Filter-Availability-mobile-2" />
                              <span className="mobile-facets__highlight"></span>
                              <svg width="1.6rem" height="1.6rem" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                                <rect width="16" height="16" stroke="currentColor" fill="none" stroke-width="1"></rect>
                              </svg>
                              <svg className="icon icon-checkmark" width="1.1rem" height="0.7rem" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 3.5L2.83333 4.75L4.16667 6L9.5 1" stroke="currentColor" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg>
                              Out of stock (1)
                            </label>
                          </li></ul>
                        <div className="no-js-hidden mobile-facets__footer">
                          <facet-remove className="mobile-facets__clear-wrapper">
                            <a href="/collections/all" className="mobile-facets__clear button">Clear</a>
                          </facet-remove>
                          <button type="button" className="no-js-hidden button button--primary" onclick="this.closest('.mobile-facets__wrapper').querySelector('summary').click()">Apply</button>
                          <noscript><button className="button button--primary">Apply</button></noscript>
                        </div>
                      </div>
                    </details>

                    <details id="Details-Mobile-2-template--16114105680111__product-grid" className="mobile-facets__details js-filter" data-index="mobile-2">
                      <summary className="mobile-facets__summary focus-inset" role="button" aria-expanded="false" aria-controls="FacetMobile-2-template--16114105680111__product-grid">
                        <div>
                          <span>Price</span>
                          <span className="mobile-facets__arrow no-js-hidden"><svg viewBox="0 0 14 10" fill="none" aria-hidden="true" focusable="false" role="presentation" className="icon icon-arrow" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z" fill="currentColor">
                            </path></svg>
                          </span>
                          <noscript>

                            <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" >
                              <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                              <g><path d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z" /></g>
                            </svg></noscript>
                        </div>
                      </summary>
                      <div id="FacetMobile-2-template--16114105680111__product-grid" className="mobile-facets__submenu">
                        <button className="mobile-facets__close-button link link--text focus-inset" aria-expanded="true" type="button">
                          <svg viewBox="0 0 14 10" fill="none" aria-hidden="true" focusable="false" role="presentation" className="icon icon-arrow" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z" fill="currentColor">
                            </path></svg>
                          Price
                        </button>
                        <p className="mobile-facets__info">The highest price is $370.00</p>
                        <price-range className="facets__price">
                          <span className="field-currency">$</span>
                          <div className="field">
                            <input className="field__input" name="filter.v.price.gte" id="Mobile-Filter-Price-GTE" type="number" placeholder="0" min="0" inputmode="decimal" max="370.00" />
                            <label className="field__label" for="Mobile-Filter-Price-GTE">From</label>
                          </div>
                          <span className="field-currency">$</span>
                          <div className="field">
                            <input className="field__input" name="filter.v.price.lte" id="Mobile-Filter-Price-LTE" type="number" min="0" inputmode="decimal" placeholder="370.00" max="370.00" />
                            <label className="field__label" for="Mobile-Filter-Price-LTE">To</label>
                          </div>
                        </price-range>
                        <div className="no-js-hidden mobile-facets__footer">
                          <facet-remove className="mobile-facets__clear-wrapper">
                            <a href="/collections/all" className="mobile-facets__clear button">Clear</a>
                          </facet-remove>
                          <button type="button" className="no-js-hidden button button--primary" onclick="this.closest('.mobile-facets__wrapper').querySelector('summary').click()">Apply</button>
                          <noscript><button className="button button--primary">Apply</button></noscript>
                        </div>
                      </div>
                    </details>

                    <details id="Details-Mobile-3-template--16114105680111__product-grid" className="mobile-facets__details js-filter" data-index="mobile-3">
                      <summary className="mobile-facets__summary focus-inset" role="button" aria-expanded="false" aria-controls="FacetMobile-3-template--16114105680111__product-grid">
                        <div>
                          <span>Brand</span>
                          <span className="mobile-facets__arrow no-js-hidden"><svg viewBox="0 0 14 10" fill="none" aria-hidden="true" focusable="false" role="presentation" className="icon icon-arrow" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z" fill="currentColor">
                            </path></svg>
                          </span>
                          <noscript>

                            <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" >
                              <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                              <g><path d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z" /></g>
                            </svg></noscript>
                        </div>
                      </summary>
                      <div id="FacetMobile-3-template--16114105680111__product-grid" className="mobile-facets__submenu">
                        <button className="mobile-facets__close-button link link--text focus-inset" aria-expanded="true" type="button">
                          <svg viewBox="0 0 14 10" fill="none" aria-hidden="true" focusable="false" role="presentation" className="icon icon-arrow" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z" fill="currentColor">
                            </path></svg>
                          Brand
                        </button>
                        <ul className="mobile-facets__list list-unstyled" ><li className="mobile-facets__item list-menu__item" id="Brand">
                          <label for="Filter-Brand-mobile-1" className="mobile-facets__label">
                            <input className="mobile-facets__checkbox" type="checkbox" name="filter.p.vendor" value="Apple" id="Filter-Brand-mobile-1" />
                            <span className="mobile-facets__highlight"></span>
                            <svg width="1.6rem" height="1.6rem" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                              <rect width="16" height="16" stroke="currentColor" fill="none" stroke-width="1"></rect>
                            </svg>
                            <svg className="icon icon-checkmark" width="1.1rem" height="0.7rem" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.5 3.5L2.83333 4.75L4.16667 6L9.5 1" stroke="currentColor" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                            Apple (5)
                          </label>
                        </li><li className="mobile-facets__item list-menu__item" id="Brand">
                            <label for="Filter-Brand-mobile-2" className="mobile-facets__label">
                              <input className="mobile-facets__checkbox" type="checkbox" name="filter.p.vendor" value="Canon" id="Filter-Brand-mobile-2" />
                              <span className="mobile-facets__highlight"></span>
                              <svg width="1.6rem" height="1.6rem" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                                <rect width="16" height="16" stroke="currentColor" fill="none" stroke-width="1"></rect>
                              </svg>
                              <svg className="icon icon-checkmark" width="1.1rem" height="0.7rem" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 3.5L2.83333 4.75L4.16667 6L9.5 1" stroke="currentColor" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg>
                              Canon (2)
                            </label>
                          </li><li className="mobile-facets__item list-menu__item" id="Brand">
                            <label for="Filter-Brand-mobile-3" className="mobile-facets__label">
                              <input className="mobile-facets__checkbox" type="checkbox" name="filter.p.vendor" value="Dell" id="Filter-Brand-mobile-3" />
                              <span className="mobile-facets__highlight"></span>
                              <svg width="1.6rem" height="1.6rem" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                                <rect width="16" height="16" stroke="currentColor" fill="none" stroke-width="1"></rect>
                              </svg>
                              <svg className="icon icon-checkmark" width="1.1rem" height="0.7rem" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 3.5L2.83333 4.75L4.16667 6L9.5 1" stroke="currentColor" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg>
                              Dell (5)
                            </label>
                          </li><li className="mobile-facets__item list-menu__item" id="Brand">
                            <label for="Filter-Brand-mobile-4" className="mobile-facets__label">
                              <input className="mobile-facets__checkbox" type="checkbox" name="filter.p.vendor" value="LG" id="Filter-Brand-mobile-4" />
                              <span className="mobile-facets__highlight"></span>
                              <svg width="1.6rem" height="1.6rem" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                                <rect width="16" height="16" stroke="currentColor" fill="none" stroke-width="1"></rect>
                              </svg>
                              <svg className="icon icon-checkmark" width="1.1rem" height="0.7rem" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 3.5L2.83333 4.75L4.16667 6L9.5 1" stroke="currentColor" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg>
                              LG (3)
                            </label>
                          </li><li className="mobile-facets__item list-menu__item" id="Brand">
                            <label for="Filter-Brand-mobile-5" className="mobile-facets__label">
                              <input className="mobile-facets__checkbox" type="checkbox" name="filter.p.vendor" value="Samsung" id="Filter-Brand-mobile-5" />
                              <span className="mobile-facets__highlight"></span>
                              <svg width="1.6rem" height="1.6rem" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                                <rect width="16" height="16" stroke="currentColor" fill="none" stroke-width="1"></rect>
                              </svg>
                              <svg className="icon icon-checkmark" width="1.1rem" height="0.7rem" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 3.5L2.83333 4.75L4.16667 6L9.5 1" stroke="currentColor" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg>
                              Samsung (3)
                            </label>
                          </li></ul>
                        <div className="no-js-hidden mobile-facets__footer">
                          <facet-remove className="mobile-facets__clear-wrapper">
                            <a href="/collections/all" className="mobile-facets__clear button">Clear</a>
                          </facet-remove>
                          <button type="button" className="no-js-hidden button button--primary" onclick="this.closest('.mobile-facets__wrapper').querySelector('summary').click()">Apply</button>
                          <noscript><button className="button button--primary">Apply</button></noscript>
                        </div>
                      </div>
                    </details>

                    <details id="Details-Mobile-4-template--16114105680111__product-grid" className="mobile-facets__details js-filter" data-index="mobile-4">
                      <summary className="mobile-facets__summary focus-inset" role="button" aria-expanded="false" aria-controls="FacetMobile-4-template--16114105680111__product-grid">
                        <div>
                          <span>Color</span>
                          <span className="mobile-facets__arrow no-js-hidden"><svg viewBox="0 0 14 10" fill="none" aria-hidden="true" focusable="false" role="presentation" className="icon icon-arrow" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z" fill="currentColor">
                            </path></svg>
                          </span>
                          <noscript>

                            <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" >
                              <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                              <g><path d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z" /></g>
                            </svg></noscript>
                        </div>
                      </summary>
                      <div id="FacetMobile-4-template--16114105680111__product-grid" className="mobile-facets__submenu">
                        <button className="mobile-facets__close-button link link--text focus-inset" aria-expanded="true" type="button">
                          <svg viewBox="0 0 14 10" fill="none" aria-hidden="true" focusable="false" role="presentation" className="icon icon-arrow" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z" fill="currentColor">
                            </path></svg>
                          Color
                        </button>
                        <ul className="mobile-facets__list list-unstyled" ><li className="mobile-facets__item list-menu__item" id="Color">
                          <div className="filter-variant-color">

                            <label for="Filter-Color-mobile-1" className="mobile-facets__label">
                              <input className="mobile-facets__checkbox" type="checkbox" name="filter.v.option.color" value="Black" id="Filter-Color-mobile-1" />
                              <span className="color-lable" style={{ backgroundColor: "Black" }}>
                              </span></label>
                          </div>
                        </li><li className="mobile-facets__item list-menu__item" id="Color">
                            <div className="filter-variant-color">

                              <label for="Filter-Color-mobile-2" className="mobile-facets__label">
                                <input className="mobile-facets__checkbox" type="checkbox" name="filter.v.option.color" value="Blue" id="Filter-Color-mobile-2" />
                                <span className="color-lable" style={{ backgroundColor: "Blue" }}>
                                </span></label>
                            </div>
                          </li><li className="mobile-facets__item list-menu__item" id="Color">
                            <div className="filter-variant-color">

                              <label for="Filter-Color-mobile-3" className="mobile-facets__label">
                                <input className="mobile-facets__checkbox" type="checkbox" name="filter.v.option.color" value="Brown" id="Filter-Color-mobile-3" />
                                <span className="color-lable" style={{ backgroundColor: "Brown" }}>
                                </span></label>
                            </div>
                          </li><li className="mobile-facets__item list-menu__item" id="Color">
                            <div className="filter-variant-color">

                              <label for="Filter-Color-mobile-4" className="mobile-facets__label">
                                <input className="mobile-facets__checkbox" type="checkbox" name="filter.v.option.color" value="Gray" id="Filter-Color-mobile-4" />
                                <span className="color-lable" style={{ backgroundColor: "Gray" }}>
                                </span></label>
                            </div>
                          </li><li className="mobile-facets__item list-menu__item" id="Color">
                            <div className="filter-variant-color">

                              <label for="Filter-Color-mobile-5" className="mobile-facets__label">
                                <input className="mobile-facets__checkbox" type="checkbox" name="filter.v.option.color" value="Green" id="Filter-Color-mobile-5" />
                                <span className="color-lable" style={{ backgroundColor: "Green" }}>
                                </span></label>
                            </div>
                          </li><li className="mobile-facets__item list-menu__item" id="Color">
                            <div className="filter-variant-color">

                              <label for="Filter-Color-mobile-6" className="mobile-facets__label">
                                <input className="mobile-facets__checkbox" type="checkbox" name="filter.v.option.color" value="Pink" id="Filter-Color-mobile-6" />
                                <span className="color-lable" style={{ backgroundColor: "Pink" }}>
                                </span></label>
                            </div>
                          </li><li className="mobile-facets__item list-menu__item" id="Color">
                            <div className="filter-variant-color">

                              <label for="Filter-Color-mobile-7" className="mobile-facets__label">
                                <input className="mobile-facets__checkbox" type="checkbox" name="filter.v.option.color" value="Red" id="Filter-Color-mobile-7" />
                                <span className="color-lable" style={{ backgroundColor: "Red" }}>
                                </span></label>
                            </div>
                          </li><li className="mobile-facets__item list-menu__item" id="Color">
                            <div className="filter-variant-color">

                              <label for="Filter-Color-mobile-8" className="mobile-facets__label">
                                <input className="mobile-facets__checkbox" type="checkbox" name="filter.v.option.color" value="White" id="Filter-Color-mobile-8" />
                                <span className="color-lable" style={{ backgroundColor: "White" }}>
                                </span></label>
                            </div>
                          </li><li className="mobile-facets__item list-menu__item" id="Color">
                            <div className="filter-variant-color">

                              <label for="Filter-Color-mobile-9" className="mobile-facets__label">
                                <input className="mobile-facets__checkbox" type="checkbox" name="filter.v.option.color" value="Yellow" id="Filter-Color-mobile-9" />
                                <span className="color-lable" style={{ backgroundColor: "Yellow" }}>
                                </span></label>
                            </div>
                          </li></ul>
                        <div className="no-js-hidden mobile-facets__footer">
                          <facet-remove className="mobile-facets__clear-wrapper">
                            <a href="/collections/all" className="mobile-facets__clear button">Clear</a>
                          </facet-remove>
                          <button type="button" className="no-js-hidden button button--primary" onclick="this.closest('.mobile-facets__wrapper').querySelector('summary').click()">Apply</button>
                          <noscript><button className="button button--primary">Apply</button></noscript>
                        </div>
                      </div>
                    </details>

                    <details id="Details-Mobile-5-template--16114105680111__product-grid" className="mobile-facets__details js-filter" data-index="mobile-5">
                      <summary className="mobile-facets__summary focus-inset" role="button" aria-expanded="false" aria-controls="FacetMobile-5-template--16114105680111__product-grid">
                        <div>
                          <span>Size</span>
                          <span className="mobile-facets__arrow no-js-hidden"><svg viewBox="0 0 14 10" fill="none" aria-hidden="true" focusable="false" role="presentation" className="icon icon-arrow" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z" fill="currentColor">
                            </path></svg>
                          </span>
                          <noscript>

                            <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" >
                              <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                              <g><path d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z" /></g>
                            </svg></noscript>
                        </div>
                      </summary>
                      <div id="FacetMobile-5-template--16114105680111__product-grid" className="mobile-facets__submenu">
                        <button className="mobile-facets__close-button link link--text focus-inset" aria-expanded="true" type="button">
                          <svg viewBox="0 0 14 10" fill="none" aria-hidden="true" focusable="false" role="presentation" className="icon icon-arrow" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z" fill="currentColor">
                            </path></svg>
                          Size
                        </button>
                        <ul className="mobile-facets__list list-unstyled" ><li className="mobile-facets__item list-menu__item" id="Size">
                          <label for="Filter-Size-mobile-1" className="mobile-facets__label">
                            <input className="mobile-facets__checkbox" type="checkbox" name="filter.v.option.size" value="S" id="Filter-Size-mobile-1" />
                            <span className="mobile-facets__highlight"></span>
                            <svg width="1.6rem" height="1.6rem" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                              <rect width="16" height="16" stroke="currentColor" fill="none" stroke-width="1"></rect>
                            </svg>
                            <svg className="icon icon-checkmark" width="1.1rem" height="0.7rem" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.5 3.5L2.83333 4.75L4.16667 6L9.5 1" stroke="currentColor" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                            S (9)
                          </label>
                        </li><li className="mobile-facets__item list-menu__item" id="Size">
                            <label for="Filter-Size-mobile-2" className="mobile-facets__label">
                              <input className="mobile-facets__checkbox" type="checkbox" name="filter.v.option.size" value="M" id="Filter-Size-mobile-2" />
                              <span className="mobile-facets__highlight"></span>
                              <svg width="1.6rem" height="1.6rem" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                                <rect width="16" height="16" stroke="currentColor" fill="none" stroke-width="1"></rect>
                              </svg>
                              <svg className="icon icon-checkmark" width="1.1rem" height="0.7rem" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 3.5L2.83333 4.75L4.16667 6L9.5 1" stroke="currentColor" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg>
                              M (9)
                            </label>
                          </li><li className="mobile-facets__item list-menu__item" id="Size">
                            <label for="Filter-Size-mobile-3" className="mobile-facets__label">
                              <input className="mobile-facets__checkbox" type="checkbox" name="filter.v.option.size" value="L" id="Filter-Size-mobile-3" />
                              <span className="mobile-facets__highlight"></span>
                              <svg width="1.6rem" height="1.6rem" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                                <rect width="16" height="16" stroke="currentColor" fill="none" stroke-width="1"></rect>
                              </svg>
                              <svg className="icon icon-checkmark" width="1.1rem" height="0.7rem" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 3.5L2.83333 4.75L4.16667 6L9.5 1" stroke="currentColor" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"></path>
                              </svg>
                              L (8)
                            </label>
                          </li></ul>
                        <div className="no-js-hidden mobile-facets__footer">
                          <facet-remove className="mobile-facets__clear-wrapper">
                            <a href="/collections/all" className="mobile-facets__clear button">Clear</a>
                          </facet-remove>
                          <button type="button" className="no-js-hidden button button--primary" onclick="this.closest('.mobile-facets__wrapper').querySelector('summary').click()">Apply</button>
                          <noscript><button className="button button--primary">Apply</button></noscript>
                        </div>
                      </div>
                    </details>
                    <div className="mobile-facets__details js-filter" data-index="mobile-">
                      <div className="mobile-facets__summary">
                        <div className="mobile-facets__sort">
                          <label for="SortBy-mobile">Sort by:</label>
                          <div className="select">
                            <select name="sort_by" className="select__select" id="SortBy-mobile" aria-describedby="a11y-refresh-page-message"><option value="manual">Featured</option><option value="best-selling">Best selling</option><option value="title-ascending" selected="selected">Alphabetically, A-Z</option><option value="title-descending">Alphabetically, Z-A</option><option value="price-ascending">Price, low to high</option><option value="price-descending">Price, high to low</option><option value="created-ascending">Date, old to new</option><option value="created-descending">Date, new to old</option></select>                            <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" >
                              <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                              <g><path d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z"></path></g>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div><div className="mobile-facets__footer">
                      <facet-remove className="mobile-facets__clear-wrapper">
                        <a href="/collections/all" className="mobile-facets__clear button">Clear</a>
                      </facet-remove>
                      <button type="button" className="no-js-hidden button button--primary" onclick="this.closest('.mobile-facets__wrapper').querySelector('summary').click()">Apply</button>
                      <noscript><button className="button button--primary">Apply</button></noscript>
                    </div>
                  </div>

                </div>
              </form>
            </facet-filters-form>
          </details>
        </menu-drawer>
        <div className="active-facets active-facets-mobile">
          <facet-remove className="active-facets__button-wrapper">
            <a href="/collections/all" className="active-facets__button-remove button underlined-link">
              <span>Clear</span>
            </a>
          </facet-remove></div>
      </div>      <div id="ProductGridContainer"><div className="collection page-width">
        <div id="products" className="row list-group ">
          <ul id="product-grid" data-id="template--16114105680111__product-grid" className="item grid grid--2-col grid--3-col-tablet grid--4-col-desktop grid--one-third-max  grid--quarter-max negative-margin">
            {products.sort((a, b) => b.id - a.id).map((product, index) => (
              <ProductCard index={index} product={product} />
            ))}</ul>
          <link rel="stylesheet" href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-pagination.css?v=182403028877127728001654690480" media="all" onload="this.media='all'" />
          <noscript><link href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-pagination.css?v=182403028877127728001654690480" rel="stylesheet" type="text/css" media="all" /></noscript><div className="pagination-wrapper">
            <nav className="pagination" role="navigation" aria-label="Pagination">
              <ul className="pagination__list list-unstyled" >
                {pageState.first ? null : <li>
                  <span onClick={() => changePageTo(pageState.page - 1)} className="pagination__item pagination__item--next pagination__item-arrow link motion-reduce" aria-label="Next page"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-lag" viewBox="0 0 24 24"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"></path><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path></svg></span>
                </li>}
                {pageState.page === 1 ? null : <li><span onClick={() => changePageTo(1)} className="pagination__item link" aria-label="Page 1">1</span></li>}
                <li><span className="pagination__item pagination__item--current" aria-current="page" aria-label={`Page ${pageState.page}`}>{pageState.page}</span></li>
                {pageState.page === pageState.totalPages ? null : <li><span onClick={() => changePageTo(pageState.totalPages)} className="pagination__item link" aria-label={`Page ${pageState.page}`}>{pageState.totalPages}</span></li>}
                {pageState.last ? null : <li>
                  <span onClick={() => changePageTo(pageState.page + 1)} className="pagination__item pagination__item--prev pagination__item-arrow link motion-reduce" aria-label="Next page"><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-lag" viewBox="0 0 24 24"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"></path><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"></path></svg></span>
                </li>}
              </ul>
            </nav>
          </div>
        </div>
      </div></div>    </div>
  </>)
}