import { Axios } from "../utils/axios";
import { BASE_URL } from '../config';
import { STATUS } from "../constants/user";

const axios = new Axios();

export const login = async (data) => {
  const response = await axios.post(`${BASE_URL}auth/login`, data);
  if (response.data?.user?.userStatus === STATUS.active) {
    localStorage.setItem(
      "accessToken",
      response.data.accessToken
    );
    localStorage.setItem(
      "refreshToken",
      response.data.refreshToken
    );
    localStorage.setItem(
      "user",
      JSON.stringify(response.data.user)
    );
  }

  return response.data;
}

export const signup = async (data) => {
  const res = await axios.post(`${BASE_URL}auth/register-user`, data);
  return res;
}
