import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "src/config";
import { CartContext } from "src/context/cart";
import { getFormattedPrice } from "src/utils/formatNumber";

export const Cart = () => {
    const { cartItems, getCartTotal, updateCartItem, removeFromCart } = useContext(CartContext);
    const navigate = useNavigate();

    const CartItem = ({ quantity, product, id }) => {
        const [tempQuantity, setQuantity] = useState(quantity);

        useEffect(() => {
            if (tempQuantity && quantity !== tempQuantity) {
                updateCartItem(product, tempQuantity);
            }
        }, [tempQuantity, product, quantity])

        return (
            <tr class="cart-item" id="CartItem-1" key={id}>
                <td class="cart-item__media">
                    {product.images.map((image) =>
                        <img class="cart-item__image" src={BASE_URL + image.uri} alt={product.name} loading="lazy" width="75" height="66" />
                    )}

                </td>

                <td class="cart-item__details"><a href="/products/copy-of-agittis-iman-mohamed-abdulmajid" class="cart-item__name break">{product.name}</a><dl></dl>

                    <p class="product-option"></p><ul class="discounts list-unstyled" aria-label="Discount"></ul>

                    <p class="cart-item__error" id="Line-item-error-1">
                        <span class="cart-item__error-text"></span>
                        <svg aria-hidden="true" focusable="false" role="presentation" class="icon icon-error" viewBox="0 0 13 13">
                            <circle cx="6.5" cy="6.50049" r="5.5" stroke="white" stroke-width="2"></circle>
                            <circle cx="6.5" cy="6.5" r="5.5" fill="#EB001B" stroke="#EB001B" stroke-width="0.7"></circle>
                            <path d="M5.87413 3.52832L5.97439 7.57216H7.02713L7.12739 3.52832H5.87413ZM6.50076 9.66091C6.88091 9.66091 7.18169 9.37267 7.18169 9.00504C7.18169 8.63742 6.88091 8.34917 6.50076 8.34917C6.12061 8.34917 5.81982 8.63742 5.81982 9.00504C5.81982 9.37267 6.12061 9.66091 6.50076 9.66091Z" fill="white"></path>
                            <path d="M5.87413 3.17832H5.51535L5.52424 3.537L5.6245 7.58083L5.63296 7.92216H5.97439H7.02713H7.36856L7.37702 7.58083L7.47728 3.537L7.48617 3.17832H7.12739H5.87413ZM6.50076 10.0109C7.06121 10.0109 7.5317 9.57872 7.5317 9.00504C7.5317 8.43137 7.06121 7.99918 6.50076 7.99918C5.94031 7.99918 5.46982 8.43137 5.46982 9.00504C5.46982 9.57872 5.94031 10.0109 6.50076 10.0109Z" fill="white" stroke="#EB001B" stroke-width="0.7">
                            </path></svg>
                    </p>
                </td>

                <td class="cart-item__prices right">
                    <div class="cart-item__price-wrapper"><span class="price price--end">
                        {getFormattedPrice(product)}
                    </span></div>
                </td>

                <td class="cart-item__quantity">
                    <label class="medium-down" for="Quantity-1">
                        Quantity
                    </label>
                    <quantity-input class="quantity">
                        <button onClick={() => setQuantity(tempQuantity > 2 ? tempQuantity - 1 : 1)} class="quantity__button no-js-hidden" name="minus" type="button">
                            <span class="visually-hidden">Decrease quantity for {product.name}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="presentation" class="icon icon-minus" fill="none" viewBox="0 0 10 2">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M.5 1C.5.7.7.5 1 .5h8a.5.5 0 110 1H1A.5.5 0 01.5 1z" fill="currentColor">
                                </path></svg>

                        </button>
                        <input class="quantity__input" type="number" onChange={({target}) => setQuantity(target.value)} name="updates[]" value={tempQuantity} min="0" aria-label="Quantity for {product.name}" id="Quantity-1" data-index="1" />
                        <button onClick={() => setQuantity(tempQuantity < product.quantity ? tempQuantity + 1 : tempQuantity)} class="quantity__button no-js-hidden" name="plus" type="button">
                            <span class="visually-hidden">Increase quantity for {product.name}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="presentation" class="icon icon-plus" fill="none" viewBox="0 0 10 10">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 4.51a.5.5 0 000 1h3.5l.01 3.5a.5.5 0 001-.01V5.5l3.5-.01a.5.5 0 00-.01-1H5.5L5.49.99a.5.5 0 00-1 .01v3.5l-3.5.01H1z" fill="currentColor">
                                </path></svg>

                        </button>
                    </quantity-input>
                    <cart-remove-button id="Remove-1" data-index="1">
                        <a onClick={()=>removeFromCart(product)} class="button button--tertiary" aria-label="Remove {product.name}">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" aria-hidden="true" focusable="false" role="presentation" class="icon icon-remove">
                                <path d="M14 3h-3.53a3.07 3.07 0 00-.6-1.65C9.44.82 8.8.5 8 .5s-1.44.32-1.87.85A3.06 3.06 0 005.53 3H2a.5.5 0 000 1h1.25v10c0 .28.22.5.5.5h8.5a.5.5 0 00.5-.5V4H14a.5.5 0 000-1zM6.91 1.98c.23-.29.58-.48 1.09-.48s.85.19 1.09.48c.2.24.3.6.36 1.02h-2.9c.05-.42.17-.78.36-1.02zm4.84 11.52h-7.5V4h7.5v9.5z" fill="currentColor"></path>
                                <path d="M6.55 5.25a.5.5 0 00-.5.5v6a.5.5 0 001 0v-6a.5.5 0 00-.5-.5zM9.45 5.25a.5.5 0 00-.5.5v6a.5.5 0 001 0v-6a.5.5 0 00-.5-.5z" fill="currentColor"></path>
                            </svg>

                        </a>
                    </cart-remove-button>
                </td>


                <td class="cart-item__totals right">
                    <div class="loading-overlay hidden">
                        <div class="loading-overlay__spinner">
                            <svg aria-hidden="true" focusable="false" role="presentation" class="spinner" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                                <circle class="path" fill="none" stroke-width="6" cx="33" cy="33" r="30"></circle>
                            </svg>
                        </div>
                    </div>

                    <div class="cart-item__price-wrapper medium-up"><span class="price price--end">
                        {getFormattedPrice({ price: quantity * product.price })}
                    </span></div>
                    <cart-remove-button id="Remove-1" data-index="1">
                        <a href="/cart/change?id=42901444886767:036200a464c5e845dd3fe32ca4b58b5d&amp;quantity=0" class="button button--tertiary" aria-label="Remove {product.name}">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" aria-hidden="true" focusable="false" role="presentation" class="icon icon-remove">
                                <path d="M14 3h-3.53a3.07 3.07 0 00-.6-1.65C9.44.82 8.8.5 8 .5s-1.44.32-1.87.85A3.06 3.06 0 005.53 3H2a.5.5 0 000 1h1.25v10c0 .28.22.5.5.5h8.5a.5.5 0 00.5-.5V4H14a.5.5 0 000-1zM6.91 1.98c.23-.29.58-.48 1.09-.48s.85.19 1.09.48c.2.24.3.6.36 1.02h-2.9c.05-.42.17-.78.36-1.02zm4.84 11.52h-7.5V4h7.5v9.5z" fill="currentColor"></path>
                                <path d="M6.55 5.25a.5.5 0 00-.5.5v6a.5.5 0 001 0v-6a.5.5 0 00-.5-.5zM9.45 5.25a.5.5 0 00-.5.5v6a.5.5 0 001 0v-6a.5.5 0 00-.5-.5z" fill="currentColor"></path>
                            </svg>

                        </a>
                    </cart-remove-button>

                </td>
            </tr>
        )
    }

    return (<>

        <div class="top-column-content" id="TopColumnContent">
            <div class="page-width">
                <div class="title-breadcrumbs">
                    <div class="title-breadcrumbs-container">
                        <nav class="breadcrumb" role="navigation" aria-label="breadcrumbs">
                            <a href="/" class="breadcrumb-home" title="Home">Home</a>
                        </nav>

                        <h2 class="breadcrumb_title">Your Shopping Cart</h2>




                    </div>
                </div>
            </div>
        </div>


        <div id="shopify-section-template--16114105647343__cart-items" class="shopify-section"><link href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-cart.css?v=5994872309512455331654690474" rel="stylesheet" type="text/css" media="all" />
            <link href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-cart-items.css?v=128376823867396144711654690474" rel="stylesheet" type="text/css" media="all" />
            <link href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-totals.css?v=150189085208012042931654690484" rel="stylesheet" type="text/css" media="all" />
            <link href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-price.css?v=26672859977914416921654690481" rel="stylesheet" type="text/css" media="all" />
            <link href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-discounts.css?v=178181572341393928631654690476" rel="stylesheet" type="text/css" media="all" />
            <link href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-loading-overlay.css?v=135534932159979988391654690478" rel="stylesheet" type="text/css" media="all" />

            <cart-items class="page-width">
                <div class="title-wrapper-with-link">
                    {/* <!--     <h1 class="title title--primary">Your shopping cart</h1> --> */}
                </div>


                <form action="/cart" class="cart__contents critical-hidden" method="post" id="cart">
                    <div class="cart__items" id="main-cart-items" data-id="template--16114105647343__cart-items">
                        <div class="js-contents">
                            {
                                !cartItems.length ?
                                    <table class="cart-items">
                                        <h2 style={{ textAlign: 'center' }} class=" cart__empty-text">Your cart is empty</h2> </table> :
                                    <table class="cart-items">
                                        <thead>
                                            <tr>
                                                <th class="caption-with-letter-spacing" colspan="2" scope="col">Product</th>
                                                <th class="right caption-with-letter-spacing" colspan="1" scope="col">Price</th>
                                                <th class="cart-items__heading--wide medium-up caption-with-letter-spacing" colspan="1" scope="col">Quantity</th>
                                                <th class="medium-up right caption-with-letter-spacing" colspan="1" scope="col">Total</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {cartItems.map(({ product, quantity, id }) => <CartItem product={product} quantity={quantity} id={id} />)}
                                        </tbody>
                                    </table>}</div>
                    </div>

                    <p class="visually-hidden" id="cart-live-region-text" aria-live="polite" role="status"></p>
                    <p class="visually-hidden" id="shopping-cart-line-item-status" aria-live="polite" aria-hidden="true" role="status">Loading...</p>
                    <a href="/" class="underlined-link button">Continue shopping</a>
                </form>

            </cart-items>

            <script src="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/cart.js?v=120273284478549983441654690467" defer="defer"></script>


        </div><div id="shopify-section-template--16114105647343__cart-footer" class="shopify-section cart__footer-wrapper"><link href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-cart.css?v=5994872309512455331654690474" rel="stylesheet" type="text/css" media="all" />
            <link href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-totals.css?v=150189085208012042931654690484" rel="stylesheet" type="text/css" media="all" />
            <link href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-price.css?v=26672859977914416921654690481" rel="stylesheet" type="text/css" media="all" />
            <link href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/component-discounts.css?v=178181572341393928631654690476" rel="stylesheet" type="text/css" media="all" />

            <div class="page-width" id="main-cart-footer" data-id="template--16114105647343__cart-footer">
                <div>
                    <div class="cart__footer"><cart-note class="cart__note field">
                        <label for="Cart-note">Order special instructions</label>
                        <textarea class="text-area text-area--resize-vertical field__input" name="note" id="Cart-note" placeholder="Order special instructions"></textarea>
                    </cart-note><div>

                            <div class="js-contents">
                                <div class="totals">
                                    <h3 class="totals__subtotal">Subtotal</h3>
                                    <p class="totals__subtotal-value">{getFormattedPrice({ price: getCartTotal() })}</p>
                                </div>

                                <div></div>

                                <small class="tax-note caption-large rte">Taxes and shipping calculated at checkout
                                </small>
                            </div>
                            <div class="cart__ctas">
                                <noscript>
                                    <button type="submit" class="cart__update-button button button--secondary" form="cart">
                                        Update
                                    </button>
                                </noscript>

                                <button disabled={!cartItems.length} type="button" onClick={() => navigate('/checkout')} class="cart__checkout-button button" name="checkout" form="cart">
                                    Check Out
                                </button>
                            </div>

                            <div id="cart-errors"></div>
                        </div>
                    </div>
                </div>
            </div>




        </div>

    </>)
}