import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import './header.css'
import { useContext, useState } from 'react'
import { CartContext } from 'src/context/cart'
import { getCurrentUser, isLoggedIn } from 'src/utils/storage'
import { success } from 'src/utils/toast'

export const Header = () => {
  const { cartItems } = useContext(CartContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showMenu, setShowMenu] = useState(false);
  const [search, setSearch] = useState(searchParams.get("q") || "");
  const navigate = useNavigate();

  return (
    <>
      <div className="header-wrapper">
        <header className="header--top-center  header--has-menu">
          <div className="header page-width">
            <h1 className="header__heading">
              <Link to="/"
                className="header__heading-link link link--text focus-inset">
                <img
                  src="/logo.png" loading="lazy"
                  className="header__heading-logo" height="100"
                  alt="Hankman - Electronics Store" />
                {/* <span style={{margin: '1rem'}}>
                  HANKMAN
                </span> */}
              </Link>
            </h1>

            <div className="site-header__search">
              <predictive-search data-loading-text="Loading...">
                <form role="search"
                  className="search-header search">
                  <div className="field">
                    <input className="search-header__input search__input" id="Search-In-Template" type="search" name="q"
                      placeholder="Search Product Here..." role="combobox" aria-expanded="false"
                      aria-owns="predictive-search-results-list" aria-controls="predictive-search-results-list"
                      aria-haspopup="listbox" aria-autocomplete="list" autoCorrect="off" autoComplete="off"
                      autoCapitalize="off" value={search} onChange={(e) => {
                        setSearch(e.target.value);
                        if (!(e.target.value)) {
                          setSearchParams({})
                        }
                      }} spellCheck="false" />
                    <div className="predictive-search predictive-search--search-template" tabIndex="-1"
                      data-predictive-search="">
                      <div className="predictive-search__loading-state">
                        <svg aria-hidden="true" focusable="false" role="presentation" className="spinner" viewBox="0 0 66 66"
                        >
                          <circle className="path" fill="none" strokeWidth="6" cx="33" cy="33" r="30"></circle>
                        </svg>
                      </div>
                    </div>

                    <span className="predictive-search-status visually-hidden" role="status" aria-hidden="true"></span><Link to={`/products?q=${search}&category=${searchParams.category || ""}`}><button
                      className="search-header__submit button" type="Search">
                      <span className="icon__fallback-text">Search</span>

                    </button></Link>
                  </div>
                </form>
              </predictive-search>
            </div>


            <div className="header-right">


              <details-modal className="header__search">
                <details>
                  <summary
                    className="header__icon header__icon--search header__icon--summary link link--text focus-inset modal__toggle"
                    aria-haspopup="dialog" aria-label="Search Product Here..." role="button" aria-expanded="false">
                    <span>
                      <svg className="modal__toggle-open icon icon-search" aria-hidden="true" focusable="false"
                        role="presentation">
                        <use href="#icon-search">
                        </use>
                      </svg>
                      <svg className="modal__toggle-close icon icon-close" aria-hidden="true" focusable="false"
                        role="presentation">
                        <use href="#icon-close">
                        </use>
                      </svg>
                      {/* <div className="search_text">Search</div> */}
                    </span>
                  </summary>
                  <div className="search-modal modal__content" role="dialog" aria-modal="true"
                    aria-label="Search Product Here...">
                    <div className="search-modal__content" tabIndex="-1"><predictive-search className="search-modal__form"
                      data-loading-text="Loading...">
                      <form action="https://electbox-codezeel.myshopify.com/search" method="get" role="search"
                        className="search search-modal__form">
                        <div className="field">
                          <input className="search__input field__input" id="Search-In-Modal" type="search" name="q"
                            placeholder="Search Product Here..." role="combobox" aria-expanded="false"
                            aria-owns="predictive-search-results-list" aria-controls="predictive-search-results-list"
                            aria-haspopup="listbox" aria-autocomplete="list" autoCorrect="off" autoComplete="off"
                            autoCapitalize="off" spellCheck="false" />
                          <label className="field__label" htmlFor="Search-In-Modal">Search Product Here...</label>
                          <input type="hidden" name="options[prefix]" value="last" />
                          <button className="search__button field__button" aria-label="Search Product Here...">
                            <svg className="icon icon-search" aria-hidden="true" focusable="false" role="presentation">
                              <use href="#icon-search">
                              </use>
                            </svg>
                          </button>
                        </div>
                        <div className="predictive-search predictive-search--header" tabIndex="-1"
                          data-predictive-search="">
                          <div className="predictive-search__loading-state">
                            <svg aria-hidden="true" focusable="false" role="presentation" className="spinner"
                              viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                              <circle className="path" fill="none" strokeWidth="6" cx="33" cy="33" r="30"></circle>
                            </svg>
                          </div>
                        </div>

                        <span className="predictive-search-status visually-hidden" role="status" aria-hidden="true"></span>
                      </form>
                    </predictive-search><button type="button"
                      className="search-modal__close-button modal__close-button link link--text focus-inset"
                      aria-label="Close">
                        <svg className="icon icon-close" aria-hidden="true" focusable="false" role="presentation">
                          <use href="#icon-close">
                          </use>
                        </svg>
                      </button>
                    </div>
                  </div>
                </details>
              </details-modal>


              <div onClick={() => isLoggedIn() ? navigate("/my-cart") : navigate("/login")} className="hearder-cart header__icon header__icon--cart link--text focus-inset">
                <div className="overlay"></div>
                <a id="cart-icon-bubble" href=""><svg id="Layer_1" className="icon" data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 762.47 673.5">
                  <path
                    d="M600.86,489.86a91.82,91.82,0,1,0,91.82,91.82A91.81,91.81,0,0,0,600.86,489.86Zm0,142.93a51.12,51.12,0,1,1,51.11-51.11A51.12,51.12,0,0,1,600.82,632.79Z">
                  </path>
                  <path
                    d="M303.75,489.86a91.82,91.82,0,1,0,91.82,91.82A91.82,91.82,0,0,0,303.75,489.86Zm-.05,142.93a51.12,51.12,0,1,1,51.12-51.11A51.11,51.11,0,0,1,303.7,632.79Z">
                  </path>
                  <path d="M392.07,561.33h66.55a20.52,20.52,0,0,1,20.46,20.46h0a20.52,20.52,0,0,1-20.46,20.46H392.07">
                  </path>
                  <path
                    d="M698.19,451.14H205.93a23.11,23.11,0,0,1-23.09-22c0-.86-.09-1.72-.19-2.57l-1.82-16.36H723.51L721.3,428A23.11,23.11,0,0,1,698.19,451.14Z">
                  </path>
                  <path
                    d="M759.15,153.79H246.94l-3.32-24.38a17.25,17.25,0,0,1,17.25-17.26H745.21a17.26,17.26,0,0,1,17.26,17.26Z">
                  </path>
                  <path
                    d="M271.55,345.56l-31.16-208A20.53,20.53,0,0,1,257.13,114h0a20.53,20.53,0,0,1,23.6,16.74l31.16,208a20.52,20.52,0,0,1-16.74,23.59h0A20.52,20.52,0,0,1,271.55,345.56Z">
                  </path>
                  <path
                    d="M676,451.15l48.69-337.74,22.9.07a17.25,17.25,0,0,1,14.55,19.59l-42.1,303.16a17.24,17.24,0,0,1-19.59,14.54Z">
                  </path>
                  <path
                    d="M184.24,436.27,123.7.12l23.72,0a17.26,17.26,0,0,1,19.33,14.92l60.56,436.35-23.74-.25A17.25,17.25,0,0,1,184.24,436.27Z">
                  </path>
                  <path d="M148.38,40.77H20.26A20.32,20.32,0,0,1,0,20.51H0A20.32,20.32,0,0,1,20.26.25H148.38"></path>
                </svg><span className="visually-hidden">My cart</span>
                  <div className="cart-count-bubble">
                    <span aria-hidden="true">{cartItems.length}</span>
                    <span className="visually-hidden">{cartItems.length} items</span>

                  </div>
                  <span className="cart_text">My Cart</span>
                </a>

              </div>

              <div className="account_dropdown" onMouseEnter={() => setShowMenu(true)} onMouseLeave={() => setShowMenu(false)} onClickCapture={() => setShowMenu(false)}>
                <div className="account_icon">
                  <svg fill="#8d8d8d" id="Capa_1" className="icon" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 477.12 512">
                    <path
                      d="M256,288.39c-153.84,0-238.56,72.78-238.56,204.92A18.69,18.69,0,0,0,36.13,512H475.87a18.69,18.69,0,0,0,18.69-18.69C494.56,361.17,409.84,288.39,256,288.39ZM55.49,474.63C62.84,375.82,130.21,325.76,256,325.76s193.16,50.06,200.52,148.87Z"
                      transform="translate(-17.44)"></path>
                    <path
                      d="M256,0C185.33,0,132.05,54.36,132.05,126.44c0,74.19,55.6,134.54,124,134.54s124-60.35,124-134.54C380,54.36,326.67,0,256,0Zm0,223.61c-47.74,0-86.58-43.59-86.58-97.17,0-51.61,36.41-89.07,86.58-89.07,49.36,0,86.58,38.29,86.58,89.07C342.58,180,303.74,223.61,256,223.61Z"
                      transform="translate(-17.44)"></path>
                  </svg>
                </div>
                <span className="account_toggle"> Account </span>
                <ul className="my-account" hidden={!showMenu}>

                  {isLoggedIn() ? <>

                    <li className="cm-wishlist-button">
                      <span className="whishlist-text cm-wishlist-icon js-popup-button">{getCurrentUser()?.username}</span>
                    </li>
                    <li className="cm-wishlist-button">
                      <span className="whishlist-text cm-wishlist-icon js-popup-button">Orders</span>
                      (<span className="cm-wishlist-counter cm-wishlist-button" data-js-wishlist-count="0">0</span>)
                    </li>
                    <li className="cm-wishlist-button"><span className="whishlist-text cm-wishlist-icon js-popup-button" onClick={() => {
                      localStorage.clear();
                      navigate('/');
                      success("Logged out!")
                    }}>Log out</span></li>
                  </> :
                    <>
                      <li className="cm-wishlist-button"><Link className="whishlist-text cm-wishlist-icon js-popup-button" to="/login">Log in</Link></li>
                      <li className="cm-wishlist-button"><Link className="whishlist-text cm-wishlist-icon js-popup-button" to="/signup">Create Account</Link></li>
                    </>}

                </ul>

              </div>
            </div>
          </div>
        </header>
        <div className="header-top-menu">
          <div className="page-width">

            <div className="site_header_menu">
              <nav className="header__inline-menu">
                <ul id="header-nav" className="list-menu list-menu--inline">
                  <li><Link to="/"
                    className="header__menu-item header__menu-item list-menu__item link link--text focus-inset"
                    aria-current="page">
                    <span className="header__active-menu-item">Home</span>
                  </Link></li>
                  <li><Link to="/products"
                    className="header__menu-item header__menu-item list-menu__item link link--text focus-inset">
                    <span>Catalog</span>
                  </Link></li>
                  <li><Link to="/contact-us"
                    className="header__menu-item header__menu-item list-menu__item link link--text focus-inset">
                    <span>Contact</span>
                  </Link></li>=
                </ul>
              </nav><header-drawer>
                <details className="menu-drawer-container">
                  <div className="menu_overlay"></div>
                  <summary className="header__icon header__icon--menu header__icon--summary link link--text focus-inset"
                    aria-label="Menu" role="button" aria-expanded="false" aria-controls="menu-drawer">
                    <span>
                      <svg version="1.1" className="icon icon-hamburger" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1000 1000"
                        enableBackground="new 0 0 1000 1000">
                        <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                        <g>
                          <path
                            d="M928.8,261.7H71.3c-33.8,0-61.3-27.4-61.3-61.3c0-33.9,27.4-61.3,61.3-61.3h857.5c33.9,0,61.3,27.4,61.3,61.3C990,234.3,962.6,261.7,928.8,261.7z">
                          </path>
                          <path
                            d="M928.8,561.2H71.3C37.4,561.2,10,533.9,10,500c0-33.9,27.4-61.3,61.3-61.3h857.5c33.9,0,61.3,27.4,61.3,61.3C990,533.9,962.6,561.2,928.8,561.2z">
                          </path>
                          <path
                            d="M928.8,860.8H71.3c-33.8,0-61.3-27.4-61.3-61.3c0-33.9,27.4-61.3,61.3-61.3h857.5c33.9,0,61.3,27.4,61.3,61.3C990,833.4,962.6,860.8,928.8,860.8z">
                          </path>
                        </g>
                      </svg>

                    </span>
                  </summary>
                  <div id="menu-drawer" className="menu-drawer motion-reduce" tabIndex="-1">
                    <div className="menu-drawer__inner-container">
                      <summary className="header__icon--menu close" aria-label="Menu" role="button" aria-expanded="false"
                        aria-controls="">
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" role="presentation"
                            className="icon icon-close" viewBox="0 0 18 17">
                            <path
                              d="M.865 15.978a.5.5 0 00.707.707l7.433-7.431 7.579 7.282a.501.501 0 00.846-.37.5.5 0 00-.153-.351L9.712 8.546l7.417-7.416a.5.5 0 10-.707-.708L8.991 7.853 1.413.573a.5.5 0 10-.693.72l7.563 7.268-7.418 7.417z">
                            </path>
                          </svg>

                        </span>
                      </summary>
                      <div className="menu-drawer__navigation-container">
                        <nav className="menu-drawer__navigation">
                          <ul className="menu-drawer__menu list-menu">
                            <li><Link to="/"
                              className="menu-drawer__menu-item list-menu__item link link--text focus-inset menu-drawer__menu-item--active"
                              aria-current="page">
                              Home
                            </Link></li>
                            <li><Link to="/products"
                              className="menu-drawer__menu-item list-menu__item link link--text focus-inset">
                              Catalog
                            </Link></li>
                            <li><Link to="/contact-us"
                              className="menu-drawer__menu-item list-menu__item link link--text focus-inset">
                              Contact
                            </Link></li>
                          </ul>
                        </nav>
                        <div className="menu-drawer__utility-links">
                          <ul className="list list-social list-unstyled">
                            <li className="list-social__item">
                              <a href="/"
                                className="link link--text list-social__link" aria-describedby="a11y-external-message"><svg
                                  aria-hidden="true" focusable="false" role="presentation" className="icon icon-twitter"
                                  viewBox="0 0 18 15">
                                  <path
                                    d="M17.64 2.6a7.33 7.33 0 01-1.75 1.82c0 .05 0 .13.02.23l.02.23a9.97 9.97 0 01-1.69 5.54c-.57.85-1.24 1.62-2.02 2.28a9.09 9.09 0 01-2.82 1.6 10.23 10.23 0 01-8.9-.98c.34.02.61.04.83.04 1.64 0 3.1-.5 4.38-1.5a3.6 3.6 0 01-3.3-2.45A2.91 2.91 0 004 9.35a3.47 3.47 0 01-2.02-1.21 3.37 3.37 0 01-.8-2.22v-.03c.46.24.98.37 1.58.4a3.45 3.45 0 01-1.54-2.9c0-.61.14-1.2.45-1.79a9.68 9.68 0 003.2 2.6 10 10 0 004.08 1.07 3 3 0 01-.13-.8c0-.97.34-1.8 1.03-2.48A3.45 3.45 0 0112.4.96a3.49 3.49 0 012.54 1.1c.8-.15 1.54-.44 2.23-.85a3.4 3.4 0 01-1.54 1.94c.74-.1 1.4-.28 2.01-.54z">
                                  </path>
                                </svg>
                                <span className="visually-hidden">Twitter</span>
                              </a>
                            </li>
                            <li className="list-social__item">
                              <a href="/"
                                className="link link--text list-social__link" aria-describedby="a11y-external-message"><svg
                                  aria-hidden="true" focusable="false" role="presentation" className="icon icon-facebook"
                                  viewBox="0 0 18 18">
                                  <path
                                    d="M16.42.61c.27 0 .5.1.69.28.19.2.28.42.28.7v15.44c0 .27-.1.5-.28.69a.94.94 0 01-.7.28h-4.39v-6.7h2.25l.31-2.65h-2.56v-1.7c0-.4.1-.72.28-.93.18-.2.5-.32 1-.32h1.37V3.35c-.6-.06-1.27-.1-2.01-.1-1.01 0-1.83.3-2.45.9-.62.6-.93 1.44-.93 2.53v1.97H7.04v2.65h2.24V18H.98c-.28 0-.5-.1-.7-.28a.94.94 0 01-.28-.7V1.59c0-.27.1-.5.28-.69a.94.94 0 01.7-.28h15.44z">
                                  </path>
                                </svg>
                                <span className="visually-hidden">Facebook</span>
                              </a>
                            </li>
                            <li className="list-social__item">
                              <a href="/"
                                className="link link--text list-social__link" aria-describedby="a11y-external-message"><svg
                                  aria-hidden="true" focusable="false" role="presentation" className="icon icon-pinterest"
                                  viewBox="0 0 17 18">
                                  <path
                                    d="M8.48.58a8.42 8.42 0 015.9 2.45 8.42 8.42 0 011.33 10.08 8.28 8.28 0 01-7.23 4.16 8.5 8.5 0 01-2.37-.32c.42-.68.7-1.29.85-1.8l.59-2.29c.14.28.41.52.8.73.4.2.8.31 1.24.31.87 0 1.65-.25 2.34-.75a4.87 4.87 0 001.6-2.05 7.3 7.3 0 00.56-2.93c0-1.3-.5-2.41-1.49-3.36a5.27 5.27 0 00-3.8-1.43c-.93 0-1.8.16-2.58.48A5.23 5.23 0 002.85 8.6c0 .75.14 1.41.43 1.98.28.56.7.96 1.27 1.2.1.04.19.04.26 0 .07-.03.12-.1.15-.2l.18-.68c.05-.15.02-.3-.11-.45a2.35 2.35 0 01-.57-1.63A3.96 3.96 0 018.6 4.8c1.09 0 1.94.3 2.54.89.61.6.92 1.37.92 2.32 0 .8-.11 1.54-.33 2.21a3.97 3.97 0 01-.93 1.62c-.4.4-.87.6-1.4.6-.43 0-.78-.15-1.06-.47-.27-.32-.36-.7-.26-1.13a111.14 111.14 0 01.47-1.6l.18-.73c.06-.26.09-.47.09-.65 0-.36-.1-.66-.28-.89-.2-.23-.47-.35-.83-.35-.45 0-.83.2-1.13.62-.3.41-.46.93-.46 1.56a4.1 4.1 0 00.18 1.15l.06.15c-.6 2.58-.95 4.1-1.08 4.54-.12.55-.16 1.2-.13 1.94a8.4 8.4 0 01-5-7.65c0-2.3.81-4.28 2.44-5.9A8.04 8.04 0 018.48.57z">
                                  </path>
                                </svg>
                                <span className="visually-hidden">Pinterest</span>
                              </a>
                            </li>
                            <li className="list-social__item">
                              <a href="/"
                                className="link link--text list-social__link" aria-describedby="a11y-external-message"><svg
                                  aria-hidden="true" focusable="false" role="presentation" className="icon icon-instagram"
                                  viewBox="0 0 18 18">
                                  <path
                                    d="M8.77 1.58c2.34 0 2.62.01 3.54.05.86.04 1.32.18 1.63.3.41.17.7.35 1.01.66.3.3.5.6.65 1 .12.32.27.78.3 1.64.05.92.06 1.2.06 3.54s-.01 2.62-.05 3.54a4.79 4.79 0 01-.3 1.63c-.17.41-.35.7-.66 1.01-.3.3-.6.5-1.01.66-.31.12-.77.26-1.63.3-.92.04-1.2.05-3.54.05s-2.62 0-3.55-.05a4.79 4.79 0 01-1.62-.3c-.42-.16-.7-.35-1.01-.66-.31-.3-.5-.6-.66-1a4.87 4.87 0 01-.3-1.64c-.04-.92-.05-1.2-.05-3.54s0-2.62.05-3.54c.04-.86.18-1.32.3-1.63.16-.41.35-.7.66-1.01.3-.3.6-.5 1-.65.32-.12.78-.27 1.63-.3.93-.05 1.2-.06 3.55-.06zm0-1.58C6.39 0 6.09.01 5.15.05c-.93.04-1.57.2-2.13.4-.57.23-1.06.54-1.55 1.02C1 1.96.7 2.45.46 3.02c-.22.56-.37 1.2-.4 2.13C0 6.1 0 6.4 0 8.77s.01 2.68.05 3.61c.04.94.2 1.57.4 2.13.23.58.54 1.07 1.02 1.56.49.48.98.78 1.55 1.01.56.22 1.2.37 2.13.4.94.05 1.24.06 3.62.06 2.39 0 2.68-.01 3.62-.05.93-.04 1.57-.2 2.13-.41a4.27 4.27 0 001.55-1.01c.49-.49.79-.98 1.01-1.56.22-.55.37-1.19.41-2.13.04-.93.05-1.23.05-3.61 0-2.39 0-2.68-.05-3.62a6.47 6.47 0 00-.4-2.13 4.27 4.27 0 00-1.02-1.55A4.35 4.35 0 0014.52.46a6.43 6.43 0 00-2.13-.41A69 69 0 008.77 0z">
                                  </path>
                                  <path
                                    d="M8.8 4a4.5 4.5 0 100 9 4.5 4.5 0 000-9zm0 7.43a2.92 2.92 0 110-5.85 2.92 2.92 0 010 5.85zM13.43 5a1.05 1.05 0 100-2.1 1.05 1.05 0 000 2.1z">
                                  </path>
                                </svg>
                                <span className="visually-hidden">Instagram</span>
                              </a>
                            </li>
                            <li className="list-social__item">
                              <a href="/"
                                className="link link--text list-social__link" aria-describedby="a11y-external-message"><svg
                                  aria-hidden="true" focusable="false" role="presentation" className="icon icon-youtube"
                                  viewBox="0 0 100 70">
                                  <path
                                    d="M98 11c2 7.7 2 24 2 24s0 16.3-2 24a12.5 12.5 0 01-9 9c-7.7 2-39 2-39 2s-31.3 0-39-2a12.5 12.5 0 01-9-9c-2-7.7-2-24-2-24s0-16.3 2-24c1.2-4.4 4.6-7.8 9-9 7.7-2 39-2 39-2s31.3 0 39 2c4.4 1.2 7.8 4.6 9 9zM40 50l26-15-26-15v30z">
                                  </path>
                                </svg>
                                <span className="visually-hidden">YouTube</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </details>
              </header-drawer>
              <div className="header_button">

                <a type="tel:+919914871595" className="header_menu_link">
                  Need Help? (+91) 99148 71595
                </a>
              </div>
            </div>
          </div>
        </div>
      </div><cart-notification>
        <div className="cart-notification-wrapper page-width">
          <div id="cart-notification" className="cart-notification focus-inset" aria-modal="true"
            aria-label="Item added to your cart" role="dialog" tabIndex="-1">

            <div className="cart-notification-inner">
              <div className="cart-notification__header">
                <button type="button" className="cart-notification__close modal__close-button link link--text focus-inset"
                  aria-label="Close">
                  <svg className="icon icon-close" aria-hidden="true" focusable="false">
                    <use href="#icon-close"></use>
                  </svg>
                </button>
              </div>
              <div id="cart-notification-product" className="cart-notification-product"></div>
              <div className="cart-notification__links">
                {/* <a href="/cart" id="cart-notification-button"
                  className="button button--secondary button--full-width"></a> */}
                <form action="https://electbox-codezeel.myshopify.com/cart" method="post" id="cart">
                  <button className="button button--primary button--full-width" name="checkout" form="cart">Check out</button>
                </form>
              </div>
              <button type="button" className="link button-label">Continue shopping</button>
            </div>
          </div>
        </div>
      </cart-notification>
    </>)
}