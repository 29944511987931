export const ContactUs = () => {

  return (<>
    <div className="top-column-content" id="TopColumnContent">
      <div className="page-width">
        <div className="title-breadcrumbs">
          <div className="title-breadcrumbs-container">
            <nav
              className="breadcrumb"
              role="navigation"
              aria-label="breadcrumbs"
            >
              <a href="/" className="breadcrumb-home" title="Home">
                Home
              </a>
              <span>Contact Us</span>
            </nav>
            <h2 className="breadcrumb_title">Contact Us</h2>
          </div>
        </div>
      </div>
    </div>
    <section
      id="shopify-section-template--16114105778415__form"
      className="shopify-section spaced-section contact"
    >
      <link
        href="//electbox-codezeel.myshopify.com/cdn/shop/t/3/assets/section-contact-form.css?v=132073197748453164971654690504"
        rel="stylesheet"
        type="text/css"
        media="all"
      />
      <div className="page-width contact-page">
        <div className="contact-us">
          <div className="contact-us-form">
            <div className="spaced-section">
              <h2 className="Contact-title title">Contact Us</h2>
              <form
                method="post"
                action="/contact#ContactForm"
                id="ContactForm"
                acceptCharset="UTF-8"
                className="contact-form"
              >
                <input type="hidden" name="form_type" defaultValue="contact" />
                <input type="hidden" name="utf8" defaultValue="✓" />
                <div className="contact__fields">
                  <div className="field">
                    <input
                      className="field__input"
                      autoComplete="name"
                      type="text"
                      id="ContactForm-name"
                      name="contact[Name]"
                      defaultValue=""
                      placeholder="Name"
                    />
                    <label className="field__label" htmlFor="ContactForm-name">
                      Name
                    </label>
                  </div>
                  <div className="field field--with-error">
                    <input
                      autoComplete="email"
                      type="email"
                      id="ContactForm-email"
                      className="field__input"
                      name="contact[email]"
                      spellCheck="false"
                      autoCapitalize="off"
                      defaultValue=""
                      aria-required="true"
                      placeholder="Email"
                    />
                    <label className="field__label" htmlFor="ContactForm-email">
                      Email <span aria-hidden="true">*</span>
                    </label>
                  </div>
                </div>
                <div className="field">
                  <input
                    type="tel"
                    id="ContactForm-phone"
                    className="field__input"
                    autoComplete="tel"
                    name="contact[Phone number]"
                    pattern="[0-9\-]*"
                    defaultValue=""
                    placeholder="Phone number"
                  />
                  <label className="field__label" htmlFor="ContactForm-phone">
                    Phone number
                  </label>
                </div>
                <div className="field">
                  <textarea
                    rows={10}
                    id="ContactForm-body"
                    className="text-area field__input"
                    name="contact[Comment]"
                    placeholder="Comment"
                    defaultValue={""}
                  />
                  <label
                    className="form__label field__label"
                    htmlFor="ContactForm-body"
                  >
                    Comment
                  </label>
                </div>
                <div className="contact__button">
                  <button type="submit" className="button">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="site-footer__address">
            <h2 className="contact-info title">Get in Touch With Us</h2>
            <div className="contact-des">
              We create premium and professional website templates for most
              popular platforms available in the market today
            </div>
            <div className="address_content info">
              <i aria-hidden="true" className="fa fa-home" />
              <span>
                Shahdara, New Delhi, Delhi, India
              </span>
            </div>
            <div className="contact_content info">
              <i aria-hidden="true" className="fa fa-phone" />
              <span>(+91) 99148 71595</span>
            </div>
            <div className="email_content info">
              <i aria-hidden="true" className="fa fa-envelope" />
              <span>
                <a href="mailto:contacthankmanteam@gmail.com" target="_Self">
                  contacthankmanteam@gmail.com
                </a>
              </span>
            </div>
            <div className="time-info info">
              <i aria-hidden="true" className="fa fa-info" />
              <span>Monday – Friday 10 AM – 8 PM</span>
            </div>
          </div>
        </div>
        <div className="map">
          <span>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12617.689403277553!2d-122.46322093501155!3d37.75669390222364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80859a6d00690021%3A0x4a501367f076adff!2sSan%20Francisco%2C%20CA!5e0!3m2!1sen!2sus!4v1643092942713!5m2!1sen!2sus"
              width={600}
              height={450}
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            />
          </span>
        </div>
      </div>
    </section>

  </>)
}