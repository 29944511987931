import './subbanner.css';

export const SubBanner = () => {
    return (
        <section id="shopify-section-template--16114105712879__1626172657e8a64879"
          className="shopify-section spaced-section subbanners-section spaced-section--full-width">
 
          <div className="tm-banners">
            <div className="page-width page-width-desktop">
              <div className="page-width-inner one-banner">
                <div className="sub-banner banner-1">
                  <a href="/">
                    <div className="banner-inner">

                      <img src="/images/sub-banner-1.jpg"
                        itemProp="banner" alt="banner"/>

                    </div>
                    <div className="subbanner-custom">
                      <div className="cms-banner-title-three">
                        Extra 30% Off
                      </div>
                      <div className="cms-banner-subtitle-three">
                        Prolet Designed<br/>
                        For AirPods
                      </div>

                      <div className="text-button">
                        <div className="button cms__btn">
                          Shop Now
                        </div>
                      </div>

                    </div>

                  </a>
                </div>
                <div className="sub-banner banner-2">
                  <a href="/collections/drills">
                    <div className="banner-inner">

                      <img src="/images/sub-banner-2.jpg"
                        itemProp="banner" alt="banner"/>

                    </div>
                    <div className="subbanner-custom">
                      <div className="cms-banner-title-three">
                        Extra 30% Off
                      </div>
                      <div className="cms-banner-subtitle-three">
                        EasySkinz XBOX<br/>
                        Series X
                      </div>

                      <div className="text-button">
                        <div className="button cms__btn">
                          shop now
                        </div>
                      </div>

                    </div>

                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>)
}