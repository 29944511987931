import * as React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useLocation,
} from "react-router-dom";


import './App.css';
import { Home } from './pages/Home';
import { AnnouncementBar } from './layout/AnnouncementBar';
import { Footer } from './layout/Footer';
import { Header } from './layout/Header';
import { Newsletter } from './layout/Newsletter';
import { Signup } from "./pages/auth/Signup";
import { Sidebar } from "./layout/Sidebar";
import { Login } from "./pages/auth/Login";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DashboardAppPage from "./vendor/pages/DashboardAppPage";
import DashboardLayout from "./vendor/layout/dashboard/DashboardLayout";
import ProductPage from "./vendor/pages/ProductPage";
import BrandPage from "./vendor/pages/BrandPage";
import CategoryPage from "./vendor/pages/CategoriesPage";
import SliderPage from "./vendor/pages/SliderPage";
import MessagePage from "./vendor/pages/MessagePage";
import { ProductDetail } from "./pages/productDetail/ProductDetail";
import { CartProvider } from "./context/cart";
import { Cart } from "./pages/cart/Cart";
import { Checkout } from "./pages/checkout/Checkout";
import { Products } from "./pages/products/Products";
import ManageOrderPage from "./vendor/pages/ManageOrderPage";
import { ContactUs } from "./pages/contactUs/ContactUs";

const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

const Layout = () => {
  return (<>

    <CartProvider>
      <AnnouncementBar />
      <Header />
      <main className="main-content" id="MainContent" role="main" tabIndex="-1">
        <div className="page-width contents">
          <Sidebar />
          <div className="main-container medium-up--three-quarters">
            <Outlet />
          </div>
        </div>
      </main>
      <Newsletter />
      <Footer />
      <ScrollToTop />
    </CartProvider></>)
}

const router = createBrowserRouter([
  {
    element: <DashboardLayout />,
    path: "/dashboard",
    children: [
      { path: '', element: <DashboardAppPage /> },
      { path: 'products', element: <ProductPage /> },
      { path: 'brand', element: <BrandPage /> },
      { path: 'category', element: <CategoryPage /> },
      { path: 'sliders', element: <SliderPage /> },
      { path: 'messages', element: <MessagePage /> },
      { path: 'manage-order', element: <ManageOrderPage /> },
    ]
  },
  {
    element: <Layout />,
    path: "/",
    children: [
      {
        path: "signup",
        element: (<Signup />),
      },
      {
        path: "login",
        element: (<Login />),
      },
      {
        path: "/products/:id",
        element: (
          <ProductDetail />
        ),
      },
      {
        path: "/my-cart",
        element: (
          <Cart />
        ),
      },
      {
        path: "/checkout",
        element: (
          <Checkout />
        ),
      },
      {
        path: "/products",
        element: (
          <Products />
        ),
      },
      {
        path: "/contact-us",
        element: (
          <ContactUs />
        )
      },
      {
        path: "/",
        element: (
          <Home />
        ),
      },
    ]
  }

]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
      <ToastContainer autoClose={5000} />
    </div>
  );
}

export default App;
