import { Formik, Form } from "formik";
import { loginSchema } from '../../schema/auth';
import './auth.css';
import { login } from "../../services/auth";
import { error, success } from "../../utils/toast";
import $ from 'jquery';
import { Link, useNavigate } from "react-router-dom";
import { ROLES } from "../../constants/user";
import { useContext, useEffect } from "react";
import { CartContext } from "src/context/cart";
import axios from "axios";
import { BASE_URL } from "../../config";

export const Login = () => {
  const navigate = useNavigate();
  const { fetchCartData } = useContext(CartContext);

  useEffect(() => {
    // Function to get query parameters from the URL
    const getQueryParam = (name) => {
      const params = new URLSearchParams(window.location.search);
      return params.get(name);
    };

    // Extract query parameters from the URL
    const isProcessVerifyEmail = getQueryParam('isProcessVerifyEmail');
    const email = getQueryParam('email');
    const emailVerificationCode = getQueryParam('emailVerificationCode');

    // Make a request to your backend to verify the email
    if (isProcessVerifyEmail === 'true' && email && emailVerificationCode) {
      axios.post(`${BASE_URL}auth/verify-user?email=${email}&isVerify=${isProcessVerifyEmail}`)
        .then(response => {
          console.log(response.data);
          success("User Email Verification Successfully!")
        })
        .catch(err => {
          console.error(err);
          error("Email Verification Failed!")
        });
    }

  }, []);

  function showRecoverPasswordForm() {
    $('#recover, #recover+div').css('display', 'block');
    $('#login, #login+div').css('display', 'none');
    return false;
  }
  function hideRecoverPasswordForm() {
    $('#recover, #recover+div').css('display', 'none');
    $('#login, #login+div').css('display', 'block');
    return false;
  }

  return (<><div className="top-column-content" id="TopColumnContent">
    <div className="page-width">
      <div className="title-breadcrumbs">
        <div className="title-breadcrumbs-container">
          <nav className="breadcrumb" role="navigation" aria-label="breadcrumbs">
            <Link to="/" className="breadcrumb-home" title="Home">Home</Link>
          </nav>

          <h2 className="breadcrumb_title">Account</h2>
        </div>
      </div>
    </div>
  </div>
    <div className="customer login">
      <h1 id="recover" tabIndex="-1">
        Reset your password
      </h1>
      <div>
        <p>
          We will send you an email to reset your password
        </p><form method="post" action="/account/recover" acceptCharset="UTF-8">
          <div className="field">
            <input type="email" value="" name="email" id="RecoverEmail" autoCorrect="off" autoCapitalize="off" autoComplete="email" placeholder="Email" />
            <label htmlFor="RecoverEmail">
              Email
            </label>
          </div><button>
            Submit
          </button>

          <div onClick={hideRecoverPasswordForm}>
            <span className="link">Cancel</span>
          </div></form></div>

      <h1 id="login" tabIndex="-1">
        Login
      </h1>
      <div>

        <Formik
          initialValues={{}}
          validationSchema={loginSchema}
          onSubmit={async (values) => {
            try {
              const res = await login(values);
              success("Logged in successfully!")
              if (res?.user?.role?.role === ROLES.vendor) {
                navigate("/dashboard")
              } else if (res?.user?.role?.role === ROLES.user) {
                fetchCartData();
                navigate("/");
              } else {
                navigate("/");
              }
            } catch (ex) {
              error(ex?.response?.data?.message || "Failed to login!")
            }
          }}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit} id="customer_login" acceptCharset="UTF-8" data-login-with-shop-sign-in="true" noValidate="noValidate">
              <div className="field">
                <input className={`${formik.touched.username && formik.errors.username && 'is-invalid'}`} type="text" name="username" id="RegisterForm-UserName" autoComplete="username" placeholder="Username"
                  onChange={formik.handleChange}
                  value={formik.values.username}
                  onBlur={formik.handleBlur}
                />
                <label htmlFor="RegisterForm-UserName">
                  Username
                </label>
              </div>
              {formik.touched.username && formik.errors.username && (<span id="RegisterForm-email-error" className="form__message">
                <svg aria-hidden="true" focusable="false" role="presentation">
                  <use href="-error"></use>
                </svg>
                {formik.errors.username}
              </span>)}
              <div className="field">
                <input className={`${formik.touched.password && formik.errors.password && 'is-invalid'}`} type="password" name="password" id="RegisterForm-password" aria-required="true" placeholder="Password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                />
                <label htmlFor="RegisterForm-password">
                  Password
                </label>
              </div>
              {formik.touched.password && formik.errors.password && (<span id="RegisterForm-email-error" className="form__message">
                <svg aria-hidden="true" focusable="false" role="presentation">
                  <use href="#icon-error"></use>
                </svg>
                {formik.errors.password}
              </span>)}

              <div onClick={showRecoverPasswordForm}>
                <span className="link">Forgot your password?</span>
              </div> <button type="submit" disabled={formik.isSubmitting || Object.keys(formik.errors).length}>
                Login
              </button>

              <Link to="/signup" className="button">
                create account
              </Link> </Form>
          )}
        </Formik></div></div>
  </>)
}