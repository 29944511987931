import { Axios } from "../utils/axios";
import { BASE_URL } from '../config';

const axios = new Axios();

export const addCategory = async (data) => {
    const res = await axios.post(`${BASE_URL}api/categories`, data);
    return res.data
}
export const getCategory = async () => {
    const res = await axios.get(`${BASE_URL}api/categories`);
    return res.data
}
export const deleteCategory = async (id) => {
    const res = await axios.delete(`${BASE_URL}api/categories/${id}`);
    return res
}
export const updateCategory = async (id,data) => {
    const res = await axios.put(`${BASE_URL}api/categories/${id}`, data);
    return res.data
}

export const getPublicCategories = async () => {
    const res = await axios.get(`${BASE_URL}public/categories`);
    return res.data
}
