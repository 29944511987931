import {Formik} from 'formik';
import "react-confirm-alert/src/react-confirm-alert.css";
import {
    Button,
    Card,
    Checkbox,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Popover,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar/Scrollbar";
import {useEffect, useState} from "react";
import { updateOrder, getAllOrder} from "../../services/manage-order";
import {error, success} from "../../utils/toast";
import {filter} from "lodash";
import {ManageOrderListHead, ManageOrderListToolbar} from "../sections/@dashboard/manage-order";


const TABLE_HEAD = [
    { id: 'id', label: 'Id', alignRight: false },
    { id: 'fullName', label: 'Full Name', alignRight: false },
    { id: 'phone', label: 'Phone', alignRight: false },
    { id: 'orderTotal', label: 'Order Total', alignRight: false },
    { id: 'orderStatus', label: 'Order Status', alignRight: false },
    { id: 'address1', label: 'Shipping Address', alignRight: false },
    { id: 'createdAt', label: 'Created At', alignRight: false },
    { id: 'lastModifiedAt', label: 'Updated At', alignRight: false },
    { id: '' },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_order) => _order.user?.fullName?.toLowerCase().indexOf(query.toLowerCase()) !== -1 || _order.orderStatus?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function ManageOrderPage() {
    const [open, setOpen] = useState(null);
    const [orderList, setOrderList] = useState([]);

    const [openAdd, setOpenAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [actionOrder, setActionOrder] = useState();
    const [edit, setEdit] = useState(false);

    // const handleClickOpen = () => {
    //     setOpenAdd(true);
    // };

    const handleClose = () => {
        setEdit(false);
        setOpenAdd(false);
    };

    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = orderList.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderList.length) : 0;

    const filteredOrder = applySortFilter(orderList, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredOrder.length && !!filterName;

    const getData = async () => {
        setLoading(true);
        try {
            const orders = await getAllOrder();
            console.log('Orders : ', orders)
            setOrderList(orders)
        } catch (e) {
            error(e.message || "Failed to get categories")
        }
        setLoading(false)
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            <title> Manage Order | Hankman </title>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Manage order
                    </Typography>
                </Stack>

                <Card>
                    <ManageOrderListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <ManageOrderListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={orderList.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {filteredOrder.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const { id, user, orderTotal, orderStatus, shippingAddress, createdAt, lastModifiedAt } = row;
                                        const selectedCategory = selected.indexOf(orderTotal) !== -1;

                                        return (
                                            <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedCategory}>
                                                <TableCell padding="checkbox">
                                                    <Checkbox checked={selectedCategory} onChange={(event) => handleClick(event, user?.fullName)} />
                                                </TableCell>
                                                <TableCell align="left">{id.toString()}</TableCell>

                                                <TableCell component="th" scope="row" padding="none">
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="subtitle2" noWrap>
                                                            {user?.fullName}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell component="th" scope="row" padding="none">
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="subtitle2" noWrap>
                                                            {user?.phone || "-"}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell component="th" scope="row" padding="none">
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="subtitle2" noWrap>
                                                            {orderTotal}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell component="th" scope="row" padding="none">
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="subtitle2" noWrap>
                                                            {orderStatus || "-"}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell component="th" scope="row" padding="none">
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Typography variant="subtitle2" noWrap>
                                                            { shippingAddress?.address1 + ' ' +  shippingAddress?.city + ' ' + shippingAddress?.state}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>

                                                <TableCell align="left">{createdAt ? new Date(createdAt).toLocaleDateString() : "-"}</TableCell>

                                                <TableCell align="left">{lastModifiedAt ? new Date(lastModifiedAt).toLocaleDateString() || "-" : "-"}</TableCell>

                                                <TableCell align="right">
                                                    <IconButton size="large" color="inherit" onClick={(e) => {
                                                        setActionOrder({ ...row })
                                                        handleOpenMenu(e)
                                                    }}>
                                                        <Iconify icon={'eva:more-vertical-fill'} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {loading && (
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Loading...
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {emptyRows > 0 && !loading && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                    {orderList.length === 0 && !loading && !isNotFound && (
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        No Data
                                                    </Typography>
                                                    <Typography variant="body2">
                                                        No records found
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>

                                {isNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <Paper
                                                    sx={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    <Typography variant="h6" paragraph>
                                                        Not found
                                                    </Typography>

                                                    <Typography variant="body2">
                                                        No results found for &nbsp;
                                                        <strong>&quot;{filterName}&quot;</strong>.
                                                        <br /> Try checking for typos or using complete words.
                                                    </Typography>
                                                </Paper>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={orderList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={() => { handleCloseMenu(); setEdit(true) }}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>
            </Popover>


            <Dialog open={openAdd || edit} onClose={handleClose}>
                <Formik
                    initialValues={edit ? actionOrder : { user: '', orderStatus: 'CREATED' }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.user?.username) {
                            errors.user = {username: 'Required'};
                        }
                        return errors;
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                        try {
                            const data = { ...values };
                            if (edit) {
                                await updateOrder(actionOrder.id, data)
                                success("Order updated successfully")
                            }
                            getData();
                            handleClose();

                        } catch (e) {
                            if (edit) {
                                error(e.message || "Failed to update Order")
                            }
                        }
                        setSubmitting(false);
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                      }) => (
                        <form className='category-form' onSubmit={handleSubmit}>
                            <DialogTitle>{edit ? "Update Order" : "Add Category"}</DialogTitle>
                            <DialogContent>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="name"
                                    label="Username"
                                    type="text"
                                    fullWidth
                                    name="user.username"
                                    variant="standard"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.user?.username}
                                />
                                {errors.user?.username && touched.user?.username && errors.user?.username}
                                <FormControl style={{ width: "100%", marginTop: '0.5rem' }}>
                                    <InputLabel htmlFor='selected-brand'>Order status</InputLabel><br/>
                                    <Select value={values.orderStatus}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={!edit}
                                            inputProps={{
                                                name: 'orderStatus',
                                                id: 'selected-brand',
                                            }}>
                                        {[{ label: "CREATED", value: "CREATED" }, { label: "SHIPPED", value: "SHIPPED" }, { label: "DELIVERED", value: "DELIVERED" }]?.map((order, index) => (<MenuItem key={index} value={order.value}>{order.label}</MenuItem>))}
                                    </Select>
                                </FormControl>
                                {errors.orderStatus && touched.orderStatus && errors.orderStatus}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Cancel</Button>
                                <Button disabled={isSubmitting} type='submit'>{edit ? "Edit" : "Add"}</Button>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>

        </>
    )
}