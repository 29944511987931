import { Axios } from "../utils/axios";
import { BASE_URL } from '../config';

const axios = new Axios();

export const addCart = async (data) => {
    const res = await axios.post(`${BASE_URL}api/carts`, data);
    return res.data
}
export const getCarts = async () => {
    const res = await axios.get(`${BASE_URL}api/carts`);
    return res.data
}
export const deleteCart = async (id) => {
    const res = await axios.delete(`${BASE_URL}api/carts/${id}`);
    return res
}
export const updateCart = async (data) => {
    const res = await axios.put(`${BASE_URL}api/carts/update`, data);
    return res.data
}

export const cartCheckout = async (data) => {
    const res = await axios.post(`${BASE_URL}api/payment`, data);
    return res.data
}