import { Axios } from "../utils/axios";
import { BASE_URL } from '../config';

const axios = new Axios();

export const getOrderByStatus = async (orderStatus) => {
    const res = await axios.get(`${BASE_URL}api/manage-order?orderStatus=${orderStatus}`);
    return res.data
}

export const updateOrder = async (id,data) => {
    const res = await axios.put(`${BASE_URL}api/manage-order/${id}`, data);
    return res.data
}

export const getAllOrder = async () => {
    const res = await axios.get(`${BASE_URL}api/orders`);
    return res.data
}