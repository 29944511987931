
import { useNavigate } from "react-router-dom"
import { ProductCard } from "./ProductCard"

export const LatestProducts = ({ products }) => {
  const navigate = useNavigate();
  return (<section id="shopify-section-template--16114105712879__1637986401d9a831b2"
    className="shopify-section spaced-section featured-collection">    <div className="collection page-width page-width-desktop">
      <div className="title-wrapper-with-link title-wrapper--self-padded-tablet-down">
        <h2 className="title">Latest Products</h2>
      </div>
      <slidercomponent className="slider-mobile-gutter">
        <ul
          className="grid grid--2-col grid--3-col-tablet grid--one-third-max grid--4-col-desktop grid--quarter-max negative-margin slider slider--tablet">
          {products.sort((a, b) => b.id - a.id).map((product, index) => (

            <ProductCard index={index} product={product} />
          ))}
        </ul>
        <div className="slider-buttons no-js-hidden">
          <button type="button" className="slider-button slider-button--prev" name="previous"
            aria-label="Slide left">
            <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg"
              x="0px" y="0px" viewBox="0 0 1000 1000"
              enableBackground="new 0 0 1000 1000"  >
              <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
              <g>
                <path
                  d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z">
                </path>
              </g>
            </svg></button>
          <button type="button" className="slider-button slider-button--next" name="next"
            aria-label="Slide right">
            <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg"
              x="0px" y="0px" viewBox="0 0 1000 1000"
              enableBackground="new 0 0 1000 1000"  >
              <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
              <g>
                <path
                  d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z">
                </path>
              </g>
            </svg></button>
        </div>
      </slidercomponent>
      <div className="center">
        <button onClick={()=>navigate("/products")} type="button" className="button button--primary" style={{ margin: "1rem"}}>
        View More Products
        </button>
      </div>
    </div>
  </section>)
}