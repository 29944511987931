import { useEffect, useState } from "react"
import { Banner } from "../components/Banner"
import { ExclusiveProduct } from "../components/ExclusiveProduct"
import { LatestProducts } from "../components/LatestProducts"
import { OurClients } from "../components/OurClients"
import { ShopByCategory } from "../components/ShopByCategory"
import { SpecialOffer } from "../components/SpecialOffer"
import { SubBanner } from "../components/SubBanner"
import { getPublicProducts } from "src/services/product"

export const Home = () => {
  const [products, setProducts] = useState([]);
  
  const getProductData = async () => {
    const data = await getPublicProducts();
    setProducts(data);
  }

  useEffect(() => {
    getProductData();
  },[])

  return (
    <>
      <Banner />
      <SubBanner />
      {/* <PopularProducts /> */}
      <ExclusiveProduct />
      <LatestProducts products={products} />
      <ShopByCategory />
      <SpecialOffer />
      {/* <FeaturedProduts /> */}
      {/* <FromOurBlog /> */}
      <OurClients />
    </>
  )
}
