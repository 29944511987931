import "./specialoffer.css";

export const SpecialOffer = () => {
    return (        <section id="shopify-section-template--16114105712879__1637985535cbd569da"
    className="shopify-section spaced-section subbanners-section spaced-section--full-width">

    <div className="tm-banners">
      <div className="page-width page-width-desktop">
        <div className="page-width-inner two-banner">
          <div className="sub-banner banner-1">
            <a href="/collections/drills">
              <div className="banner-inner">

                <img src="/images//cms-banner-1.jpg"
                  itemProp="banner" alt="Banner"/>

              </div>
              <div className="subbanner-custom">
                <div className="cms-banner-title-three">
                  Special Offers
                </div>
                <div className="cms-banner-subtitle-three">
                  Polaroid Camera<br/>
                  Blue &amp; White
                </div>

                <div className="text-button">
                  <div className="button cms__btn">
                    Shop Now
                  </div>
                </div>

              </div>

            </a>
          </div>
          <div className="sub-banner banner-2">
            <a href="/collections/drills">
              <div className="banner-inner">

                <img src="/images//cms-banner-2.jpg"
                  itemProp="banner" alt="banner"/>

              </div>
              <div className="subbanner-custom">
                <div className="cms-banner-title-three">
                  Get 50% Off
                </div>
                <div className="cms-banner-subtitle-three">
                  Smart Speaker<br/>
                  &amp; Assistant
                </div>

                <div className="text-button">
                  <div className="button cms__btn">
                    Shop Now
                  </div>
                </div>

              </div>

            </a>
          </div>
        </div>
      </div>
    </div>


  </section>)
}