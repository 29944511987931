import './newsletter.css'

export const Newsletter = () => {
    return (
        <section id="shopify-section-newsletter" className="shopify-section spaced-section newsletter spaced-section--full-width">
            <div className="newsletter center">
                <div className="page-width page-width-desktop">
                    <div className="newsletter__wrapper">
                        <h2 className="h1">Get Our Latests Update !</h2>
                        <div className="newsletter__subheading rte">
                            <p>Join Our Newsletter And Get $10 Discount</p>
                        </div>
                        <form method="post" action="https://electbox-codezeel.myshopify.com/contact#contact_form" id="contact_form"
                            acceptCharset="UTF-8" className="newsletter-form"><input type="hidden" name="form_type" value="customer" /><input
                                type="hidden" name="utf8" value="✓" />
                            <input type="hidden" name="contact[tags]" value="newsletter" />
                            <div className="newsletter-form__field-wrapper">
                                <div className="field">
                                    <input id="NewsletterForm--newsletter" type="email" name="contact[email]" className="field__input"
                                        aria-required="true" autoCorrect="off" autoCapitalize="off" autoComplete="email"
                                        placeholder="Your email" required="" />
                                    <label className="field__label" htmlFor="NewsletterForm--newsletter">
                                        Your email
                                    </label>
                                </div>
                            </div>
                            <button type="submit" className="newsletter__button button" name="commit">
                                Subscribe
                            </button>
                        </form>
                    </div>

                    <div className="get_app">
                        <div className="text">
                            <div className="App_title">
                                Get Download Apps
                            </div>

                            <div className="App_subtitle">
                                15% discount on your first purchase
                            </div>
                        </div>
                        <div className="app-icon">
                            <a href="/" className="app__link">
                                <div className="app_image_1"></div>
                            </a>

                            <a href="/" className="app__link">
                                <div className="app_image_2"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </section>)
}