import { Link } from "react-router-dom"
import { BASE_URL } from "src/config"
import { AddToCart } from "./AddToCartButton"

export const ProductCard = ({ index, product }) => {
    return (<li key={index} className="grid__item slider__slide">
        <span className="products">
            <div className="card-wrapper">
                <div className="card-img">
                    <Link to={`/products/${product?.id}`}
                        className="full-unstyled-link">
                        <span className="visually-hidden">All-new Amazon Echo Product Dot (4th Gen)</span>
                        <div className="card card--product" tabIndex="-1">
                            <div className="card__inner">
                                <div>
                                    <div className="media media--transparent media--adapt media--hover-effect"
                                        style={{ paddingBottom: "87.41258741258741%" }}>
                                        {product?.images?.map((image, key) => <img key={key}
                                            src={BASE_URL + image.uri}
                                            alt={image.name} loading="lazy" className="motion-reduce"
                                            width="1144" height="1000" />)}
                                    </div>

                                </div>
                                {/* <div className="grid-product__on-sale card__badge">

                  <span className="badge badge--bottom-left" aria-hidden="true">-16%</span>
                </div> */}
                            </div>
                        </div>

                    </Link>

                </div>
                <div className="product-hover">
                    <button type="button" aria-label="Add to wishlist" className="cm-link btn--status"
                        button-wishlist="" data-product-handle="copy-of-agittis-iman-mohamed-abdulmajid"><svg
                            className="icon cmheart not-added" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                            <title></title>
                            <g id="Like">
                                <path
                                    d="M54.958,11.2624A17.2642,17.2642,0,0,0,32,9.9964,17.2374,17.2374,0,0,0,9.042,35.6447L30.5855,57.1882a2,2,0,0,0,2.829,0L54.958,35.6447A17.26,17.26,0,0,0,54.958,11.2624ZM52.129,32.8157,32,52.9447,11.871,32.8157A13.2366,13.2366,0,0,1,30.5865,14.0924a2.0011,2.0011,0,0,0,2.827,0A13.2366,13.2366,0,1,1,52.129,32.8157Z">
                                </path>
                            </g>
                        </svg>



                        <svg className="icon added icon-solidheart" version="1.1" id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg" x="0px"
                            y="0px" width="510px" height="510px" viewBox="0 0 512 512"
                            style={{ enableBackground: "new 0 0 510 510}" }}  >
                            <path
                                d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z">
                            </path>
                        </svg>

                    </button>
                    <button type="button" aria-label="Add to compare" className="cm-link btn--status" button-compare=""
                        data-product-handle="copy-of-agittis-iman-mohamed-abdulmajid"><svg
                            className="icon icon-compare not-added" version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                            preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                                <path d="M2336 4830 c-593 -53 -1152 -350 -1519 -805 -143 -178 -267 -383
-267 -442 1 -52 35 -110 79 -132 95 -49 160 -16 237 118 193 336 475 602 819
771 306 150 660 219 982 192 372 -32 677 -142 976 -354 111 -79 268 -225 361
-337 255 -307 412 -701 433 -1091 l6 -115 -70 67 c-39 37 -83 72 -97 78 -125
47 -243 -71 -195 -197 12 -32 400 -427 441 -449 15 -8 49 -14 77 -14 64 0 91
21 322 256 189 192 209 222 195 298 -9 48 -62 102 -109 111 -72 14 -105 -1
-189 -83 l-78 -75 0 80 c0 187 -58 481 -135 688 -254 682 -843 1208 -1545
1378 -220 54 -507 76 -724 57z"></path>
                                <path d="M423 2961 c-26 -21 -127 -119 -224 -217 -189 -192 -209 -222 -195
-298 9 -48 62 -102 109 -111 72 -14 105 1 189 83 l78 75 0 -80 c0 -118 27
-309 65 -458 199 -788 816 -1407 1601 -1604 197 -50 326 -65 544 -65 208 1
297 10 480 50 564 125 1053 463 1373 949 76 116 127 217 127 252 -1 52 -35
110 -79 132 -95 49 -160 16 -237 -118 -151 -264 -346 -473 -598 -642 -537
-361 -1220 -426 -1811 -174 -341 146 -643 397 -849 705 -184 276 -296 608
-312 930 l-6 115 63 -61 c35 -34 78 -69 96 -78 121 -61 251 62 202 191 -12 32
-400 427 -441 449 -15 8 -49 14 -77 14 -42 0 -56 -5 -98 -39z"></path>
                            </g>
                        </svg>
                        <svg className="icon added icon-checked" version="1.1" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 26 26"
                            enableBackground="new 0 0 26 26">
                            <path
                                d="m.3,14c-0.2-0.2-0.3-0.5-0.3-0.7s0.1-0.5 0.3-0.7l1.4-1.4c0.4-0.4 1-0.4 1.4,0l.1,.1 5.5,5.9c0.2,0.2 0.5,0.2 0.7,0l13.4-13.9h0.1v-8.88178e-16c0.4-0.4 1-0.4 1.4,0l1.4,1.4c0.4,0.4 0.4,1 0,1.4l0,0-16,16.6c-0.2,0.2-0.4,0.3-0.7,0.3-0.3,0-0.5-0.1-0.7-0.3l-7.8-8.4-.2-.3z">
                            </path>
                        </svg>

                    </button>
                    <div className="quick-view-button"><a className="quick-view cm-link btn--status"
                        data-handle="copy-of-agittis-iman-mohamed-abdulmajid"
                        href="/">

                        <svg className="icon  fa-eye fa-w-18" version="1.0" xmlns="http://www.w3.org/2000/svg"
                            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                            preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                                <path d="M2370 4184 c-589 -70 -1135 -342 -1715 -855 -203 -179 -537 -540
-623 -674 -36 -56 -36 -134 0 -190 46 -72 240 -297 378 -438 493 -508 1042
-864 1560 -1012 225 -64 339 -79 590 -79 251 0 365 15 590 79 607 174 1274
647 1806 1283 146 174 158 195 158 262 0 67 -12 88 -158 262 -529 631 -1194
1105 -1796 1280 -202 59 -336 78 -555 82 -110 3 -216 2 -235 0z m435 -349
c471 -71 984 -348 1477 -799 151 -137 448 -454 448 -477 0 -3 -34 -46 -77 -94
-582 -666 -1244 -1089 -1848 -1180 -128 -19 -362 -19 -490 0 -435 66 -899 303
-1360 694 -167 141 -565 551 -565 581 0 4 34 47 77 95 578 661 1240 1086 1839
1179 122 19 375 19 499 1z"></path>
                                <path d="M2420 3564 c-433 -79 -741 -361 -846 -774 -27 -106 -27 -354 0 -460
97 -380 376 -659 756 -756 106 -27 354 -27 460 0 380 97 659 376 756 756 15
58 19 110 19 230 0 120 -4 172 -19 230 -95 375 -366 650 -741 752 -68 19 -323
33 -385 22z m300 -350 c187 -42 358 -179 445 -359 52 -107 68 -189 63 -325 -5
-129 -30 -216 -90 -318 -42 -72 -158 -188 -230 -230 -211 -124 -485 -124 -696
0 -72 42 -188 158 -230 230 -124 211 -124 485 0 696 42 72 158 188 230 230
151 89 328 116 508 76z"></path>
                            </g>
                        </svg>
                    </a></div>
                    <div data-js-product="" data-product-handle="copy-of-agittis-iman-mohamed-abdulmajid"
                        data-product-variant-id="42901444886767">
                    </div>
                </div>

                <div className="card-information">
                    <div className="card-information__wrapper">
                        <div className="rating reviews" role="img" aria-label="5.0 out of 5.0 stars">
                            <span aria-hidden="true" className="rating-star color-icon-text"
                                style={{ "--rating": 5, "--rating-max": 5.0, "--rating-decimal": 0 }}></span>
                            <p className="rating-count">
                                <span className="visually-hidden">(1)</span>
                                <span aria-hidden="true">(1 reviews)</span>
                            </p>
                        </div><span className="card-information__text h5">
                            <Link
                                to={`/products/${product?.id}`}>
                                {product?.name}
                            </Link>
                        </span>
                        <span className="caption-large light">{product?.description}</span>


                        <div className="price  price--on-regular ">
                            <dl>
                                <div className="price__regular">
                                    <dt>
                                        <span className="visually-hidden visually-hidden--inline">Regular price</span>
                                    </dt>
                                    <dd>
                                        <span className="price-item price-item--regular">
                                            ${new Intl.NumberFormat().format(product?.price)}
                                        </span>
                                    </dd>
                                </div>

                                {/* <div className="price__sale">
                  <dt>
                    <span className="visually-hidden visually-hidden--inline">Sale price</span>
                  </dt>
                  <dd>
                    <span className="price-item price-item--sale">
                      $100.00
                    </span>
                  </dd>
                  <dt className="price__compare">
                    <span className="visually-hidden visually-hidden--inline">Regular price</span>
                  </dt>
                  <div className="old-price">

                    <dd className="price__compare">
                      <s className="price-item price-item--regular">
                        $120.00
                      </s>
                    </dd>
                  </div>
                </div> */}
                                <small className="unit-price caption hidden">
                                    <dt className="visually-hidden">Unit price</dt>
                                    <dd>
                                        <span></span>
                                        <span aria-hidden="true">/</span>
                                        <span className="visually-hidden">&nbsp;per&nbsp;</span>
                                        <span>
                                        </span>
                                    </dd>
                                </small>
                            </dl>

                        </div>
                        <div className="product_hover_icon">

                            <productform className="product-form">
                                <form method="post" action="https://electbox-codezeel.myshopify.com/cart/add"
                                    id="ProductDrop" acceptCharset="UTF-8" className="form" encType="multipart/form-data"
                                    noValidate="noValidate" data-type="add-to-cart-form"><input type="hidden"
                                        name="form_type" value="product" /><input type="hidden" name="utf8" value="✓" /><input
                                        type="hidden" name="id" value="42901444886767" />
                                    <div className="product-form__buttons">

                                        <AddToCart product={product} />

                                    </div>
                                </form>
                            </productform>

                            <div className="com-quick">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </span>
    </li>)
}