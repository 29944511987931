import { useEffect, useState } from "react"
import { getPublicSlider } from "src/services/slider";
import { BASE_URL } from "src/config";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export const Banner = () => {
  const [sliders, setSliders] = useState([]);

  const getData = async () => {
    const data = await getPublicSlider();
    setSliders(data);
  }

  useEffect(() => {

    getData();
  }, [])
  return (<section id="shopify-section-template--16114105712879__1626698440992c0b90"
    className="shopify-section spaced-section slideshow spaced-section--full-width">


    <div className="page-width">
      <div className="flexslider">

        <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false} showStatus={false}>
          {sliders.map((slider) => <div key={slider.id} style={{ width: "1076px", marginRight: "0px", float: "left", display: "block" }} className="flex-active-slide"
            data-thumb-alt="">
            <img
              src={BASE_URL + slider.sliderUrl}
              draggable="false" alt="banner" />
          </div>)}
        </Carousel>
      </div>
    </div>

  </section>)
}