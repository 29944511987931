import { createContext, useState, useEffect } from 'react'
import { addCart, deleteCart, getCarts, updateCart } from 'src/services/cart';
import { isLoggedIn } from 'src/utils/storage';
import { error, success } from 'src/utils/toast';

export const CartContext = createContext()

export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([])

    const addToCart = async (item, quantity=1) => {
        const itemInCart = cartItems.find((cartItem) => cartItem?.product?.id === item.id);

        try {

            if (itemInCart) {
                await updateCart({ ...itemInCart, quantity: itemInCart.quantity + 1 });
            } else {
                await addCart({ quantity: quantity, product: item });
            }
            success("Added to card!")
        }catch(e) {
            error(e.message);
        }
        await getData();
    };

    const removeFromCart = async (item) => {
        const isItemInCart = cartItems.find((cartItem) => cartItem?.product?.id === item.id);

        // if (isItemInCart.quantity === 1) {
        //     setCartItems(cartItems.filter((cartItem) => cartItem.id !== item.id));
        // } else {
        //     setCartItems(
        //         cartItems.map((cartItem) =>
        //             cartItem.id === item.id
        //                 ? { ...cartItem, quantity: cartItem.quantity - 1 }
        //                 : cartItem
        //         )
        //     );
        // }
        if(isItemInCart) {
            try {
                await deleteCart(isItemInCart.id);
            } catch(e) {
                error(e.message);
            }
        }
        await getData();
    };

    const updateCartItem = async (item, quantity) => {
        const isItemInCart = cartItems.find((cartItem) => cartItem?.product?.id === item.id);
        if(isItemInCart) {
            try {

                await updateCart({...isItemInCart, quantity});
            }catch(e) {
                error(e.message);
            }
        }
        await getData();
    }

    const clearCart = () => {
        setCartItems([]);
    };

    const getCartTotal = () => {
        return cartItems.reduce((total, item) => total + item.product.price * item.quantity, 0);
    };

    useEffect(() => {
        localStorage.setItem("cartItems", JSON.stringify(cartItems));
    }, [cartItems]);

    const getData = async () => {
        const cartData = await getCarts();
        setCartItems(cartData);
    }

    useEffect(() => {
        if (isLoggedIn())
            getData();
    }, []);

    return (
        <CartContext.Provider
            value={{
                cartItems,
                addToCart,
                removeFromCart,
                clearCart,
                fetchCartData: getData,
                getCartTotal,
                updateCartItem
            }}
        >
            {children}
        </CartContext.Provider>
    );
};