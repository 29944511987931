import { Axios } from "../utils/axios";
import { BASE_URL } from '../config';

const axios = new Axios();

export const addSlider = async (data) => {
    const formData = new FormData();
    formData.append("sliderName", data.sliderName);
    formData.append("file", data.image);
    const res = await axios.post(`${BASE_URL}api/sliders`, formData);
    return res.data
}
export const updateSlider = async (id,data) => {
    const formData = new FormData();
    formData.append("sliderName", data.sliderName);
    formData.append("file", data.image);
    const res = await axios.put(`${BASE_URL}api/sliders/${id}`, formData);
    return res.data
}
export const getSlider = async () => {
    const res = await axios.get(`${BASE_URL}api/sliders`);
    return res.data
}

export const getPublicSlider = async () => {
    const res = await axios.get(`${BASE_URL}public/sliders`);
    return res.data
}

export const deleteSlider = async (id) => {
    const res = await axios.delete(`${BASE_URL}api/sliders/${id}`);
    return res
}