import './announcementbar.css'

export const AnnouncementBar = () => {
    return (<div id="shopify-section-announcement-bar" className="shopify-section">
        <div className="announcement-bar" role="region" aria-label="Announcement">
            <div className="page-width"><a href="/"
                className="announcement-bar__link link link--text focus-inset animate-arrow">
                <p className="announcement-bar__message h5">
                    Limited-Time Offers : Mid-Summer Season Sale On Selected Items -
                    <span className="announcement_btn">Shop Now</span><svg viewBox="0 0 14 10" fill="none" aria-hidden="true"
                        focusable="false" role="presentation" className="icon icon-arrow" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd"
                            d="M8.537.808a.5.5 0 01.817-.162l4 4a.5.5 0 010 .708l-4 4a.5.5 0 11-.708-.708L11.793 5.5H1a.5.5 0 010-1h10.793L8.646 1.354a.5.5 0 01-.109-.546z"
                            fill="currentColor">
                        </path>
                    </svg>

                </p>
            </a></div>
        </div>
    </div>)
}