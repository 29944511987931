import { ROLES } from "../constants/user";

export const getRole = () => {
    const user = JSON.parse(localStorage.getItem("user", "{}"));

    return user?.role?.role || ROLES.user;
}

export const getCurrentUser = () => {
    const user = JSON.parse(localStorage.getItem("user", "{}"));

    return user;
}
export const isLoggedIn = () => {
    return !!getCurrentUser()?.id;
}