import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "src/context/cart";
import { isLoggedIn } from "src/utils/storage";
import { error } from "src/utils/toast";

export const AddToCart = ({ product, quantity }) => {
    const { addToCart } = useContext(CartContext);
    const navigate = useNavigate();

    const handleAddToCart = async () => {
        await addToCart(product, quantity);
    }

    return (<button type="button" onClick={() => isLoggedIn() ? (product.quantity ? handleAddToCart(product) : error("Out of stock!")) : navigate("/login")} name="add" className="product-form__submit button button--secondary">Add to cart
    </button>)
}