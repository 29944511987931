import './exclusiveproduct.css';

export const ExclusiveProduct = () => {
    return (        <section id="shopify-section-template--16114105712879__16406706604735bd2b"
    className="shopify-section spaced-section subbanners-section spaced-section--full-width">

    <div className="tm-banners">
      <div className="page-width page-width-desktop">
        <div className="page-width-inner full banner">
          <div className="sub-banner banner-1">
            <a href="/collections/shop">
              <div className="banner-inner">

                <img src="/images/offer-banner-1.jpg"
                  itemProp="banner" alt="banner"/>

              </div>
              <div className="subbanner-custom">
                <div className="cms-banner-title-three">
                  <span>Exclusively High Quality Products</span>
                </div>
                <div className="cms-banner-subtitle-three">
                  Nikon Normal 55mm f/1.2 Manual Focus Lens<br/>

                </div>

                <div className="text-button">
                  <div className="button cms__btn">

                  </div>
                </div>

              </div>

            </a>
          </div>
        </div>
      </div>
    </div>
  </section>)
}