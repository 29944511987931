import { useCallback, useEffect, useState } from "react"
import $ from 'jquery';

import './sidebar.css'
import { getPublicCategories } from "src/services/category";
import { Link, useSearchParams } from "react-router-dom";

export const Sidebar = () => {
  const [categories, setCategories] = useState([]);
  const [categoriesToggle, setCategoriesToggle] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const getData = async () => {
    const data = await getPublicCategories();
    setCategories(data);
  }

  const initialSetup = useCallback(async () => {
    await getData();
    $(document).on('ready', function () {

      $(".side-categories").on("click", function () {
        $("#CategoryBox #header-SiteCat, #header-SiteCat").slideToggle(500);
        $('.side-categories').toggleClass('active');

      });
    });

    function mobileToggleMenu2() {
      if ($(window).width() < 1270) {
        $("#CategoryBox  ul#header-SiteCat > li.site-cat--has-dropdown").append("<span className='mobile_togglemenu'> </span>");
        $("#CategoryBox  ul#header-SiteCat > li.site-cat--has-dropdown").addClass('toggle');
        $("#CategoryBox  ul#header-SiteCat > li.site-cat--has-dropdown .mobile_togglemenu").click(function () {
          $(this).parent().toggleClass('active').find('.site-nav__childlist-drop').slideToggle('slow');

        });
      }
      else {
        $("#CategoryBox  ul#header-SiteCat > li.site-cat--has-dropdown").removeClass('toggle')
        $("#CategoryBox  ul#header-SiteCat > li.site-cat--has-dropdown").removeClass('active');
        $("#CategoryBox  ul#header-SiteCat > li.site-cat--has-dropdown .mobile_togglemenu").remove();
      }
    }
    $(document).on('ready', mobileToggleMenu2);
    $(window).on('resize', function () { mobileToggleMenu2(); });

    function mobileToggleMenu3() {
      if ($(window).width() < 1270) {
        $(" #CategoryBox  ul#header-SiteCat  li.site-cat--has-dropdown .site-nav__childlist-item-dropdown").append("<span className='mobile_togglemenu'> </span>");
        $(" #CategoryBox  ul#header-SiteCat li.site-cat--has-dropdown .site-nav__childlist-item-dropdown").addClass('toggle');
        $(" #CategoryBox  ul#header-SiteCat li.site-cat--has-dropdown .site-nav__childlist-item-dropdown .mobile_togglemenu").click(function () {
          $(this).parent().toggleClass('active').find('.header-nav__childlist').slideToggle('slow');

        });
      }
      else {
        $("#CategoryBox  ul#header-SiteCat  li.site-cat--has-dropdown .site-nav__childlist-item-dropdown").removeClass('toggle')
        $("#CategoryBox  ul#header-SiteCat  li.site-cat--has-dropdown .site-nav__childlist-item-dropdown").removeClass('active');
        $("#CategoryBox  ul#header-SiteCat  li.site-cat--has-dropdown .site-nav__childlist-item-dropdown .mobile_togglemenu").remove();
      }
    }
    $(document).on('ready', mobileToggleMenu3);
    $(window).on('resize', function () { mobileToggleMenu3(); });


    function appendSidecategory() {

      if ($(document).width() <= 1269) {
        $('.header-category').prependTo('#MainContent .page-width.contents');
      }
      else {
        $('.header-category').appendTo('.side-bar .header-menu');
      }
    }
    $(document).on('ready', function () { appendSidecategory(); });
    $(document).on('load', function () { appendSidecategory(); });
    $(window).on('resize', function () { appendSidecategory(); });
  }, []);

  useEffect(() => {
    initialSetup();
  }, [initialSetup])


  return (
    <div className="side-bar medium-up--one-quarter">
      <div id="shopify-section-tm-sidebar" className="shopify-section">


        <div className="header-menu">
          <div className="header-category">
            {/* eslint-disable-next-line */}
            <nav className="category_box" id="CategoryBox" role="categorybox">
              <span className={categoriesToggle ? "side-categories" : "side-categories active"} onClick={() => setCategoriesToggle(!categoriesToggle)}>
                <svg version="1.1" className="icon icon-hamburger" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1000 1000"
                  enableBackground="new 0 0 1000 1000">
                  <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                  <g>
                    <path
                      d="M928.8,261.7H71.3c-33.8,0-61.3-27.4-61.3-61.3c0-33.9,27.4-61.3,61.3-61.3h857.5c33.9,0,61.3,27.4,61.3,61.3C990,234.3,962.6,261.7,928.8,261.7z">
                    </path>
                    <path
                      d="M928.8,561.2H71.3C37.4,561.2,10,533.9,10,500c0-33.9,27.4-61.3,61.3-61.3h857.5c33.9,0,61.3,27.4,61.3,61.3C990,533.9,962.6,561.2,928.8,561.2z">
                    </path>
                    <path
                      d="M928.8,860.8H71.3c-33.8,0-61.3-27.4-61.3-61.3c0-33.9,27.4-61.3,61.3-61.3h857.5c33.9,0,61.3,27.4,61.3,61.3C990,833.4,962.6,860.8,928.8,860.8z">
                    </path>
                  </g>
                </svg>
                <span className="categories_title">shop by Categories<svg version="1.1" className="icon icon-header-menu"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000">
                  <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                  <g>
                    <path
                      d="M928.8,261.7H71.3c-33.8,0-61.3-27.4-61.3-61.3c0-33.9,27.4-61.3,61.3-61.3h857.5c33.9,0,61.3,27.4,61.3,61.3C990,234.3,962.6,261.7,928.8,261.7z">
                    </path>
                    <path
                      d="M928.8,561.2H71.3C37.4,561.2,10,533.9,10,500c0-33.9,27.4-61.3,61.3-61.3h857.5c33.9,0,61.3,27.4,61.3,61.3C990,533.9,962.6,561.2,928.8,561.2z">
                    </path>
                    <path
                      d="M928.8,860.8H71.3c-33.8,0-61.3-27.4-61.3-61.3c0-33.9,27.4-61.3,61.3-61.3h857.5c33.9,0,61.3,27.4,61.3,61.3C990,833.4,962.6,860.8,928.8,860.8z">
                    </path>
                  </g>
                </svg><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down"
                  className="icon icon-caret" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path
                      d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z">
                    </path>
                  </svg></span></span>

              <div className="header-sidecategory">
                <ul className={categoriesToggle ? "site-cat" : "site-cat hidden"} id="header-SiteCat">

                  {categories.map((category, key) => (<li key={key}>
                    <Link to={`/products?q=${searchParams.get("q") || ""}&category=${searchParams.get("category") === category.name ? "" : category.name}`}
                      className={searchParams.get("category") === category.name ? `active-category` : `site-cat__link site-cat__link--main`}>{category.name}</Link>
                  </li>))}


                </ul>
              </div>

            </nav>
          </div>
        </div>



      </div>


      <section id="shopify-section-tm_service"
        className="shopify-section spaced-section service-section spaced-section--full-width">

        <div className="service-block side_service" data-section-id="tm_service" data-section-type="services-section">


          <div className="services-wrapper">
            <div className="services" id="services-tm_service">
              <a href="/" className="service service-1">
                <div className="service-block service-block-1">

                  <div className="service-icon service-icon21e6b7fb-5f30-48c3-8a6d-050a04bb0209"></div>
                  <div className="service-description">
                    <div className="service-title">Fast Delivery</div>
                    <div className="service-subtitle">Our Fast Delivery Service</div>

                  </div>
                </div>
              </a>

              <a href="/" className="service service-2">
                <div className="service-block service-block-2">

                  <div className="service-icon service-icon22626b54-017a-4149-b141-25bcdcab6877"></div>
                  <div className="service-description">
                    <div className="service-title">24/7 Call Support</div>
                    <div className="service-subtitle">Our Fast Delivery Service</div>

                  </div>
                </div>
              </a>

              <a href="/" className="service service-3">
                <div className="service-block service-block-3">

                  <div className="service-icon service-icon8a0631b4-b552-4391-a635-d5f2f2334efb"></div>
                  <div className="service-description">
                    <div className="service-title">Quality Products</div>
                    <div className="service-subtitle">Our Fast Delivery Service</div>

                  </div>
                </div>
              </a>

              <a href="/" className="service service-4">
                <div className="service-block service-block-4">

                  <div className="service-icon service-icon9dc0200c-a565-4e4a-8595-0c50a7e48334"></div>
                  <div className="service-description">
                    <div className="service-title">Up To 20% Off</div>
                    <div className="service-subtitle">Our Fast Delivery Service</div>

                  </div>
                </div>
              </a>


              <a href="/" className="service service-5">
                <div className="service-block service-block-5">

                  <div className="service-icon service-icone5b2a621-2b06-4756-af68-f9a6a0745694"></div>
                  <div className="service-description">
                    <div className="service-title">Best Satisfaction</div>
                    <div className="service-subtitle">Our Fast Delivery Service</div>

                  </div>
                </div>
              </a>


            </div>
          </div>



        </div>



      </section>


      <div id="shopify-section-left_banner"
        className="shopify-section left_banner spaced-section spaced-section--full-width">
        <div className="sub-banner left-banner">
          <div className="banner-inner">
            <a href="/">
              <img src="/images/left-banner-1_large.jpg" alt=""
                itemProp="banner" />
            </a>
          </div>
        </div>

      </div>


      <section id="shopify-section-multicolumn"
        className="shopify-section spaced-section multicolumn spaced-section--full-width">



        <div className="multicolumn side_multicolumn no-heading">

          <h2 className="title">Our Client Say’s</h2>
          <div className="multicolumn__content">
            <slidercomponent className="slider-mobile-gutter">

              <ul className="multicolumn-list grid grid--1-col grid--3-col-tablet slider slider--mobile grid--peek"
                id="Slider-multicolumn">
                <li className="multicolumn-list__item grid__item slider__slide">
                  <div className="multicolumn-card">
                    <div className="multicolumn-card__info">
                      <div className="multicolumn__image">

                        <div className="multi_img multi_imga5be0351-f55e-49ec-97fd-1047770c1786"></div>
                      </div>
                      <div className="rte">
                        <p>Contrary to popular belief, Ipsum is not smply random roots in a piece dummy tex Ipsum is
                          not smply dummy ipsum loream.</p>
                      </div>

                      <svg version="1.1" className="icon quote" fill="#2d2d2d" xmlns="http://www.w3.org/2000/svg"
                        x="0px" y="0px" viewBox="0 0 1000 1000"
                        enableBackground="new 0 0 1000 1000" >
                        <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                        <g>
                          <path
                            d="M463.2,919.6H10v-343c0-128.6,24.5-226.6,79.6-300.1c55.1-73.5,147-140.9,275.6-196l98,183.8c-79.6,36.8-134.7,73.5-165.4,110.3c-30.6,36.7-49,79.6-49,128.6h214.4V919.6L463.2,919.6z M990,919.6H536.8v-343c0-128.6,24.5-226.6,79.6-300.1c55.1-73.5,147-140.9,275.6-196l98,183.8c-79.6,36.8-134.8,73.5-165.4,110.3c-30.6,36.7-49,79.6-49,128.6H990V919.6z">
                          </path>
                        </g>
                      </svg>
                      <h3>Mr. Dummy</h3>
                      <div className="author-details">Manager</div>
                    </div>


                  </div>
                </li>
                <li className="multicolumn-list__item grid__item slider__slide">
                  <div className="multicolumn-card">
                    <div className="multicolumn-card__info">
                      <div className="multicolumn__image">

                        <div className="multi_img multi_img3e280019-ae2c-463a-b5ce-ccdc6e9d1028"></div>
                      </div>
                      <div className="rte">
                        <p>Contrary to popular belief, Ipsum is not smply random roots in a piece dummy tex Ipsum is
                          not smply dummy ipsum loream.</p>
                      </div>

                      <svg version="1.1" className="icon quote" fill="#2d2d2d" xmlns="http://www.w3.org/2000/svg"
                        x="0px" y="0px" viewBox="0 0 1000 1000"
                        enableBackground="new 0 0 1000 1000" >
                        <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                        <g>
                          <path
                            d="M463.2,919.6H10v-343c0-128.6,24.5-226.6,79.6-300.1c55.1-73.5,147-140.9,275.6-196l98,183.8c-79.6,36.8-134.7,73.5-165.4,110.3c-30.6,36.7-49,79.6-49,128.6h214.4V919.6L463.2,919.6z M990,919.6H536.8v-343c0-128.6,24.5-226.6,79.6-300.1c55.1-73.5,147-140.9,275.6-196l98,183.8c-79.6,36.8-134.8,73.5-165.4,110.3c-30.6,36.7-49,79.6-49,128.6H990V919.6z">
                          </path>
                        </g>
                      </svg>
                      <h3>Mr.Krina James</h3>
                      <div className="author-details">Design stuff</div>
                    </div>


                  </div>
                </li>
                <li className="multicolumn-list__item grid__item slider__slide">
                  <div className="multicolumn-card">
                    <div className="multicolumn-card__info">
                      <div className="multicolumn__image">

                        <div className="multi_img multi_imgdd8f4c59-5ccb-4d82-a0b3-a903a81722c5"></div>
                      </div>
                      <div className="rte">
                        <p>Contrary to popular belief, Ipsum is not smply random roots in a piece dummy tex Ipsum is
                          not smply dummy ipsum loream.</p>
                      </div>

                      <svg version="1.1" className="icon quote" fill="#2d2d2d" xmlns="http://www.w3.org/2000/svg"
                        x="0px" y="0px" viewBox="0 0 1000 1000"
                        enableBackground="new 0 0 1000 1000" >
                        <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                        <g>
                          <path
                            d="M463.2,919.6H10v-343c0-128.6,24.5-226.6,79.6-300.1c55.1-73.5,147-140.9,275.6-196l98,183.8c-79.6,36.8-134.7,73.5-165.4,110.3c-30.6,36.7-49,79.6-49,128.6h214.4V919.6L463.2,919.6z M990,919.6H536.8v-343c0-128.6,24.5-226.6,79.6-300.1c55.1-73.5,147-140.9,275.6-196l98,183.8c-79.6,36.8-134.8,73.5-165.4,110.3c-30.6,36.7-49,79.6-49,128.6H990V919.6z">
                          </path>
                        </g>
                      </svg>
                      <h3>Mr.Christian Laube</h3>
                      <div className="author-details">Design stuff</div>
                    </div>


                  </div>
                </li>
              </ul>
              <div className="slider-buttons no-js-hidden medium-hide">
                <button type="button" className="slider-button slider-button--prev" name="previous"
                  aria-label="Slide left">
                  <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg"
                    x="0px" y="0px" viewBox="0 0 1000 1000"
                    enableBackground="new 0 0 1000 1000" >
                    <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                    <g>
                      <path
                        d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z">
                      </path>
                    </g>
                  </svg></button>
                <button type="button" className="slider-button slider-button--next" name="next"
                  aria-label="Slide right">

                  <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg"
                    x="0px" y="0px" viewBox="0 0 1000 1000"
                    enableBackground="new 0 0 1000 1000" >
                    <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                    <g>
                      <path
                        d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z">
                      </path>
                    </g>
                  </svg></button>
              </div>
            </slidercomponent>

          </div>

        </div>


      </section>

    </div>)
}