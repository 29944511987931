import './footer.css';

export const Footer = () => {
    return (
        <div id="shopify-section-footer" className="shopify-section">
            <footer className="footer">
                <div className="footer__content-top page-width">
                    <div className="footer__blocks-wrapper grid grid--1-col grid--2-col grid--4-col-tablet ">
                        <div className="accordion">
                            <details>
                                <summary>
                                    <h2 className="h4 accordion__title">Contact Us</h2>


                                    <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg"
                                        x="0px" y="0px" viewBox="0 0 1000 1000"
                                        enableBackground="new 0 0 1000 1000" space="preserve">
                                        <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                                        <g>
                                            <path
                                                d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z">
                                            </path>
                                        </g>
                                    </svg>
                                </summary>
                                <div className="footer-block__details-content information">
                                    <li className="address list-menu__item--link">
                                        <span>Shahdara, New Delhi, Delhi
                                        </span>
                                    </li>
                                    <a href="mailto:contacthankmanteam@gmail.com" className="email list-menu__item--link"
                                        target="_Self">contacthankmanteam@gmail.com</a>
                                    <li className="contact list-menu__item--link">
                                        <span>(+91) 99148 71595</span>
                                    </li>
                                    <li className="social list-menu__item--link">
                                        <ul className="footer__list-social list-unstyled list-social">
                                            <li className="list-social__item">
                                                <a href="/" className="link link--text list-social__link"
                                                    aria-describedby="a11y-external-message"><svg aria-hidden="true" focusable="false"
                                                        role="presentation" className="icon icon-twitter" viewBox="0 0 18 15">
                                                        <path
                                                            d="M17.64 2.6a7.33 7.33 0 01-1.75 1.82c0 .05 0 .13.02.23l.02.23a9.97 9.97 0 01-1.69 5.54c-.57.85-1.24 1.62-2.02 2.28a9.09 9.09 0 01-2.82 1.6 10.23 10.23 0 01-8.9-.98c.34.02.61.04.83.04 1.64 0 3.1-.5 4.38-1.5a3.6 3.6 0 01-3.3-2.45A2.91 2.91 0 004 9.35a3.47 3.47 0 01-2.02-1.21 3.37 3.37 0 01-.8-2.22v-.03c.46.24.98.37 1.58.4a3.45 3.45 0 01-1.54-2.9c0-.61.14-1.2.45-1.79a9.68 9.68 0 003.2 2.6 10 10 0 004.08 1.07 3 3 0 01-.13-.8c0-.97.34-1.8 1.03-2.48A3.45 3.45 0 0112.4.96a3.49 3.49 0 012.54 1.1c.8-.15 1.54-.44 2.23-.85a3.4 3.4 0 01-1.54 1.94c.74-.1 1.4-.28 2.01-.54z">
                                                        </path>
                                                    </svg>
                                                    <span className="visually-hidden">Twitter</span>
                                                </a>
                                            </li>
                                            <li className="list-social__item">
                                                <a href="/" className="link link--text list-social__link"
                                                    aria-describedby="a11y-external-message"><svg aria-hidden="true" focusable="false"
                                                        role="presentation" className="icon icon-facebook" viewBox="0 0 18 18">
                                                        <path
                                                            d="M16.42.61c.27 0 .5.1.69.28.19.2.28.42.28.7v15.44c0 .27-.1.5-.28.69a.94.94 0 01-.7.28h-4.39v-6.7h2.25l.31-2.65h-2.56v-1.7c0-.4.1-.72.28-.93.18-.2.5-.32 1-.32h1.37V3.35c-.6-.06-1.27-.1-2.01-.1-1.01 0-1.83.3-2.45.9-.62.6-.93 1.44-.93 2.53v1.97H7.04v2.65h2.24V18H.98c-.28 0-.5-.1-.7-.28a.94.94 0 01-.28-.7V1.59c0-.27.1-.5.28-.69a.94.94 0 01.7-.28h15.44z">
                                                        </path>
                                                    </svg>
                                                    <span className="visually-hidden">Facebook</span>
                                                </a>
                                            </li>
                                            <li className="list-social__item">
                                                <a href="/" className="link link--text list-social__link"
                                                    aria-describedby="a11y-external-message"><svg aria-hidden="true" focusable="false"
                                                        role="presentation" className="icon icon-pinterest" viewBox="0 0 17 18">
                                                        <path
                                                            d="M8.48.58a8.42 8.42 0 015.9 2.45 8.42 8.42 0 011.33 10.08 8.28 8.28 0 01-7.23 4.16 8.5 8.5 0 01-2.37-.32c.42-.68.7-1.29.85-1.8l.59-2.29c.14.28.41.52.8.73.4.2.8.31 1.24.31.87 0 1.65-.25 2.34-.75a4.87 4.87 0 001.6-2.05 7.3 7.3 0 00.56-2.93c0-1.3-.5-2.41-1.49-3.36a5.27 5.27 0 00-3.8-1.43c-.93 0-1.8.16-2.58.48A5.23 5.23 0 002.85 8.6c0 .75.14 1.41.43 1.98.28.56.7.96 1.27 1.2.1.04.19.04.26 0 .07-.03.12-.1.15-.2l.18-.68c.05-.15.02-.3-.11-.45a2.35 2.35 0 01-.57-1.63A3.96 3.96 0 018.6 4.8c1.09 0 1.94.3 2.54.89.61.6.92 1.37.92 2.32 0 .8-.11 1.54-.33 2.21a3.97 3.97 0 01-.93 1.62c-.4.4-.87.6-1.4.6-.43 0-.78-.15-1.06-.47-.27-.32-.36-.7-.26-1.13a111.14 111.14 0 01.47-1.6l.18-.73c.06-.26.09-.47.09-.65 0-.36-.1-.66-.28-.89-.2-.23-.47-.35-.83-.35-.45 0-.83.2-1.13.62-.3.41-.46.93-.46 1.56a4.1 4.1 0 00.18 1.15l.06.15c-.6 2.58-.95 4.1-1.08 4.54-.12.55-.16 1.2-.13 1.94a8.4 8.4 0 01-5-7.65c0-2.3.81-4.28 2.44-5.9A8.04 8.04 0 018.48.57z">
                                                        </path>
                                                    </svg>
                                                    <span className="visually-hidden">Pinterest</span>
                                                </a>
                                            </li>
                                            <li className="list-social__item">
                                                <a href="/" className="link link--text list-social__link"
                                                    aria-describedby="a11y-external-message"><svg aria-hidden="true" focusable="false"
                                                        role="presentation" className="icon icon-instagram" viewBox="0 0 18 18">
                                                        <path
                                                            d="M8.77 1.58c2.34 0 2.62.01 3.54.05.86.04 1.32.18 1.63.3.41.17.7.35 1.01.66.3.3.5.6.65 1 .12.32.27.78.3 1.64.05.92.06 1.2.06 3.54s-.01 2.62-.05 3.54a4.79 4.79 0 01-.3 1.63c-.17.41-.35.7-.66 1.01-.3.3-.6.5-1.01.66-.31.12-.77.26-1.63.3-.92.04-1.2.05-3.54.05s-2.62 0-3.55-.05a4.79 4.79 0 01-1.62-.3c-.42-.16-.7-.35-1.01-.66-.31-.3-.5-.6-.66-1a4.87 4.87 0 01-.3-1.64c-.04-.92-.05-1.2-.05-3.54s0-2.62.05-3.54c.04-.86.18-1.32.3-1.63.16-.41.35-.7.66-1.01.3-.3.6-.5 1-.65.32-.12.78-.27 1.63-.3.93-.05 1.2-.06 3.55-.06zm0-1.58C6.39 0 6.09.01 5.15.05c-.93.04-1.57.2-2.13.4-.57.23-1.06.54-1.55 1.02C1 1.96.7 2.45.46 3.02c-.22.56-.37 1.2-.4 2.13C0 6.1 0 6.4 0 8.77s.01 2.68.05 3.61c.04.94.2 1.57.4 2.13.23.58.54 1.07 1.02 1.56.49.48.98.78 1.55 1.01.56.22 1.2.37 2.13.4.94.05 1.24.06 3.62.06 2.39 0 2.68-.01 3.62-.05.93-.04 1.57-.2 2.13-.41a4.27 4.27 0 001.55-1.01c.49-.49.79-.98 1.01-1.56.22-.55.37-1.19.41-2.13.04-.93.05-1.23.05-3.61 0-2.39 0-2.68-.05-3.62a6.47 6.47 0 00-.4-2.13 4.27 4.27 0 00-1.02-1.55A4.35 4.35 0 0014.52.46a6.43 6.43 0 00-2.13-.41A69 69 0 008.77 0z">
                                                        </path>
                                                        <path
                                                            d="M8.8 4a4.5 4.5 0 100 9 4.5 4.5 0 000-9zm0 7.43a2.92 2.92 0 110-5.85 2.92 2.92 0 010 5.85zM13.43 5a1.05 1.05 0 100-2.1 1.05 1.05 0 000 2.1z">
                                                        </path>
                                                    </svg>
                                                    <span className="visually-hidden">Instagram</span>
                                                </a>
                                            </li>
                                            <li className="list-social__item">
                                                <a href="/" className="link link--text list-social__link"
                                                    aria-describedby="a11y-external-message"><svg aria-hidden="true" focusable="false"
                                                        role="presentation" className="icon icon-youtube" viewBox="0 0 100 70">
                                                        <path
                                                            d="M98 11c2 7.7 2 24 2 24s0 16.3-2 24a12.5 12.5 0 01-9 9c-7.7 2-39 2-39 2s-31.3 0-39-2a12.5 12.5 0 01-9-9c-2-7.7-2-24-2-24s0-16.3 2-24c1.2-4.4 4.6-7.8 9-9 7.7-2 39-2 39-2s31.3 0 39 2c4.4 1.2 7.8 4.6 9 9zM40 50l26-15-26-15v30z">
                                                        </path>
                                                    </svg>
                                                    <span className="visually-hidden">YouTube</span>
                                                </a>
                                            </li>
                                            <li className="list-social__item--placeholder">Follow us on social media!</li>
                                        </ul>
                                    </li>
                                </div>

                            </details>

                        </div>
                        <div className="footer-block grid__item text small-hide ">
                            <h2 className="footer-block__heading">Contact Us</h2>
                            <ul className="footer-block__details-content information">
                                <li className="address list-menu__item--link">
                                    <span>Shahdara, New Delhi, Delhi
                                    </span>
                                </li>
                                <a href="mailto:contacthankmanteam@gmail.com" className="email list-menu__item--link"
                                    target="_Self">contacthankmanteam@gmail.com</a>
                                <li className="contact list-menu__item--link">
                                    <span>(+91) 99148 71595</span>
                                </li>
                            </ul>
                            <ul className="footer__list-social list-unstyled list-social">
                                <li className="list-social__item">
                                    <a href="/" className="link link--text list-social__link"
                                        aria-describedby="a11y-external-message"><svg aria-hidden="true" focusable="false" role="presentation"
                                            className="icon icon-twitter" viewBox="0 0 18 15">
                                            <path
                                                d="M17.64 2.6a7.33 7.33 0 01-1.75 1.82c0 .05 0 .13.02.23l.02.23a9.97 9.97 0 01-1.69 5.54c-.57.85-1.24 1.62-2.02 2.28a9.09 9.09 0 01-2.82 1.6 10.23 10.23 0 01-8.9-.98c.34.02.61.04.83.04 1.64 0 3.1-.5 4.38-1.5a3.6 3.6 0 01-3.3-2.45A2.91 2.91 0 004 9.35a3.47 3.47 0 01-2.02-1.21 3.37 3.37 0 01-.8-2.22v-.03c.46.24.98.37 1.58.4a3.45 3.45 0 01-1.54-2.9c0-.61.14-1.2.45-1.79a9.68 9.68 0 003.2 2.6 10 10 0 004.08 1.07 3 3 0 01-.13-.8c0-.97.34-1.8 1.03-2.48A3.45 3.45 0 0112.4.96a3.49 3.49 0 012.54 1.1c.8-.15 1.54-.44 2.23-.85a3.4 3.4 0 01-1.54 1.94c.74-.1 1.4-.28 2.01-.54z">
                                            </path>
                                        </svg>
                                        <span className="visually-hidden">Twitter</span>
                                    </a>
                                </li>
                                <li className="list-social__item">
                                    <a href="/" className="link link--text list-social__link"
                                        aria-describedby="a11y-external-message"><svg aria-hidden="true" focusable="false" role="presentation"
                                            className="icon icon-facebook" viewBox="0 0 18 18">
                                            <path
                                                d="M16.42.61c.27 0 .5.1.69.28.19.2.28.42.28.7v15.44c0 .27-.1.5-.28.69a.94.94 0 01-.7.28h-4.39v-6.7h2.25l.31-2.65h-2.56v-1.7c0-.4.1-.72.28-.93.18-.2.5-.32 1-.32h1.37V3.35c-.6-.06-1.27-.1-2.01-.1-1.01 0-1.83.3-2.45.9-.62.6-.93 1.44-.93 2.53v1.97H7.04v2.65h2.24V18H.98c-.28 0-.5-.1-.7-.28a.94.94 0 01-.28-.7V1.59c0-.27.1-.5.28-.69a.94.94 0 01.7-.28h15.44z">
                                            </path>
                                        </svg>
                                        <span className="visually-hidden">Facebook</span>
                                    </a>
                                </li>
                                <li className="list-social__item">
                                    <a href="/" className="link link--text list-social__link"
                                        aria-describedby="a11y-external-message"><svg aria-hidden="true" focusable="false" role="presentation"
                                            className="icon icon-pinterest" viewBox="0 0 17 18">
                                            <path
                                                d="M8.48.58a8.42 8.42 0 015.9 2.45 8.42 8.42 0 011.33 10.08 8.28 8.28 0 01-7.23 4.16 8.5 8.5 0 01-2.37-.32c.42-.68.7-1.29.85-1.8l.59-2.29c.14.28.41.52.8.73.4.2.8.31 1.24.31.87 0 1.65-.25 2.34-.75a4.87 4.87 0 001.6-2.05 7.3 7.3 0 00.56-2.93c0-1.3-.5-2.41-1.49-3.36a5.27 5.27 0 00-3.8-1.43c-.93 0-1.8.16-2.58.48A5.23 5.23 0 002.85 8.6c0 .75.14 1.41.43 1.98.28.56.7.96 1.27 1.2.1.04.19.04.26 0 .07-.03.12-.1.15-.2l.18-.68c.05-.15.02-.3-.11-.45a2.35 2.35 0 01-.57-1.63A3.96 3.96 0 018.6 4.8c1.09 0 1.94.3 2.54.89.61.6.92 1.37.92 2.32 0 .8-.11 1.54-.33 2.21a3.97 3.97 0 01-.93 1.62c-.4.4-.87.6-1.4.6-.43 0-.78-.15-1.06-.47-.27-.32-.36-.7-.26-1.13a111.14 111.14 0 01.47-1.6l.18-.73c.06-.26.09-.47.09-.65 0-.36-.1-.66-.28-.89-.2-.23-.47-.35-.83-.35-.45 0-.83.2-1.13.62-.3.41-.46.93-.46 1.56a4.1 4.1 0 00.18 1.15l.06.15c-.6 2.58-.95 4.1-1.08 4.54-.12.55-.16 1.2-.13 1.94a8.4 8.4 0 01-5-7.65c0-2.3.81-4.28 2.44-5.9A8.04 8.04 0 018.48.57z">
                                            </path>
                                        </svg>
                                        <span className="visually-hidden">Pinterest</span>
                                    </a>
                                </li>
                                <li className="list-social__item">
                                    <a href="/" className="link link--text list-social__link"
                                        aria-describedby="a11y-external-message"><svg aria-hidden="true" focusable="false" role="presentation"
                                            className="icon icon-instagram" viewBox="0 0 18 18">
                                            <path
                                                d="M8.77 1.58c2.34 0 2.62.01 3.54.05.86.04 1.32.18 1.63.3.41.17.7.35 1.01.66.3.3.5.6.65 1 .12.32.27.78.3 1.64.05.92.06 1.2.06 3.54s-.01 2.62-.05 3.54a4.79 4.79 0 01-.3 1.63c-.17.41-.35.7-.66 1.01-.3.3-.6.5-1.01.66-.31.12-.77.26-1.63.3-.92.04-1.2.05-3.54.05s-2.62 0-3.55-.05a4.79 4.79 0 01-1.62-.3c-.42-.16-.7-.35-1.01-.66-.31-.3-.5-.6-.66-1a4.87 4.87 0 01-.3-1.64c-.04-.92-.05-1.2-.05-3.54s0-2.62.05-3.54c.04-.86.18-1.32.3-1.63.16-.41.35-.7.66-1.01.3-.3.6-.5 1-.65.32-.12.78-.27 1.63-.3.93-.05 1.2-.06 3.55-.06zm0-1.58C6.39 0 6.09.01 5.15.05c-.93.04-1.57.2-2.13.4-.57.23-1.06.54-1.55 1.02C1 1.96.7 2.45.46 3.02c-.22.56-.37 1.2-.4 2.13C0 6.1 0 6.4 0 8.77s.01 2.68.05 3.61c.04.94.2 1.57.4 2.13.23.58.54 1.07 1.02 1.56.49.48.98.78 1.55 1.01.56.22 1.2.37 2.13.4.94.05 1.24.06 3.62.06 2.39 0 2.68-.01 3.62-.05.93-.04 1.57-.2 2.13-.41a4.27 4.27 0 001.55-1.01c.49-.49.79-.98 1.01-1.56.22-.55.37-1.19.41-2.13.04-.93.05-1.23.05-3.61 0-2.39 0-2.68-.05-3.62a6.47 6.47 0 00-.4-2.13 4.27 4.27 0 00-1.02-1.55A4.35 4.35 0 0014.52.46a6.43 6.43 0 00-2.13-.41A69 69 0 008.77 0z">
                                            </path>
                                            <path
                                                d="M8.8 4a4.5 4.5 0 100 9 4.5 4.5 0 000-9zm0 7.43a2.92 2.92 0 110-5.85 2.92 2.92 0 010 5.85zM13.43 5a1.05 1.05 0 100-2.1 1.05 1.05 0 000 2.1z">
                                            </path>
                                        </svg>
                                        <span className="visually-hidden">Instagram</span>
                                    </a>
                                </li>
                                <li className="list-social__item">
                                    <a href="/" className="link link--text list-social__link"
                                        aria-describedby="a11y-external-message"><svg aria-hidden="true" focusable="false" role="presentation"
                                            className="icon icon-youtube" viewBox="0 0 100 70">
                                            <path
                                                d="M98 11c2 7.7 2 24 2 24s0 16.3-2 24a12.5 12.5 0 01-9 9c-7.7 2-39 2-39 2s-31.3 0-39-2a12.5 12.5 0 01-9-9c-2-7.7-2-24-2-24s0-16.3 2-24c1.2-4.4 4.6-7.8 9-9 7.7-2 39-2 39-2s31.3 0 39 2c4.4 1.2 7.8 4.6 9 9zM40 50l26-15-26-15v30z">
                                            </path>
                                        </svg>
                                        <span className="visually-hidden">YouTube</span>
                                    </a>
                                </li>
                                <li className="list-social__item--placeholder">Follow us on social media!</li>
                            </ul>
                        </div>
                        <div className="accordion">
                            <details>
                                <summary>
                                    <h2 className="h4 accordion__title">Need Help?</h2>


                                    <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg"
                                        x="0px" y="0px" viewBox="0 0 1000 1000"
                                        enableBackground="new 0 0 1000 1000" space="preserve">
                                        <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                                        <g>
                                            <path
                                                d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z">
                                            </path>
                                        </g>
                                    </svg>
                                </summary>
                                <ul className="footer-block__details-content list-unstyled">
                                    <li>
                                        <a href="/pages/contact"
                                            className="link link--text list-menu__item list-menu__item--link">
                                            Contact Us
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/pages/shipping"
                                            className="link link--text list-menu__item list-menu__item--link">
                                            Shipping
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/pages/sitemap"
                                            className="link link--text list-menu__item list-menu__item--link">
                                            Sitemap
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/pages/faqs"
                                            className="link link--text list-menu__item list-menu__item--link">
                                            FAQs
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/"
                                            className="link link--text list-menu__item list-menu__item--link">
                                            Stores
                                        </a>
                                    </li>
                                </ul>
                            </details>
                        </div>
                        <div className="footer-block grid__item footer-block--menu small-hide ">
                            <h2 className="footer-block__heading">Need Help?</h2>
                            <ul className="footer-block__details-content list-unstyled">
                                <li>
                                    <a href="/pages/contact"
                                        className="link link--text list-menu__item list-menu__item--link">
                                        Contact Us
                                    </a>
                                </li>
                                <li>
                                    <a href="/pages/shipping"
                                        className="link link--text list-menu__item list-menu__item--link">
                                        Shipping
                                    </a>
                                </li>
                                <li>
                                    <a href="/pages/sitemap"
                                        className="link link--text list-menu__item list-menu__item--link">
                                        Sitemap
                                    </a>
                                </li>
                                <li>
                                    <a href="/pages/faqs"
                                        className="link link--text list-menu__item list-menu__item--link">
                                        FAQs
                                    </a>
                                </li>
                                <li>
                                    <a href="/"
                                        className="link link--text list-menu__item list-menu__item--link">
                                        Stores
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="accordion">
                            <details>
                                <summary>
                                    <h2 className="h4 accordion__title">Information</h2>


                                    <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg"
                                        x="0px" y="0px" viewBox="0 0 1000 1000"
                                        enableBackground="new 0 0 1000 1000" space="preserve">
                                        <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                                        <g>
                                            <path
                                                d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z">
                                            </path>
                                        </g>
                                    </svg>
                                </summary>
                                <ul className="footer-block__details-content list-unstyled">
                                    <li>
                                        <a href="/pages/policy-for-buyers"
                                            className="link link--text list-menu__item list-menu__item--link">
                                            Policy for Buyers
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/pages/policy-for-sellers"
                                            className="link link--text list-menu__item list-menu__item--link">
                                            Policy for Sellers
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/pages/terms-and-conditions"
                                            className="link link--text list-menu__item list-menu__item--link">
                                            Terms and Conditions
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/pages/shipping-refund"
                                            className="link link--text list-menu__item list-menu__item--link">
                                            Shipping &amp; Refund
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/"
                                            className="link link--text list-menu__item list-menu__item--link">
                                            Wholesale Policy
                                        </a>
                                    </li>
                                </ul>
                            </details>
                        </div>
                        <div className="footer-block grid__item footer-block--menu small-hide ">
                            <h2 className="footer-block__heading">Information</h2>
                            <ul className="footer-block__details-content list-unstyled">
                                <li>
                                    <a href="/pages/policy-for-buyers"
                                        className="link link--text list-menu__item list-menu__item--link">
                                        Policy for Buyers
                                    </a>
                                </li>
                                <li>
                                    <a href="/pages/policy-for-sellers"
                                        className="link link--text list-menu__item list-menu__item--link">
                                        Policy for Sellers
                                    </a>
                                </li>
                                <li>
                                    <a href="/pages/terms-and-conditions"
                                        className="link link--text list-menu__item list-menu__item--link">
                                        Terms and Conditions
                                    </a>
                                </li>
                                <li>
                                    <a href="/pages/shipping-refund"
                                        className="link link--text list-menu__item list-menu__item--link">
                                        Shipping &amp; Refund
                                    </a>
                                </li>
                                <li>
                                    <a href="/"
                                        className="link link--text list-menu__item list-menu__item--link">
                                        Wholesale Policy
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="accordion">
                            <details>
                                <summary>
                                    <h2 className="h4 accordion__title">About us</h2>


                                    <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg"
                                        x="0px" y="0px" viewBox="0 0 1000 1000"
                                        enableBackground="new 0 0 1000 1000" space="preserve">
                                        <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                                        <g>
                                            <path
                                                d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z">
                                            </path>
                                        </g>
                                    </svg>
                                </summary>
                                <ul className="footer-block__details-content list-unstyled">
                                    <li>
                                        <a href="/pages/about-us"
                                            className="link link--text list-menu__item list-menu__item--link">
                                            About Us
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/pages/delivery-information"
                                            className="link link--text list-menu__item list-menu__item--link">
                                            Delivery Information
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/pages/privacy-policy"
                                            className="link link--text list-menu__item list-menu__item--link">
                                            Privacy Policy
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/pages/terms-and-conditions"
                                            className="link link--text list-menu__item list-menu__item--link">
                                            Terms and Conditions
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/"
                                            className="link link--text list-menu__item list-menu__item--link">
                                            Search
                                        </a>
                                    </li>
                                </ul>
                            </details>
                        </div>
                        <div className="footer-block grid__item footer-block--menu small-hide ">
                            <h2 className="footer-block__heading">About us</h2>
                            <ul className="footer-block__details-content list-unstyled">
                                <li>
                                    <a href="/pages/about-us"
                                        className="link link--text list-menu__item list-menu__item--link">
                                        About Us
                                    </a>
                                </li>
                                <li>
                                    <a href="/pages/delivery-information"
                                        className="link link--text list-menu__item list-menu__item--link">
                                        Delivery Information
                                    </a>
                                </li>
                                <li>
                                    <a href="/pages/privacy-policy"
                                        className="link link--text list-menu__item list-menu__item--link">
                                        Privacy Policy
                                    </a>
                                </li>
                                <li>
                                    <a href="/pages/terms-and-conditions"
                                        className="link link--text list-menu__item list-menu__item--link">
                                        Terms and Conditions
                                    </a>
                                </li>
                                <li>
                                    <a href="/"
                                        className="link link--text list-menu__item list-menu__item--link">
                                        Search
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="footer__column footer__column--info">
                    <div className="page-width">
                        <div className="footer__copyright">
                            <span className="copyright__content">© 2023, <a href="/"
                                title="">Hankman - Electronics Store</a></span>
                        </div>
                        <div className="footer__content-bottom">
                            <div className="footer__content-bottom-wrapper page-width">
                                <div className="footer__column footer__localization"><localization-form>
                                    <form method="post" action="https://electbox-codezeel.myshopify.com/localization"
                                        id="FooterCountryForm" acceptCharset="UTF-8" className="localization-form"
                                        encType="multipart/form-data"><input type="hidden" name="form_type" value="localization" /><input
                                            type="hidden" name="utf8" value="✓" /><input type="hidden" name="_method" value="put" /><input
                                            type="hidden" name="return_to" value="/" />
                                        <div className="no-js-hidden">
                                            <h2 className="caption-large" id="FooterCountryLabel">Currency</h2>
                                            <div className="disclosure CurrencyList">
                                                <button type="button"
                                                    className="disclosure__button FooterCurrency localization-form__select localization-selector link link--text caption-large"
                                                    aria-expanded="false" aria-controls="FooterCountryList" aria-describedby="FooterCountryLabel">
                                                    India (INR)



                                                    <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg"
                                                        x="0px" y="0px" viewBox="0 0 1000 1000"
                                                        enableBackground="new 0 0 1000 1000" space="preserve">
                                                        <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                                                        <g>
                                                            <path
                                                                d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z">
                                                            </path>
                                                        </g>
                                                    </svg>
                                                </button>
                                                <ul id="FooterCountryList" className="disclosure__list list-unstyled" hidden="">
                                                    <li className="disclosure__item" tabIndex="-1">
                                                        <a className="link link--text disclosure__link caption-large focus-inset"
                                                            href="/" data-value="CA">
                                                            Canada <span className="localization-form__currency">(CAD $)</span>
                                                        </a>
                                                    </li>
                                                    <li className="disclosure__item" tabIndex="-1">
                                                        <a className="link link--text disclosure__link caption-large focus-inset"
                                                            href="/" data-value="FR">
                                                            France <span className="localization-form__currency">(EUR €)</span>
                                                        </a>
                                                    </li>
                                                    <li className="disclosure__item" tabIndex="-1">
                                                        <a className="link link--text disclosure__link caption-large disclosure__link--active focus-inset"
                                                            href="/" aria-current="true" data-value="US">
                                                            United States <span className="localization-form__currency">(INR)</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <input type="hidden" name="country_code" value="US" />
                                        </div>
                                    </form>
                                </localization-form><localization-form>
                                        <form method="post" action="https://electbox-codezeel.myshopify.com/localization"
                                            id="FooterLanguageForm" acceptCharset="UTF-8" className="localization-form"
                                            encType="multipart/form-data"><input type="hidden" name="form_type" value="localization" /><input
                                                type="hidden" name="utf8" value="✓" /><input type="hidden" name="_method" value="put" /><input
                                                type="hidden" name="return_to" value="/" />
                                            <div className="no-js-hidden">
                                                <h2 className="caption-large" id="FooterLanguageLabel">Language</h2>
                                                <div className="disclosure LanguageList">
                                                    <button type="button"
                                                        className="disclosure__button FooterLanguage localization-form__select localization-selector link link--text caption-large"
                                                        aria-expanded="false" aria-controls="FooterLanguageList"
                                                        aria-describedby="FooterLanguageLabel">
                                                        English



                                                        <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg"
                                                            x="0px" y="0px" viewBox="0 0 1000 1000"
                                                            enableBackground="new 0 0 1000 1000" space="preserve">
                                                            <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                                                            <g>
                                                                <path
                                                                    d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z">
                                                                </path>
                                                            </g>
                                                        </svg>
                                                    </button>
                                                    <ul id="FooterLanguageList" className="disclosure__list list-unstyled" hidden="">
                                                        <li className="disclosure__item" tabIndex="-1">
                                                            <a className="link link--text disclosure__link caption-large disclosure__link--active focus-inset"
                                                                href="/" hrefLang="en" lang="en"
                                                                aria-current="true" data-value="en">
                                                                English
                                                            </a>
                                                        </li>
                                                        <li className="disclosure__item" tabIndex="-1">
                                                            <a className="link link--text disclosure__link caption-large focus-inset"
                                                                href="/" hrefLang="fr" lang="fr" data-value="fr">
                                                                Français
                                                            </a>
                                                        </li>
                                                        <li className="disclosure__item" tabIndex="-1">
                                                            <a className="link link--text disclosure__link caption-large focus-inset"
                                                                href="/" hrefLang="nl" lang="nl" data-value="nl">
                                                                Nederlands
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <input type="hidden" name="locale_code" value="en" />
                                            </div>
                                        </form>
                                    </localization-form></div>
                            </div>
                        </div>
                        <div className="footer__payment">
                            <span className="visually-hidden">Payment methods</span>
                            <ul className="list list-payment">
                                <li className="list-payment__item">
                                    <svg className="icon icon--full-color" viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" role="img"
                                        width="38" height="24" aria-labelledby="pi-visa">
                                        <title id="pi-visa">Visa</title>
                                        <path opacity=".07"
                                            d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path>
                                        <path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32">
                                        </path>
                                        <path
                                            d="M28.3 10.1H28c-.4 1-.7 1.5-1 3h1.9c-.3-1.5-.3-2.2-.6-3zm2.9 5.9h-1.7c-.1 0-.1 0-.2-.1l-.2-.9-.1-.2h-2.4c-.1 0-.2 0-.2.2l-.3.9c0 .1-.1.1-.1.1h-2.1l.2-.5L27 8.7c0-.5.3-.7.8-.7h1.5c.1 0 .2 0 .2.2l1.4 6.5c.1.4.2.7.2 1.1.1.1.1.1.1.2zm-13.4-.3l.4-1.8c.1 0 .2.1.2.1.7.3 1.4.5 2.1.4.2 0 .5-.1.7-.2.5-.2.5-.7.1-1.1-.2-.2-.5-.3-.8-.5-.4-.2-.8-.4-1.1-.7-1.2-1-.8-2.4-.1-3.1.6-.4.9-.8 1.7-.8 1.2 0 2.5 0 3.1.2h.1c-.1.6-.2 1.1-.4 1.7-.5-.2-1-.4-1.5-.4-.3 0-.6 0-.9.1-.2 0-.3.1-.4.2-.2.2-.2.5 0 .7l.5.4c.4.2.8.4 1.1.6.5.3 1 .8 1.1 1.4.2.9-.1 1.7-.9 2.3-.5.4-.7.6-1.4.6-1.4 0-2.5.1-3.4-.2-.1.2-.1.2-.2.1zm-3.5.3c.1-.7.1-.7.2-1 .5-2.2 1-4.5 1.4-6.7.1-.2.1-.3.3-.3H18c-.2 1.2-.4 2.1-.7 3.2-.3 1.5-.6 3-1 4.5 0 .2-.1.2-.3.2M5 8.2c0-.1.2-.2.3-.2h3.4c.5 0 .9.3 1 .8l.9 4.4c0 .1 0 .1.1.2 0-.1.1-.1.1-.1l2.1-5.1c-.1-.1 0-.2.1-.2h2.1c0 .1 0 .1-.1.2l-3.1 7.3c-.1.2-.1.3-.2.4-.1.1-.3 0-.5 0H9.7c-.1 0-.2 0-.2-.2L7.9 9.5c-.2-.2-.5-.5-.9-.6-.6-.3-1.7-.5-1.9-.5L5 8.2z"
                                            fill="#142688"></path>
                                    </svg>
                                </li>
                                <li className="list-payment__item">
                                    <svg className="icon icon--full-color" viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" role="img"
                                        width="38" height="24" aria-labelledby="pi-master">
                                        <title id="pi-master">Mastercard</title>
                                        <path opacity=".07"
                                            d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path>
                                        <path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32">
                                        </path>
                                        <circle fill="#EB001B" cx="15" cy="12" r="7"></circle>
                                        <circle fill="#F79E1B" cx="23" cy="12" r="7"></circle>
                                        <path fill="#FF5F00"
                                            d="M22 12c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7s1.2 4.5 3 5.7c1.8-1.2 3-3.3 3-5.7z"></path>
                                    </svg>
                                </li>
                                <li className="list-payment__item">
                                    <svg className="icon icon--full-color" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 38 24"
                                        width="38" height="24" aria-labelledby="pi-american_express">
                                        <title id="pi-american_express">American Express</title>
                                        <g fill="none">
                                            <path fill="#000"
                                                d="M35,0 L3,0 C1.3,0 0,1.3 0,3 L0,21 C0,22.7 1.4,24 3,24 L35,24 C36.7,24 38,22.7 38,21 L38,3 C38,1.3 36.6,0 35,0 Z"
                                                opacity=".07"></path>
                                            <path fill="#006FCF"
                                                d="M35,1 C36.1,1 37,1.9 37,3 L37,21 C37,22.1 36.1,23 35,23 L3,23 C1.9,23 1,22.1 1,21 L1,3 C1,1.9 1.9,1 3,1 L35,1">
                                            </path>
                                            <path fill="#FFF"
                                                d="M8.971,10.268 L9.745,12.144 L8.203,12.144 L8.971,10.268 Z M25.046,10.346 L22.069,10.346 L22.069,11.173 L24.998,11.173 L24.998,12.412 L22.075,12.412 L22.075,13.334 L25.052,13.334 L25.052,14.073 L27.129,11.828 L25.052,9.488 L25.046,10.346 L25.046,10.346 Z M10.983,8.006 L14.978,8.006 L15.865,9.941 L16.687,8 L27.057,8 L28.135,9.19 L29.25,8 L34.013,8 L30.494,11.852 L33.977,15.68 L29.143,15.68 L28.065,14.49 L26.94,15.68 L10.03,15.68 L9.536,14.49 L8.406,14.49 L7.911,15.68 L4,15.68 L7.286,8 L10.716,8 L10.983,8.006 Z M19.646,9.084 L17.407,9.084 L15.907,12.62 L14.282,9.084 L12.06,9.084 L12.06,13.894 L10,9.084 L8.007,9.084 L5.625,14.596 L7.18,14.596 L7.674,13.406 L10.27,13.406 L10.764,14.596 L13.484,14.596 L13.484,10.661 L15.235,14.602 L16.425,14.602 L18.165,10.673 L18.165,14.603 L19.623,14.603 L19.647,9.083 L19.646,9.084 Z M28.986,11.852 L31.517,9.084 L29.695,9.084 L28.094,10.81 L26.546,9.084 L20.652,9.084 L20.652,14.602 L26.462,14.602 L28.076,12.864 L29.624,14.602 L31.499,14.602 L28.987,11.852 L28.986,11.852 Z">
                                            </path>
                                        </g>
                                    </svg>

                                </li>
                                <li className="list-payment__item">
                                    <svg className="icon icon--full-color" viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" width="38"
                                        height="24" role="img" aria-labelledby="pi-paypal">
                                        <title id="pi-paypal">PayPal</title>
                                        <path opacity=".07"
                                            d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path>
                                        <path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32">
                                        </path>
                                        <path fill="#003087"
                                            d="M23.9 8.3c.2-1 0-1.7-.6-2.3-.6-.7-1.7-1-3.1-1h-4.1c-.3 0-.5.2-.6.5L14 15.6c0 .2.1.4.3.4H17l.4-3.4 1.8-2.2 4.7-2.1z">
                                        </path>
                                        <path fill="#3086C8"
                                            d="M23.9 8.3l-.2.2c-.5 2.8-2.2 3.8-4.6 3.8H18c-.3 0-.5.2-.6.5l-.6 3.9-.2 1c0 .2.1.4.3.4H19c.3 0 .5-.2.5-.4v-.1l.4-2.4v-.1c0-.2.3-.4.5-.4h.3c2.1 0 3.7-.8 4.1-3.2.2-1 .1-1.8-.4-2.4-.1-.5-.3-.7-.5-.8z">
                                        </path>
                                        <path fill="#012169"
                                            d="M23.3 8.1c-.1-.1-.2-.1-.3-.1-.1 0-.2 0-.3-.1-.3-.1-.7-.1-1.1-.1h-3c-.1 0-.2 0-.2.1-.2.1-.3.2-.3.4l-.7 4.4v.1c0-.3.3-.5.6-.5h1.3c2.5 0 4.1-1 4.6-3.8v-.2c-.1-.1-.3-.2-.5-.2h-.1z">
                                        </path>
                                    </svg>
                                </li>
                                <li className="list-payment__item">
                                    <svg className="icon icon--full-color" viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" role="img"
                                        width="38" height="24" aria-labelledby="pi-diners_club">
                                        <title id="pi-diners_club">Diners Club</title>
                                        <path opacity=".07"
                                            d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path>
                                        <path fill="#fff" d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32">
                                        </path>
                                        <path
                                            d="M12 12v3.7c0 .3-.2.3-.5.2-1.9-.8-3-3.3-2.3-5.4.4-1.1 1.2-2 2.3-2.4.4-.2.5-.1.5.2V12zm2 0V8.3c0-.3 0-.3.3-.2 2.1.8 3.2 3.3 2.4 5.4-.4 1.1-1.2 2-2.3 2.4-.4.2-.4.1-.4-.2V12zm7.2-7H13c3.8 0 6.8 3.1 6.8 7s-3 7-6.8 7h8.2c3.8 0 6.8-3.1 6.8-7s-3-7-6.8-7z"
                                            fill="#3086C8"></path>
                                    </svg>
                                </li>
                                <li className="list-payment__item">
                                    <svg className="icon icon--full-color" viewBox="0 0 38 24" width="38" height="24" role="img"
                                        aria-labelledby="pi-discover" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <title id="pi-discover">Discover</title>
                                        <path fill="#000" opacity=".07"
                                            d="M35 0H3C1.3 0 0 1.3 0 3v18c0 1.7 1.4 3 3 3h32c1.7 0 3-1.3 3-3V3c0-1.7-1.4-3-3-3z"></path>
                                        <path d="M35 1c1.1 0 2 .9 2 2v18c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h32z" fill="#fff">
                                        </path>
                                        <path
                                            d="M3.57 7.16H2v5.5h1.57c.83 0 1.43-.2 1.96-.63.63-.52 1-1.3 1-2.11-.01-1.63-1.22-2.76-2.96-2.76zm1.26 4.14c-.34.3-.77.44-1.47.44h-.29V8.1h.29c.69 0 1.11.12 1.47.44.37.33.59.84.59 1.37 0 .53-.22 1.06-.59 1.39zm2.19-4.14h1.07v5.5H7.02v-5.5zm3.69 2.11c-.64-.24-.83-.4-.83-.69 0-.35.34-.61.8-.61.32 0 .59.13.86.45l.56-.73c-.46-.4-1.01-.61-1.62-.61-.97 0-1.72.68-1.72 1.58 0 .76.35 1.15 1.35 1.51.42.15.63.25.74.31.21.14.32.34.32.57 0 .45-.35.78-.83.78-.51 0-.92-.26-1.17-.73l-.69.67c.49.73 1.09 1.05 1.9 1.05 1.11 0 1.9-.74 1.9-1.81.02-.89-.35-1.29-1.57-1.74zm1.92.65c0 1.62 1.27 2.87 2.9 2.87.46 0 .86-.09 1.34-.32v-1.26c-.43.43-.81.6-1.29.6-1.08 0-1.85-.78-1.85-1.9 0-1.06.79-1.89 1.8-1.89.51 0 .9.18 1.34.62V7.38c-.47-.24-.86-.34-1.32-.34-1.61 0-2.92 1.28-2.92 2.88zm12.76.94l-1.47-3.7h-1.17l2.33 5.64h.58l2.37-5.64h-1.16l-1.48 3.7zm3.13 1.8h3.04v-.93h-1.97v-1.48h1.9v-.93h-1.9V8.1h1.97v-.94h-3.04v5.5zm7.29-3.87c0-1.03-.71-1.62-1.95-1.62h-1.59v5.5h1.07v-2.21h.14l1.48 2.21h1.32l-1.73-2.32c.81-.17 1.26-.72 1.26-1.56zm-2.16.91h-.31V8.03h.33c.67 0 1.03.28 1.03.82 0 .55-.36.85-1.05.85z"
                                            fill="#231F20"></path>
                                        <path d="M20.16 12.86a2.931 2.931 0 100-5.862 2.931 2.931 0 000 5.862z" fill="url(#pi-paint0_linear)">
                                        </path>
                                        <path opacity=".65" d="M20.16 12.86a2.931 2.931 0 100-5.862 2.931 2.931 0 000 5.862z"
                                            fill="url(#pi-paint1_linear)"></path>
                                        <path
                                            d="M36.57 7.506c0-.1-.07-.15-.18-.15h-.16v.48h.12v-.19l.14.19h.14l-.16-.2c.06-.01.1-.06.1-.13zm-.2.07h-.02v-.13h.02c.06 0 .09.02.09.06 0 .05-.03.07-.09.07z"
                                            fill="#231F20"></path>
                                        <path
                                            d="M36.41 7.176c-.23 0-.42.19-.42.42 0 .23.19.42.42.42.23 0 .42-.19.42-.42 0-.23-.19-.42-.42-.42zm0 .77c-.18 0-.34-.15-.34-.35 0-.19.15-.35.34-.35.18 0 .33.16.33.35 0 .19-.15.35-.33.35z"
                                            fill="#231F20"></path>
                                        <path d="M37 12.984S27.09 19.873 8.976 23h26.023a2 2 0 002-1.984l.024-3.02L37 12.985z" fill="#F48120">
                                        </path>
                                        <defs>
                                            <lineargradient id="pi-paint0_linear" x1="21.657" y1="12.275" x2="19.632" y2="9.104"
                                                gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#F89F20"></stop>
                                                <stop offset=".25" stopColor="#F79A20"></stop>
                                                <stop offset=".533" stopColor="#F68D20"></stop>
                                                <stop offset=".62" stopColor="#F58720"></stop>
                                                <stop offset=".723" stopColor="#F48120"></stop>
                                                <stop offset="1" stopColor="#F37521"></stop>
                                            </lineargradient>
                                            <lineargradient id="pi-paint1_linear" x1="21.338" y1="12.232" x2="18.378" y2="6.446"
                                                gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#F58720"></stop>
                                                <stop offset=".359" stopColor="#E16F27"></stop>
                                                <stop offset=".703" stopColor="#D4602C"></stop>
                                                <stop offset=".982" stopColor="#D05B2E"></stop>
                                            </lineargradient>
                                        </defs>
                                    </svg>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer></div>)
}