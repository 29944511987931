import { Formik, Form } from "formik";
import { signupSchema } from '../../schema/auth';
import './auth.css';
import { signup } from "../../services/auth";
import { error, success } from "../../utils/toast";
import { Link, useNavigate } from "react-router-dom";

export const Signup = () => {
  let navigate = useNavigate();
  return (
    <>
      <div className="top-column-content" id="TopColumnContent">
        <div className="page-width">
          <div className="title-breadcrumbs">
            <div className="title-breadcrumbs-container">
              <nav className="breadcrumb" role="navigation" aria-label="breadcrumbs">
                <Link to="/" className="breadcrumb-home" title="Home">Home</Link>
              </nav>

              <h2 className="breadcrumb_title">Create Account</h2>
            </div>
          </div>
        </div>
      </div>



      <div className="customer register">
        <svg style={{ display: "none" }}>
          <symbol id="icon-error" viewBox="0 0 13 13">
            <circle cx="6.5" cy="6.50049" r="5.5" stroke="white" stroke-width="2"></circle>
            <circle cx="6.5" cy="6.5" r="5.5" fill="#EB001B" stroke="#EB001B" stroke-width="0.7"></circle>
            <path d="M5.87413 3.52832L5.97439 7.57216H7.02713L7.12739 3.52832H5.87413ZM6.50076 9.66091C6.88091 9.66091 7.18169 9.37267 7.18169 9.00504C7.18169 8.63742 6.88091 8.34917 6.50076 8.34917C6.12061 8.34917 5.81982 8.63742 5.81982 9.00504C5.81982 9.37267 6.12061 9.66091 6.50076 9.66091Z" fill="white"></path>
            <path d="M5.87413 3.17832H5.51535L5.52424 3.537L5.6245 7.58083L5.63296 7.92216H5.97439H7.02713H7.36856L7.37702 7.58083L7.47728 3.537L7.48617 3.17832H7.12739H5.87413ZM6.50076 10.0109C7.06121 10.0109 7.5317 9.57872 7.5317 9.00504C7.5317 8.43137 7.06121 7.99918 6.50076 7.99918C5.94031 7.99918 5.46982 8.43137 5.46982 9.00504C5.46982 9.57872 5.94031 10.0109 6.50076 10.0109Z" fill="white" stroke="#EB001B" stroke-width="0.7">
            </path></symbol>
        </svg>
        <h1>
          Create account
        </h1>
        <Formik
          initialValues={{}}
          validationSchema={signupSchema}
          onSubmit={async (values) => {
            try {
              const data = { ...values };
              delete data.confirmPassword;
              await signup(data);
              success("Account created successfully!");
              navigate("/login");
            } catch (ex) {
              error(ex?.response?.data?.message || "Failed to create account!")
            }
          }}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit} id="create_customer" acceptCharset="UTF-8" data-login-with-shop-sign-up="true" noValidate="noValidate">
              <div className="field">
                <input className={`${formik.touched.fullName && formik.errors.fullName && 'is-invalid'}`} type="text" name="fullName" id="RegisterForm-FullName" autoComplete="given-name" placeholder="Full name"
                  onChange={formik.handleChange}
                  value={formik.values.fullName}
                  onBlur={formik.handleBlur}
                />
                <label htmlFor="RegisterForm-FullName">
                  Full name
                </label>
              </div>
              {formik.touched.fullName && formik.errors.fullName && (<span id="RegisterForm-email-error" className="form__message">
                <svg aria-hidden="true" focusable="false" role="presentation">
                  <use href="#icon-error"></use>
                </svg>
                {formik.errors.fullName}
              </span>)}
              <div className="field">
                <input className={`${formik.touched.username && formik.errors.username && 'is-invalid'}`} type="text" name="username" id="RegisterForm-UserName" autoComplete="username" placeholder="Username"
                  onChange={formik.handleChange}
                  value={formik.values.username}
                  onBlur={formik.handleBlur}
                />
                <label htmlFor="RegisterForm-UserName">
                  Username
                </label>
              </div>
              {formik.touched.username && formik.errors.username && (<span id="RegisterForm-email-error" className="form__message">
                <svg aria-hidden="true" focusable="false" role="presentation">
                  <use href="#icon-error"></use>
                </svg>
                {formik.errors.username}
              </span>)}
              <div className="field">
                <input className={`${formik.touched.email && formik.errors.email && 'is-invalid'}`} type="email" name="email" id="RegisterForm-email" spellcheck="false" autoCapitalize="off" autoComplete="email" aria-required="true" placeholder="Email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                />
                <label htmlFor="RegisterForm-email">
                  Email
                </label>
              </div>

              {formik.touched.email && formik.errors.email && (<span id="RegisterForm-email-error" className="form__message">
                <svg aria-hidden="true" focusable="false" role="presentation">
                  <use href="#icon-error"></use>
                </svg>
                {formik.errors.email}
              </span>)}
              <div className="field">
                <input className={`${formik.touched.phone && formik.errors.phone && 'is-invalid'}`} type="text" name="phone" id="RegisterForm-Phone" autoComplete="phone-number" placeholder="(+91) ----- -----"
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                  onBlur={formik.handleBlur}
                />
                <label htmlFor="RegisterForm-Phone">
                  Phone number
                </label>
              </div>
              {formik.touched.phone && formik.errors.phone && (<span id="RegisterForm-email-error" className="form__message">
                <svg aria-hidden="true" focusable="false" role="presentation">
                  <use href="#icon-error"></use>
                </svg>
                {formik.errors.phone}
              </span>)}
              <div className="field">
                <input className={`${formik.touched.password && formik.errors.password && 'is-invalid'}`} type="password" name="password" id="RegisterForm-password" aria-required="true" placeholder="Password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                />
                <label htmlFor="RegisterForm-password">
                  Password
                </label>
              </div>
              {formik.touched.password && formik.errors.password && (<span id="RegisterForm-email-error" className="form__message">
                <svg aria-hidden="true" focusable="false" role="presentation">
                  <use href="#icon-error"></use>
                </svg>
                {formik.errors.password}
              </span>)}
              <div className="field">
                <input className={`${formik.touched.confirmPassword && formik.errors.confirmPassword && 'is-invalid'}`} type="password" name="confirmPassword" id="RegisterForm-confirmPassword" aria-required="true" placeholder="Confirm Password"
                  onChange={formik.handleChange}
                  value={formik.values.confirmPassword}
                  onBlur={formik.handleBlur}
                />
                <label htmlFor="RegisterForm-confirmPassword">
                  Confirm Password
                </label>
              </div>
              {formik.touched.confirmPassword && formik.errors.confirmPassword && (<span id="RegisterForm-email-error" className="form__message">
                <svg aria-hidden="true" focusable="false" role="presentation">
                  <use href="#icon-error"></use>
                </svg>
                {formik.errors.confirmPassword}
              </span>)}
              <button type="submit" disabled={formik.isSubmitting || Object.keys(formik.errors).length}>
                Create
              </button>
              <div>
                <Link to="/login" className="link">
                  Already got account? Click here
                </Link>
              </div>
            </Form>
          )}
        </Formik>

      </div>
    </>
  )
}