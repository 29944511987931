import * as Yup from "yup";

export const signupSchema = Yup.object({
    username: Yup.string().required("Username Required!"),
    phone: Yup.string().required("Phone Required!"),
    fullName: Yup.string().required("Firstname Required!"),
    email: Yup.string().email("Email is invalid!").required("Email Required!"),
    password: Yup.string()
        .min(4, "Password must be minimum 4 digits!")
        .required("Password Required!"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password must match!")
        .required("Confirm password is reqired!")
});

export const checkoutSchema = Yup.object({
    address1: Yup.string().required("Address 1 Required!"),
    address2: Yup.string().required("Address 2 Required!"),
    city: Yup.string().required("City Required!"),
    state: Yup.string().required("State Required!"),
    zipCode: Yup.string().required("Zip code Required!"),
});

export const loginSchema = Yup.object({
    username: Yup.string().required("Username Required!"),
    password: Yup.string()
        .min(4, "Password must be minimum 4 digits!")
        .required("Password Required!")
});