import { useEffect } from "react"
import $ from 'jquery';

export const OurClients = () => {

  useEffect(()=>{
    $(document).on('load', function () {
      $('.logo-bar').owlCarousel({
        loop: !0,
        nav: !1,
        dots: !1,
        autoplay: !0,
        responsive: {
          0: {
            items: 2
          },
          480: {
            items: 2
          },
          600: {
            items: 3
          },
          750: {
            items: 3
          },
          990: {
            items: 4
          },
          1270: {
            items: 5
          }
        }
      })

    });
  },[])

    return (
        <section id="shopify-section-tm-logo-bar" className="shopify-section brand-section spaced-section">

          <div className="tm-logo">
            <div className="page-width page-width-desktop">

              <ul className="logo-bar owl-carousel owl-loaded owl-drag">
                <div className="owl-stage-outer">
                  <div className="owl-stage"
                    style={{transform: "translate3d(-2152px, 0px, 0px)", transition: "all 0.25s ease 0s", width: "3444px"}}>
                    <div className="owl-item cloned" style={{width: "215px"}}>
                      <li className="logo-bar__item">

                        <a href="/" className="logo-bar__link">


                          <img src="/images/1@2x.png"
                            className="logo-bar__image" alt="logo"/>
                        </a>

                      </li>
                    </div>
                    <div className="owl-item cloned" style={{width: "215px"}}>
                      <li className="logo-bar__item">

                        <a href="/" className="logo-bar__link">


                          <img src="/images/6@2x.png"
                            className="logo-bar__image" alt="logo"/>


                        </a>

                      </li>
                    </div>
                    <div className="owl-item cloned" style={{width: "215px"}}>
                      <li className="logo-bar__item">

                        <a href="/" className="logo-bar__link">


                          <img src="/images/5@2x.png"
                            className="logo-bar__image" alt="logo"/>


                        </a>

                      </li>
                    </div>
                    <div className="owl-item cloned" style={{width: "215px"}}>
                      <li className="logo-bar__item">

                        <a href="/" className="logo-bar__link">


                          <img src="/images/2@2x.png"
                            className="logo-bar__image" alt="logo"/>


                        </a>

                      </li>
                    </div>
                    <div className="owl-item cloned" style={{width: "215px"}}>
                      <li className="logo-bar__item">

                        <a href="/" className="logo-bar__link">


                          <img src="/images/3@2x.png"
                            className="logo-bar__image" alt="logo"/>


                        </a>

                      </li>
                    </div>
                    <div className="owl-item" style={{width: "215px"}}>
                      <li className="logo-bar__item">

                        <a href="/" className="logo-bar__link">


                          <img src="/images/4@2x.png"
                            className="logo-bar__image" alt="logo"/>


                        </a>

                      </li>
                    </div>
                    <div className="owl-item" style={{width: "215px"}}>
                      <li className="logo-bar__item">

                        <a href="/" className="logo-bar__link">


                          <img src="/images/1@2x.png"
                            className="logo-bar__image" alt="logo"/>


                        </a>

                      </li>
                    </div>
                    <div className="owl-item" style={{width: "215px"}}>
                      <li className="logo-bar__item">

                        <a href="/" className="logo-bar__link">


                          <img src="/images/6@2x.png"
                            className="logo-bar__image" alt="logo"/>


                        </a>

                      </li>
                    </div>
                    <div className="owl-item" style={{width: "215px"}}>
                      <li className="logo-bar__item">

                        <a href="/" className="logo-bar__link">


                          <img src="/images/5@2x.png"
                            className="logo-bar__image" alt="logo"/>


                        </a>

                      </li>
                    </div>
                    <div className="owl-item" style={{width: "215px"}}>
                      <li className="logo-bar__item">

                        <a href="/" className="logo-bar__link">


                          <img src="/images/2@2x.png"
                            className="logo-bar__image" alt="logo"/>


                        </a>

                      </li>
                    </div>
                    <div className="owl-item active" style={{width: "215px"}}>
                      <li className="logo-bar__item">

                        <a href="/" className="logo-bar__link">


                          <img src="/images/3@2x.png"
                            className="logo-bar__image" alt="logo"/>


                        </a>

                      </li>
                    </div>
                    <div className="owl-item cloned active" style={{width: "215px"}}>
                      <li className="logo-bar__item">

                        <a href="/" className="logo-bar__link">


                          <img src="/images/4@2x.png"
                            className="logo-bar__image" alt="logo"/>


                        </a>

                      </li>
                    </div>
                    <div className="owl-item cloned active" style={{width: "215px"}}>
                      <li className="logo-bar__item">

                        <a href="/" className="logo-bar__link">


                          <img src="/images/1@2x.png"
                            className="logo-bar__image" alt="logo"/>


                        </a>

                      </li>
                    </div>
                    <div className="owl-item cloned active" style={{width: "215px"}}>
                      <li className="logo-bar__item">

                        <a href="/" className="logo-bar__link">


                          <img src="/images/6@2x.png"
                            className="logo-bar__image" alt="logo"/>


                        </a>

                      </li>
                    </div>
                    <div className="owl-item cloned active" style={{width: "215px"}}>
                      <li className="logo-bar__item">

                        <a href="/" className="logo-bar__link">


                          <img src="/images/5@2x.png"
                            className="logo-bar__image" alt="logo"/>


                        </a>

                      </li>
                    </div>
                    <div className="owl-item cloned active" style={{width: "215px"}}>
                      <li className="logo-bar__item">

                        <a href="/" className="logo-bar__link">


                          <img src="/images/2@2x.png"
                            className="logo-bar__image" alt="logo"/>


                        </a>

                      </li>
                    </div>
                  </div>
                </div>
                {/* <div className="owl-nav disabled"><button type="button" role="presentation" className="owl-prev"><span
                      aria-label="Previous">‹</span></button><button type="button" role="presentation"
                    className="owl-next"><span aria-label="Next">›</span></button></div>
                <div className="owl-dots disabled"></div> */}
              </ul>


            </div>
          </div>


        </section>
)
}