import { Grid, Container, Typography } from '@mui/material';
// sections
import {
  AppWidgetSummary,
} from '../sections/@dashboard/app';
import { useEffect, useState } from 'react';
import { getProducts } from 'src/services/product';
import { error } from 'src/utils/toast';
import { getCategory } from 'src/services/category';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const [productlist, setProductlist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categorylist, setCategorylist] = useState([]);

  const getData = async () => {
    setLoading(true);
    try {
      const products = await getProducts();
      setProductlist(products)
      const categories = await getCategory();
      setCategorylist(categories.map(category => ({ label: category.title, value: category.id })))
    } catch (e) {
      error(e.message || "Failed to get products")
    }
    setLoading(false)
  }

  useEffect(() => {
    getData();
  }, [])

  return (
    <>
      <title> Dashboard | Hankman </title>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>
        <Grid container spacing={0}>
          {loading ? <h4>Loading...</h4> : null}
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Categories" total={categorylist.length} color="info" icon={'ant-design:pie-chart-filled'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Products" total={productlist.length} color="warning" icon={'ant-design:shopping-filled'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Vendors" total={1} color="secondary" icon={'ant-design:idcard-filled'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Messages" total={1} color="primary" icon={'ant-design:mail-filled'} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
