export const ShopByCategory = () => {
    return (        <section id="shopify-section-template--16114105712879__16379852619eb4419d"
    className="shopify-section spaced-section">

    <div className="collection-list-wrapper">

      <div className="page-width page-width-desktop">
        <h2 className="title-wrapper-with-link">Shop By Category</h2>

        <slidercomponent className="slider-mobile-gutter">
          <ul
            className="collection-list grid grid--1-col grid--4-col-tablet slider slider--tablet grid--peek collection-list--4-items"
            id="Slider-template--16114105712879__16379852619eb4419d">
            <li className="collection-list__item grid__item slider__slide">
              <a href="/"
                className="card animate-arrow link card--media card--light-border">
              </a>
              <div className="card--stretch card-colored"><a href="/"
                  className="card animate-arrow link card--media card--light-border">
                  <div>

                    <div className="media media--adapt media--hover-effect overflow-hidden"
                      style={{paddingBottom: "102.36686390532543%"}}>

                      <img src="/images/category-1_339x346.jpg"
                        alt="Wireless Headphones" height="346" width="338" loading="lazy" className="motion-reduce"/>
                    </div>
                  </div>

                </a>
                <div className="card__text card__text-spacing card-colored card__text-hover"><a
                    href="/"
                    className="card animate-arrow link card--media card--light-border">
                    <div className="overlay-card"></div>

                    <h3>Wireless Headphones</h3>

                  </a>
                  <div className="grid__item collection-box link-list"><a
                      href="/"
                      className="card animate-arrow link card--media card--light-border">

                    </a>
                    <ul className="site-collection__linklist collection-content"><a
                        href="/"
                        className="card animate-arrow link card--media card--light-border">

                      </a>
                      <li className="site-collection__linklist-item"><a
                          href="/"
                          className="card animate-arrow link card--media card--light-border">
                        </a><a href="/">Desktops</a>
                      </li>

                      <li className="site-collection__linklist-item">
                        <a href="/">Laptops/ Notebooks</a>
                      </li>

                      <li className="site-collection__linklist-item">
                        <a href="/">Gaming Laptops</a>
                      </li>

                      <li className="site-collection__linklist-item">
                        <a href="/">Shopping tools</a>
                      </li>

                      <li className="site-collection__linklist-item">
                        <a href="/">Accessories</a>
                      </li>

                    </ul>

                  </div>



                </div>
              </div>

            </li>
            <li className="collection-list__item grid__item slider__slide">
              <a href="/"
                className="card animate-arrow link card--media card--light-border">
              </a>
              <div className="card--stretch card-colored"><a href="/"
                  className="card animate-arrow link card--media card--light-border">
                  <div>

                    <div className="media media--adapt media--hover-effect overflow-hidden"
                      style={{paddingBottom: "102.36686390532543%"}}>

                      <img src="/images/category-2_339x346.jpg"
                        alt="Computer &amp; Laptop" height="346" width="338" loading="lazy" className="motion-reduce"/>
                    </div>
                  </div>

                </a>
                <div className="card__text card__text-spacing card-colored card__text-hover"><a
                    href="/"
                    className="card animate-arrow link card--media card--light-border">
                    <div className="overlay-card"></div>

                    <h3>Computer &amp; Laptop</h3>

                  </a>
                  <div className="grid__item collection-box link-list"><a
                      href="/"
                      className="card animate-arrow link card--media card--light-border">

                    </a>
                    <ul className="site-collection__linklist collection-content"><a
                        href="/"
                        className="card animate-arrow link card--media card--light-border">

                      </a>
                      <li className="site-collection__linklist-item"><a
                          href="/"
                          className="card animate-arrow link card--media card--light-border">
                        </a><a href="/">Control Speakers</a>
                      </li>

                      <li className="site-collection__linklist-item">
                        <a href="/">Wireless Printer</a>
                      </li>

                      <li className="site-collection__linklist-item">
                        <a href="/">Jonor Magicbook</a>
                      </li>

                      <li className="site-collection__linklist-item">
                        <a href="/">Headset</a>
                      </li>

                      <li className="site-collection__linklist-item">
                        <a href="/">Smart Watches</a>
                      </li>

                    </ul>

                  </div>



                </div>
              </div>

            </li>
            <li className="collection-list__item grid__item slider__slide">
              <a href="/"
                className="card animate-arrow link card--media card--light-border">
              </a>
              <div className="card--stretch card-colored"><a href="/"
                  className="card animate-arrow link card--media card--light-border">
                  <div>

                    <div className="media media--adapt media--hover-effect overflow-hidden"
                      style={{paddingBottom: "102.36686390532543%"}}>

                      <img src="/images/category-3_339x346.jpg"
                        alt="Camera &amp; Videos" height="346" width="338" loading="lazy" className="motion-reduce"/>
                    </div>
                  </div>

                </a>
                <div className="card__text card__text-spacing card-colored card__text-hover"><a
                    href="/"
                    className="card animate-arrow link card--media card--light-border">
                    <div className="overlay-card"></div>

                    <h3>Camera &amp; Videos</h3>

                  </a>
                  <div className="grid__item collection-box link-list"><a
                      href="/"
                      className="card animate-arrow link card--media card--light-border">

                    </a>
                    <ul className="site-collection__linklist collection-content"><a
                        href="/"
                        className="card animate-arrow link card--media card--light-border">

                      </a>
                      <li className="site-collection__linklist-item"><a
                          href="/"
                          className="card animate-arrow link card--media card--light-border">
                        </a><a href="/">Video Game</a>
                      </li>

                      <li className="site-collection__linklist-item">
                        <a href="/">Control Speakers</a>
                      </li>

                      <li className="site-collection__linklist-item">
                        <a href="/">iPad Accessories</a>
                      </li>

                      <li className="site-collection__linklist-item">
                        <a href="/">Polaroid Camera</a>
                      </li>

                      <li className="site-collection__linklist-item">
                        <a href="/">Gadgets</a>
                      </li>

                    </ul>

                  </div>



                </div>
              </div>

            </li>
            <li className="collection-list__item grid__item slider__slide">
              <a href="/"
                className="card animate-arrow link card--media card--light-border">
              </a>
              <div className="card--stretch card-colored"><a href="/"
                  className="card animate-arrow link card--media card--light-border">
                  <div>

                    <div className="media media--adapt media--hover-effect overflow-hidden"
                      style={{paddingBottom: "102.36686390532543%"}}>

                      <img src="/images/category-4_339x346.jpg"
                        alt="Smartwatches &amp; Eyewear" height="346" width="338" loading="lazy"
                        className="motion-reduce"/>
                    </div>
                  </div>

                </a>
                <div className="card__text card__text-spacing card-colored card__text-hover"><a
                    href="/"
                    className="card animate-arrow link card--media card--light-border">
                    <div className="overlay-card"></div>

                    <h3>Smartwatches &amp; Eyewear</h3>

                  </a>
                  <div className="grid__item collection-box link-list"><a
                      href="/"
                      className="card animate-arrow link card--media card--light-border">

                    </a>
                    <ul className="site-collection__linklist collection-content"><a
                        href="/"
                        className="card animate-arrow link card--media card--light-border">

                      </a>
                      <li className="site-collection__linklist-item"><a
                          href="/"
                          className="card animate-arrow link card--media card--light-border">
                        </a><a href="/">Smart Watches</a>
                      </li>

                      <li className="site-collection__linklist-item">
                        <a href="/">Wireless Printer</a>
                      </li>

                      <li className="site-collection__linklist-item">
                        <a href="/">Eyewear</a>
                      </li>

                      <li className="site-collection__linklist-item">
                        <a href="/">Digital camera</a>
                      </li>

                      <li className="site-collection__linklist-item">
                        <a href="/">Speaker</a>
                      </li>

                    </ul>

                  </div>



                </div>
              </div>

            </li>
          </ul>
          <div className="slider-buttons no-js-hidden medium-hide">
            <button type="button" className="slider-button slider-button--prev" name="previous"
              aria-label="Slide left">
             

              <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg"
                  x="0px" y="0px" viewBox="0 0 1000 1000"
                enableBackground="new 0 0 1000 1000">
                <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                <g>
                  <path
                    d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z">
                  </path>
                </g>
              </svg></button>
            <button type="button" className="slider-button slider-button--next" name="next"
              aria-label="Slide right">
             

              <svg version="1.1" className="icon icon-caret" xmlns="http://www.w3.org/2000/svg"
                  x="0px" y="0px" viewBox="0 0 1000 1000"
                enableBackground="new 0 0 1000 1000">
                <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                <g>
                  <path
                    d="M978.3,256.4c-15.5-15-40.7-15-56.3,0L500,662.4L77.9,256.4c-15.5-15-40.8-15-56.3,0c-15.5,14.9-15.5,39.2,0,54.1l450.2,433c15.5,15,40.7,15,56.3,0l450.2-433C993.9,295.6,993.9,271.3,978.3,256.4z">
                  </path>
                </g>
              </svg></button>
          </div>
        </slidercomponent>
      </div>
    </div>


  </section>)
}